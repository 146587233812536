import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  HostBinding,
  HostListener,
  input,
} from '@angular/core';
import { Icon, Size } from '../components';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  standalone: true,
  imports: [CommonModule, Icon],
})
export class ProfileImage {
  @HostBinding('class') get hostClass() {
    if (this.src()) {
      return 'cursor-pointer';
    }

    return '';
  }

  @HostListener('click') handleClick() {
    if (this.src()) {
      window.open(this.src(), '_blank');
    }
  }

  src = input<string>('');
  size = input<Size>('md');

  currentSize = computed(() => {
    switch (this.size()) {
      case 'sm':
        return 'size-8';
      case 'md':
        return 'size-10';
      case 'lg':
        return 'size-20';
      case 'xl':
        return 'size-24';
      default:
        return 'size-10';
    }
  });
}
