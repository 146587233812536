<section id="top-bar" [attr.data-theme]="theme()" class="border-b theme-border">
  <div class="flex flex-col gap-1">
    <div [attr.data-theme]="theme()" class="text-2xl font-bold theme-text">
      {{ header() }}
    </div>
    @if (description()) {
      <div [attr.data-theme]="theme()" class="flex text-sm description">
        {{ description() }}
      </div>
    }
  </div>
  <ng-container *ngTemplateOutlet="suffixSection" />
</section>

<ng-template #suffixSection>
  <div class="flex items-center gap-2">
    <app-icon-button
      (click)="toggleTheme()"
      [icon]="
        theme() === 'dark'
          ? 'material-symbols-light:dark-mode-rounded'
          : 'ic:baseline-light-mode'
      "
    />
  </div>
</ng-template>
