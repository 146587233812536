import { Component, inject, model } from '@angular/core';
import { Checkbox } from '../../../checkbox/checkbox.component';
import { DataGridStore } from '../../data-grid.store';

@Component({
  host: {
    ngSkipHydration: 'true',
  },
  selector: 'app-checkbox-column',
  standalone: true,
  template: `
    <div class="flex justify-center">
      <app-checkbox [(value)]="value" (valueChange)="valueChange($event)" />
    </div>
  `,
  imports: [Checkbox],
})
export class CheckboxColumn {
  readonly store = inject(DataGridStore);

  value = model<boolean>(false);
  selecteds = model<any[]>([]);

  /**
   * @description 전체 선택/해제
   * @param ev
   */
  valueChange(ev: boolean) {
    const rowData = this.store.rowData() || [];

    if (ev) {
      this.selecteds.set(rowData);
    } else {
      this.selecteds.set([]);
    }
  }
}
