import { Component, computed, input } from '@angular/core';
import { BaseConfig } from '../common/config/config.adapter';
import { Color, Rounded, Variant } from '../common/types';

@Component({
  selector: 'app-badge',
  standalone: true,
  styleUrl: './badge.component.scss',
  template: `
    <div
      class="badge"
      [attr.data-size]="currentSize()"
      [attr.data-rounded]="currentRounded()"
      [attr.data-variant]="currentVariant()"
      [attr.data-color]="currentColor()"
    >
      <p class="min-w-max">
        <ng-content />
      </p>
    </div>
  `,
})
export class Badge extends BaseConfig {
  variant = input<Variant>();
  color = input<(Color & 'center') | string>();
  size = input<string>();
  rounded = input<Rounded>();

  config = computed(() => this.globalConfig()?.badge);

  currentVariant = computed(
    () => this.variant() || this.config()?.variant || 'solid',
  );
  currentColor = computed(
    () => this.color() || this.config()?.color || 'primary',
  );
  currentSize = computed(() => this.size() || this.config()?.size || 'md');
  currentRounded = computed(
    () => this.rounded() || this.config()?.rounded || 'full',
  );
}
