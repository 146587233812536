/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CenterRoleDto } from '../../models/center-role-dto';
import { UpdateCenterRoleDto } from '../../models/update-center-role-dto';

export interface CenterRoleControllerUpdateRole$Params {

/**
 * 역할 ID
 */
  roleId: string;

/**
 * 센터 ID
 */
  id: any;
      body: UpdateCenterRoleDto
}

export function centerRoleControllerUpdateRole(http: HttpClient, rootUrl: string, params: CenterRoleControllerUpdateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterRoleDto>> {
  const rb = new RequestBuilder(rootUrl, centerRoleControllerUpdateRole.PATH, 'patch');
  if (params) {
    rb.path('roleId', params.roleId, {});
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CenterRoleDto>;
    })
  );
}

centerRoleControllerUpdateRole.PATH = '/api/center/{id}/roles/{roleId}';
