/**
 * @name MalirangCharacters
 * @description 말이랑 캐릭터 고정 이미지
 * @type {Object[]}
 * @property {string} src - 이미지 경로
 * @property {string} alt - 이미지 설명
 */
export const MalirangCharacters = [
  {
    src: 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/%ED%8F%AC%EB%A1%B1%EC%9D%B4.svg',
    alt: '포롱이',
    value: 'PORONG',
  },
  {
    src: 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/%EB%A0%88%EC%98%A4.svg',
    alt: '레오',
    value: 'LEO',
  },
  {
    src: 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/%EB%9E%91.svg',
    alt: '랑',
    value: 'RANG',
  },
  {
    src: 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/%EC%86%9C%EC%86%9C.svg',
    alt: '솜솜',
    value: 'SOMSOM',
  },
  {
    src: 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/%EB%A7%88%EB%A3%A8.svg',
    alt: '마루',
    value: 'MARU',
  },
];

export const MalirangCharacter = {
  PORONG: {
    src: 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/%ED%8F%AC%EB%A1%B1%EC%9D%B4.svg',
    alt: '포롱이',
  },
  LEO: {
    src: 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/%EB%A0%88%EC%98%A4.svg',
    alt: '레오',
  },
  RANG: {
    src: 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/%EB%9E%91.svg',
    alt: '랑',
  },
  SOMSOM: {
    src: 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/%EC%86%9C%EC%86%9C.svg',
    alt: '솜솜',
  },
  MARU: {
    src: 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/%EB%A7%88%EB%A3%A8.svg',
    alt: '마루',
  },
} as const;
