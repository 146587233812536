/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { voucherControllerCreate } from '../fn/voucher/voucher-controller-create';
import { VoucherControllerCreate$Params } from '../fn/voucher/voucher-controller-create';
import { voucherControllerDelete } from '../fn/voucher/voucher-controller-delete';
import { VoucherControllerDelete$Params } from '../fn/voucher/voucher-controller-delete';
import { voucherControllerFindAll } from '../fn/voucher/voucher-controller-find-all';
import { VoucherControllerFindAll$Params } from '../fn/voucher/voucher-controller-find-all';
import { voucherControllerFindById } from '../fn/voucher/voucher-controller-find-by-id';
import { VoucherControllerFindById$Params } from '../fn/voucher/voucher-controller-find-by-id';
import { voucherControllerSearchCursor } from '../fn/voucher/voucher-controller-search-cursor';
import { VoucherControllerSearchCursor$Params } from '../fn/voucher/voucher-controller-search-cursor';
import { voucherControllerSearchOffset } from '../fn/voucher/voucher-controller-search-offset';
import { VoucherControllerSearchOffset$Params } from '../fn/voucher/voucher-controller-search-offset';
import { voucherControllerUpdate } from '../fn/voucher/voucher-controller-update';
import { VoucherControllerUpdate$Params } from '../fn/voucher/voucher-controller-update';
import { VoucherDto } from '../models/voucher-dto';

@Injectable({ providedIn: 'root' })
export class VoucherService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `voucherControllerFindAll()` */
  static readonly VoucherControllerFindAllPath = '/api/voucher';

  /**
   * 바우처 전체 조회.
   *
   * 바우처 전체를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `voucherControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  voucherControllerFindAll$Response(params: VoucherControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VoucherDto>>> {
    return voucherControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 전체 조회.
   *
   * 바우처 전체를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `voucherControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  voucherControllerFindAll(params: VoucherControllerFindAll$Params, context?: HttpContext): Observable<Array<VoucherDto>> {
    return this.voucherControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VoucherDto>>): Array<VoucherDto> => r.body)
    );
  }

  /** Path part for operation `voucherControllerCreate()` */
  static readonly VoucherControllerCreatePath = '/api/voucher';

  /**
   * 바우처 생성.
   *
   * 바우처을(를) 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `voucherControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  voucherControllerCreate$Response(params: VoucherControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<VoucherDto>> {
    return voucherControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 생성.
   *
   * 바우처을(를) 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `voucherControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  voucherControllerCreate(params: VoucherControllerCreate$Params, context?: HttpContext): Observable<VoucherDto> {
    return this.voucherControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<VoucherDto>): VoucherDto => r.body)
    );
  }

  /** Path part for operation `voucherControllerSearchOffset()` */
  static readonly VoucherControllerSearchOffsetPath = '/api/voucher/search/offset';

  /**
   * 바우처 오프셋 기반 조회.
   *
   * 바우처을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `voucherControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  voucherControllerSearchOffset$Response(params: VoucherControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return voucherControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 오프셋 기반 조회.
   *
   * 바우처을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `voucherControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  voucherControllerSearchOffset(params: VoucherControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.voucherControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `voucherControllerSearchCursor()` */
  static readonly VoucherControllerSearchCursorPath = '/api/voucher/search/cursor';

  /**
   * 바우처 커서 기반 조회.
   *
   * 바우처을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `voucherControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  voucherControllerSearchCursor$Response(params: VoucherControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return voucherControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 커서 기반 조회.
   *
   * 바우처을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `voucherControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  voucherControllerSearchCursor(params: VoucherControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.voucherControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `voucherControllerFindById()` */
  static readonly VoucherControllerFindByIdPath = '/api/voucher/{id}';

  /**
   * 바우처 ID 기반 조회.
   *
   * 바우처을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `voucherControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  voucherControllerFindById$Response(params: VoucherControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<VoucherDto>> {
    return voucherControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 ID 기반 조회.
   *
   * 바우처을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `voucherControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  voucherControllerFindById(params: VoucherControllerFindById$Params, context?: HttpContext): Observable<VoucherDto> {
    return this.voucherControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<VoucherDto>): VoucherDto => r.body)
    );
  }

  /** Path part for operation `voucherControllerDelete()` */
  static readonly VoucherControllerDeletePath = '/api/voucher/{id}';

  /**
   * 바우처 삭제.
   *
   * 바우처을(를) 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `voucherControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  voucherControllerDelete$Response(params: VoucherControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<VoucherDto>> {
    return voucherControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 삭제.
   *
   * 바우처을(를) 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `voucherControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  voucherControllerDelete(params: VoucherControllerDelete$Params, context?: HttpContext): Observable<VoucherDto> {
    return this.voucherControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<VoucherDto>): VoucherDto => r.body)
    );
  }

  /** Path part for operation `voucherControllerUpdate()` */
  static readonly VoucherControllerUpdatePath = '/api/voucher/{id}';

  /**
   * 바우처 수정.
   *
   * 바우처을(를) 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `voucherControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  voucherControllerUpdate$Response(params: VoucherControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<VoucherDto>> {
    return voucherControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 수정.
   *
   * 바우처을(를) 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `voucherControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  voucherControllerUpdate(params: VoucherControllerUpdate$Params, context?: HttpContext): Observable<VoucherDto> {
    return this.voucherControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<VoucherDto>): VoucherDto => r.body)
    );
  }

}
