import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxAdapter } from './checkbox.adapter';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: Checkbox,
      multi: true,
    },
  ],
})
export class Checkbox extends CheckboxAdapter {}
