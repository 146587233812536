<div class="flex flex-col gap-2">
  @if (label()) {
    <app-label [required]="required()!">{{ label() }}</app-label>
  }
  <div class="flex gap-3 items-center">
    @for (option of options(); track $index) {
      <app-checkbox
        [size]="size() || 'md'"
        (valueChange)="handleValueChange($event, option.value)"
        [value]="value()?.includes(option.value)!"
        [modelValue]="option.value"
        [label]="option.label"
        [description]="option.description || ''"
      />
    }
  </div>
</div>
