/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  computed,
  effect,
  input,
  model,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Label } from '../label/label.component';
import { RadioOptionItem } from './radio-option/radio-option.component';

export type RadioOption = {
  label: string;
  description?: string;
  value: string | number | boolean;
  disabled?: boolean;
};

export type RadioDirection = 'horizontal' | 'vertical';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  standalone: true,
  imports: [CommonModule, Label, RadioOptionItem],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: Radio,
      multi: true,
    },
  ],
})
export class Radio implements ControlValueAccessor {
  $options = input.required<string[] | RadioOption[]>({ alias: 'options' });
  options = computed(() => {
    return this.$options().map((option: string | RadioOption) => {
      if (typeof option === 'string') {
        return { label: option, value: option, disabled: false };
      }
      return option;
    });
  });

  label = input<string>();
  direction = input<RadioDirection>('horizontal');
  required = input<boolean, string>(false, { transform: booleanAttribute });
  $disabled = input<boolean>(false, { alias: 'disabled' });
  _disabled = signal<boolean>(false);
  disabled = computed(() => this._disabled() || this.$disabled());

  value = model<string | number | boolean>();

  constructor() {
    effect(() => {
      this.onChange(this.value()!);
    });
  }

  onChange = (value: string | number | boolean) => {};
  onTouched = () => {};

  writeValue(obj: any): void {
    this.value.set(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(disabled: boolean): void {
    this._disabled.set(disabled);
  }
}
