import { Component, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Radio } from '../../../radio/radio.component';
import { FormFieldAdapter } from '../../adapters/form-field.adapter';
import { RadioFormField } from '../../auto-form.type';

@Component({
  selector: 'app-radio-form-field',
  standalone: true,
  imports: [Radio],
  templateUrl: './radio-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioFormFieldComponent,
      multi: true,
    },
  ],
})
export class RadioFormFieldComponent
  extends FormFieldAdapter<RadioFormField<any>>
  implements OnInit {}
