<div class="relative w-full h-full">
  <img
    class="object-cover"
    [ngSrc]="src()"
    fill
    priority
    (load)="onLoad()"
    (error)="onError()"
    [class.opacity-0]="!loaded()"
    [class.opacity-100]="loaded()"
  />

  @if (!loaded() && !error()) {
    <div class="w-full h-full bg-neutral-100 animate-pulse"></div>
  }
</div>
