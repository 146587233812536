import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  inject,
  input,
  model,
  OnInit,
  output,
  Pipe,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { map } from 'rxjs';
import { Button } from '../button/button.component';
import { Hint } from '../hint/hint.component';
import { AutoFormStore } from './auto-form.store';
import { FormMetadata } from './auto-form.type';
import { CheckboxFormFieldComponent } from './fields/checkbox-form-field/checkbox-form-field.component';
import { CustomFormFieldComponent } from './fields/custom-form-field/custom-form-field.component';
import { DateFormFieldComponent } from './fields/date-form-field/date-form-field.component';
import { EmailFormFieldComponent } from './fields/email-form-field/email-form-field.component';
import { FileFormFieldComponent } from './fields/file-form-field/file-form-field.component';
import { NumberFormFieldComponent } from './fields/number-form-field/number-form-field.component';
import { PasswordFormFieldComponent } from './fields/password-form-field/password-form-field.component';
import { RadioFormFieldComponent } from './fields/radio-form-field/radio-form-field.component';
import { SelectFormFieldComponent } from './fields/select-form-field/select-form-field.component';
import { TextFormFieldComponent } from './fields/text-form-field/text-form-field.component';
import { TextareaFormFieldComponent } from './fields/textarea-form-field/textarea-form-field.component';
import { ToggleFormFieldComponent } from './fields/toggle-form-field/toggle-form-field.component';
import { AddressFormFieldComponent } from './fields/address-form-field/address-form-field.component';
import { ObjectValidator } from '../form/validators/object.validator';

@Pipe({
  name: 'fieldName',
})
export class FormFieldNamePipe {
  transform(field: any): string {
    return field;
  }
}

@Component({
  host: {
    ngSkipHydration: 'true',
  },
  selector: 'app-auto-form',
  templateUrl: './auto-form.component.html',
  standalone: true,
  imports: [
    TextFormFieldComponent,
    AddressFormFieldComponent,
    NumberFormFieldComponent,
    TextareaFormFieldComponent,
    EmailFormFieldComponent,
    PasswordFormFieldComponent,
    DateFormFieldComponent,
    FileFormFieldComponent,
    SelectFormFieldComponent,
    RadioFormFieldComponent,
    CheckboxFormFieldComponent,
    ToggleFormFieldComponent,
    CustomFormFieldComponent,
    ReactiveFormsModule,
    FormFieldNamePipe,
    FormsModule,
    Button,
    RouterLink,
    CommonModule,
    Hint,
  ],
  providers: [AutoFormStore],
})
export class AutoForm implements OnInit {
  readonly store = inject(AutoFormStore);

  submit = output<any>();
  close = output<void>();

  metadata = input.required<FormMetadata<any>>();

  // 버퍼 null 값 반드시 제거
  schema = computed(() => this.metadata()?.schema?.filter((i) => !!i));
  schema$ = toObservable(this.schema);

  defaultValues = computed(() => this.metadata().defaultValues);
  defaultValues$ = toObservable(this.defaultValues);

  options = computed(() => this.metadata().options);

  form = new FormGroup({});

  values = model<any>({});

  _values = toSignal(this.form.valueChanges);
  invalid = toSignal(this.form.valueChanges.pipe(map(() => this.form.invalid)));

  ngOnInit(): void {
    this.schema$.subscribe({
      next: (schema) => {
        /**
         * form control 초기화
         */
        schema
          .filter((item) => !!item)
          .map((item) => this.form.removeControl(item.field as string));

        /**
         * form control 설정
         */
        schema
          .filter((item) => item?.active !== false)
          .map((data) => {
            if (data) {
              const field = data.field as any;

              const controlOptions = {
                validators: data.validators || [],
                asyncValidators: data.asyncValidators,
                nonNullable: data.required,
              };

              if (data.required) {
                controlOptions.validators = [
                  ...controlOptions.validators,
                  Validators.required,
                ];
              }

              switch (data.type) {
                case 'toggle': {
                  this.form.addControl(
                    field,
                    new FormControl(false, controlOptions),
                  );
                  break;
                }
                case 'checkbox': {
                  if (data.group) {
                    this.form.addControl(
                      field,
                      new FormControl([], controlOptions),
                    );
                  }

                  this.form.addControl(
                    field,
                    new FormControl(false, controlOptions),
                  );
                  break;
                }
                case 'date':
                  this.form.addControl(
                    field,
                    new FormControl(null, controlOptions),
                  );
                  break;
                default:
                  this.form.addControl(
                    field,
                    new FormControl('', controlOptions),
                  );
                  break;
              }
            }
          });
      },
    });

    this.form.valueChanges.subscribe((values) => {
      if (!this.invalid()) {
        this.values.set(values);
      } else {
        this.values.set(null);
      }
    });

    this.defaultValues$.subscribe({
      next: (values) => {
        if (values) {
          this.store.setDefaultValues(values);
          this.form.patchValue(values);
        }
      },
    });
  }

  handleSubmit() {
    if (this.invalid()) return;
    this.submit.emit(this._values());
  }
}
