<div
  (click)="toggle()"
  class="wrapper group"
  [attr.data-theme]="theme()"
  [class.active]="value()"
  [class.p-2]="label()"
>
  <div
    [attr.data-size]="size()"
    class="checkbox"
    [ngClass]="
      value() ? 'border-primary-500 bg-primary-500' : 'bg-white border-gray-300'
    "
  >
    @if (value()) {
      <img
        [attr.data-size]="size()"
        class="icon"
        src="https://api.iconify.design/ic:baseline-check.svg?color=%23ffffff"
      />
    }
  </div>
  @if (label()) {
    <div class="flex flex-col gap-1 cursor-pointer" [class.active]="value()">
      @if (label(); as label) {
        <p
          [attr.data-size]="size()"
          [attr.data-theme]="theme()"
          class="label theme-text"
        >
          {{ label }}
        </p>
      }
      @if (description(); as description) {
        <p [attr.data-size]="size()" class="text-gray-500 description">
          {{ description }}
        </p>
      }
    </div>
  }
</div>
