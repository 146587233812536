import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ADMIN_ACCESS_TOKEN_KEY } from '@malirang/common';
import { LocalStorageService } from '@malirang/front-share';
import { catchError, throwError } from 'rxjs';

export const HttpInterceptor: HttpInterceptorFn = (req, next) => {
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  const accessToken = localStorageService.get(ADMIN_ACCESS_TOKEN_KEY);
  const request = req.clone({
    setHeaders: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return next(request).pipe(
    catchError((e) => {
      if (e.status === 401) {
        localStorageService.remove(ADMIN_ACCESS_TOKEN_KEY);
        router.navigate(['/login']);
      }

      return throwError(() => e.error);
    }),
  );
};
