/* eslint-disable @nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  effect,
  model,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInput, Color, Input, Label } from '@malirang/front-share';

export type ResidentNumberValue = {
  first: string;
  second: string;
};

@Component({
  selector: 'app-input-resident-number',
  standalone: true,
  imports: [CommonModule, Input, Label],
  templateUrl: './input-resident-number.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputResidentNumber,
      multi: true,
    },
  ],
})
export class InputResidentNumber
  extends BaseInput<ResidentNumberValue>
  implements OnInit
{
  first = model<string>();
  second = model<string>();
  _invalid = signal(false);
  invalid = output<boolean>();
  touched = signal(false);

  constructor() {
    super();

    effect(() => {
      this.onChange(this.value()!);
    });
  }

  $color = computed<Color>(() =>
    this._invalid() && this.touched() ? 'red' : 'primary',
  );

  valueChange() {
    if (this.first() || this.second()) {
      this.touched.set(true);
    }

    const first = this.first()?.replace(/[^0-9]/g, '');
    const second = this.second()?.replace(/[^0-9]/g, '');

    if (first?.toString().length === 6 && second?.toString().length === 7) {
      this.writeValue({
        first: this.first(),
        second: this.second(),
      });
      this._invalid.set(false);
      this.invalid.emit(false);
    } else {
      this.writeValue(null);
      this._invalid.set(true);
      this.invalid.emit(true);
    }
  }
}
