@if (formField(); as field) {
  @if (field.group) {
    <app-checkbox-group
      [label]="field.label"
      [required]="field.required ? 'true' : 'false'"
      [options]="field.options"
      [(value)]="value"
    />
    <p
      formError
      [formControl]="formControl!"
      (hasError)="hasError.set($event)"
    ></p>
  } @else {
    <app-checkbox [label]="field.label!" [(value)]="value" />
  }
}
