/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { diaryControllerCreateV1 } from '../fn/diary/diary-controller-create-v-1';
import { DiaryControllerCreateV1$Params } from '../fn/diary/diary-controller-create-v-1';
import { diaryControllerDeleteV1 } from '../fn/diary/diary-controller-delete-v-1';
import { DiaryControllerDeleteV1$Params } from '../fn/diary/diary-controller-delete-v-1';
import { diaryControllerFindOneV1 } from '../fn/diary/diary-controller-find-one-v-1';
import { DiaryControllerFindOneV1$Params } from '../fn/diary/diary-controller-find-one-v-1';
import { diaryControllerSearchV1 } from '../fn/diary/diary-controller-search-v-1';
import { DiaryControllerSearchV1$Params } from '../fn/diary/diary-controller-search-v-1';
import { diaryControllerUpdateV1 } from '../fn/diary/diary-controller-update-v-1';
import { DiaryControllerUpdateV1$Params } from '../fn/diary/diary-controller-update-v-1';
import { DiaryDto } from '../models/diary-dto';
import { SearchDiaryResponseDto } from '../models/search-diary-response-dto';

@Injectable({ providedIn: 'root' })
export class DiaryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `diaryControllerSearchV1()` */
  static readonly DiaryControllerSearchV1Path = '/api/v1/diary/search';

  /**
   * 보호자가 내 아동의 일지 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `diaryControllerSearchV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  diaryControllerSearchV1$Response(params: DiaryControllerSearchV1$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchDiaryResponseDto>> {
    return diaryControllerSearchV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 일지 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `diaryControllerSearchV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  diaryControllerSearchV1(params: DiaryControllerSearchV1$Params, context?: HttpContext): Observable<SearchDiaryResponseDto> {
    return this.diaryControllerSearchV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchDiaryResponseDto>): SearchDiaryResponseDto => r.body)
    );
  }

  /** Path part for operation `diaryControllerFindOneV1()` */
  static readonly DiaryControllerFindOneV1Path = '/api/v1/diary/{id}';

  /**
   * 보호자가 내 아동의 일지 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `diaryControllerFindOneV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  diaryControllerFindOneV1$Response(params: DiaryControllerFindOneV1$Params, context?: HttpContext): Observable<StrictHttpResponse<DiaryDto>> {
    return diaryControllerFindOneV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 일지 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `diaryControllerFindOneV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  diaryControllerFindOneV1(params: DiaryControllerFindOneV1$Params, context?: HttpContext): Observable<DiaryDto> {
    return this.diaryControllerFindOneV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiaryDto>): DiaryDto => r.body)
    );
  }

  /** Path part for operation `diaryControllerDeleteV1()` */
  static readonly DiaryControllerDeleteV1Path = '/api/v1/diary/{id}';

  /**
   * 보호자가 내 아동 일지 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `diaryControllerDeleteV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  diaryControllerDeleteV1$Response(params: DiaryControllerDeleteV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return diaryControllerDeleteV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 일지 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `diaryControllerDeleteV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  diaryControllerDeleteV1(params: DiaryControllerDeleteV1$Params, context?: HttpContext): Observable<boolean> {
    return this.diaryControllerDeleteV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `diaryControllerUpdateV1()` */
  static readonly DiaryControllerUpdateV1Path = '/api/v1/diary/{id}';

  /**
   * 보호자가 내 아동 일지 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `diaryControllerUpdateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  diaryControllerUpdateV1$Response(params: DiaryControllerUpdateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return diaryControllerUpdateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 일지 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `diaryControllerUpdateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  diaryControllerUpdateV1(params: DiaryControllerUpdateV1$Params, context?: HttpContext): Observable<boolean> {
    return this.diaryControllerUpdateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `diaryControllerCreateV1()` */
  static readonly DiaryControllerCreateV1Path = '/api/v1/diary';

  /**
   * 보호자가 내 아동의 일지 작성.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `diaryControllerCreateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  diaryControllerCreateV1$Response(params: DiaryControllerCreateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return diaryControllerCreateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 일지 작성.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `diaryControllerCreateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  diaryControllerCreateV1(params: DiaryControllerCreateV1$Params, context?: HttpContext): Observable<boolean> {
    return this.diaryControllerCreateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
