import { CommonModule } from '@angular/common';
import { Component, computed, HostListener } from '@angular/core';
import dayjs from 'dayjs';
import { InputBaseDay } from '../calendar-base-day';

@Component({
  selector: 'app-day',
  imports: [CommonModule],
  standalone: true,
  styleUrls: ['../calendar.component.scss'],
  templateUrl: './day.component.html',
})
export class Day extends InputBaseDay {
  @HostListener('click') click() {
    this.store.setValue(this.day().toDate());
    this.select.emit();
  }

  backgroundColor = computed<string>(() => {
    if (this.isToday()) {
      return 'today';
    }

    if (this.selected()) {
      return 'selected';
    }

    return 'default';
  });

  textColor = computed<string>(() => {
    if (dayjs(this.value()).get('month') !== this.day().get('month')) {
      return 'prev-month';
    }

    if (this.isToday()) {
      return 'today';
    }

    if (this.selected()) {
      return 'selected';
    }

    if (this.day().get('day') === 0) {
      return 'sunday';
    }

    if (this.day().get('day') === 6) {
      return 'saturday';
    }

    return 'default';
  });
}
