import { Component, computed } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Select } from '../../../select/select.component';
import { FormFieldAdapter } from '../../adapters/form-field.adapter';
import { SelectFormField } from '../../auto-form.type';
import { ErrorDirective } from '../../directives/error.directive';
import { josa } from 'es-hangul';

@Component({
  selector: 'app-select-form-field',
  standalone: true,
  imports: [Select, ErrorDirective],
  templateUrl: './select-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectFormFieldComponent,
      multi: true,
    },
  ],
})
export class SelectFormFieldComponent extends FormFieldAdapter<
  SelectFormField<any>
> {
  _placeholder = computed<string>(() => {
    const value = this.formField().label;
    return `${josa(value!, '을/를')} 입력해주세요.`;
  });
}
