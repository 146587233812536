@if (item(); as item) {
  <section class="flex-col w-full md:min-w-[25rem] border-l h-full flex">
    <app-modal-header (close)="close.emit()" class="md:hidden">
      <ng-container *ngTemplateOutlet="header" />
    </app-modal-header>
    <div
      class="items-center justify-between hidden w-full p-6 border-b md:flex"
    >
      <ng-container *ngTemplateOutlet="header" />
      <app-icon
        (click)="open.set(false)"
        name="mdi:close"
        class="cursor-pointer"
      />
    </div>
    <div class="flex flex-col gap-2.5 p-3">
      <app-input-search [(value)]="query" [debounce]="300" />
      @for (event of events(); track event) {
        <app-schedule-info-item [event]="event" />
      }
      @if (!events().length) {
        <div class="flex items-center justify-center h-32">
          <p class="text-gray-400">등록된 일정이 없습니다.</p>
        </div>
      }
    </div>
  </section>
}

<ng-template #header>
  <div class="flex items-center gap-2">
    <p class="font-semibold">
      {{ item().date ? (item().date | date: 'y년 MM월 dd일 EE요일') : '-' }}
    </p>
    <p class="text-gray-200">|</p>
    <p class="font-semibold text-primary">{{ events().length }}건</p>
  </div>
</ng-template>
