export const CenterServiceType = {
  LANGUAGE: '언어',
  ART: '미술',
  PSYCHOLOGY: '심리',
} as const;

export const CenterUserStatus = {
  PENDING: '승인 대기',
  ACCEPTED: '승인됨',
  REJECTED: '반려됨',
  CANCELED: '가입 취소',
  LEFT: '탈퇴',
} as const;
