/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { centerControllerActive } from '../fn/center/center-controller-active';
import { CenterControllerActive$Params } from '../fn/center/center-controller-active';
import { centerControllerAdminDelete } from '../fn/center/center-controller-admin-delete';
import { CenterControllerAdminDelete$Params } from '../fn/center/center-controller-admin-delete';
import { centerControllerAdminUpdate } from '../fn/center/center-controller-admin-update';
import { CenterControllerAdminUpdate$Params } from '../fn/center/center-controller-admin-update';
import { centerControllerCreate } from '../fn/center/center-controller-create';
import { CenterControllerCreate$Params } from '../fn/center/center-controller-create';
import { centerControllerCreateByOwnerId } from '../fn/center/center-controller-create-by-owner-id';
import { CenterControllerCreateByOwnerId$Params } from '../fn/center/center-controller-create-by-owner-id';
import { centerControllerDelete } from '../fn/center/center-controller-delete';
import { CenterControllerDelete$Params } from '../fn/center/center-controller-delete';
import { centerControllerFindById } from '../fn/center/center-controller-find-by-id';
import { CenterControllerFindById$Params } from '../fn/center/center-controller-find-by-id';
import { centerControllerFindPermissions } from '../fn/center/center-controller-find-permissions';
import { CenterControllerFindPermissions$Params } from '../fn/center/center-controller-find-permissions';
import { centerControllerGetMyCenterList } from '../fn/center/center-controller-get-my-center-list';
import { CenterControllerGetMyCenterList$Params } from '../fn/center/center-controller-get-my-center-list';
import { centerControllerSearchCursor } from '../fn/center/center-controller-search-cursor';
import { CenterControllerSearchCursor$Params } from '../fn/center/center-controller-search-cursor';
import { centerControllerSearchOffset } from '../fn/center/center-controller-search-offset';
import { CenterControllerSearchOffset$Params } from '../fn/center/center-controller-search-offset';
import { centerControllerUpdate } from '../fn/center/center-controller-update';
import { CenterControllerUpdate$Params } from '../fn/center/center-controller-update';
import { CenterDto } from '../models/center-dto';
import { centerInviteLogControlllerAcceptInvite } from '../fn/center/center-invite-log-controlller-accept-invite';
import { CenterInviteLogControlllerAcceptInvite$Params } from '../fn/center/center-invite-log-controlller-accept-invite';
import { centerInviteLogControlllerInviteUser } from '../fn/center/center-invite-log-controlller-invite-user';
import { CenterInviteLogControlllerInviteUser$Params } from '../fn/center/center-invite-log-controlller-invite-user';
import { centerInviteLogControlllerRejectInvite } from '../fn/center/center-invite-log-controlller-reject-invite';
import { CenterInviteLogControlllerRejectInvite$Params } from '../fn/center/center-invite-log-controlller-reject-invite';
import { centerInviteLogControlllerSearchCursor } from '../fn/center/center-invite-log-controlller-search-cursor';
import { CenterInviteLogControlllerSearchCursor$Params } from '../fn/center/center-invite-log-controlller-search-cursor';
import { centerInviteLogControlllerSearchOffset } from '../fn/center/center-invite-log-controlller-search-offset';
import { CenterInviteLogControlllerSearchOffset$Params } from '../fn/center/center-invite-log-controlller-search-offset';
import { centerJoinLogControllerAcceptJoinRequest } from '../fn/center/center-join-log-controller-accept-join-request';
import { CenterJoinLogControllerAcceptJoinRequest$Params } from '../fn/center/center-join-log-controller-accept-join-request';
import { centerJoinLogControllerCancelJoinRequest } from '../fn/center/center-join-log-controller-cancel-join-request';
import { CenterJoinLogControllerCancelJoinRequest$Params } from '../fn/center/center-join-log-controller-cancel-join-request';
import { centerJoinLogControllerJoinUser } from '../fn/center/center-join-log-controller-join-user';
import { CenterJoinLogControllerJoinUser$Params } from '../fn/center/center-join-log-controller-join-user';
import { centerJoinLogControllerRejectJoinRequest } from '../fn/center/center-join-log-controller-reject-join-request';
import { CenterJoinLogControllerRejectJoinRequest$Params } from '../fn/center/center-join-log-controller-reject-join-request';
import { centerJoinLogControllerSearchCursor } from '../fn/center/center-join-log-controller-search-cursor';
import { CenterJoinLogControllerSearchCursor$Params } from '../fn/center/center-join-log-controller-search-cursor';
import { centerJoinLogControllerSearchOffset } from '../fn/center/center-join-log-controller-search-offset';
import { CenterJoinLogControllerSearchOffset$Params } from '../fn/center/center-join-log-controller-search-offset';
import { centerRoleControllerCreateRole } from '../fn/center/center-role-controller-create-role';
import { CenterRoleControllerCreateRole$Params } from '../fn/center/center-role-controller-create-role';
import { centerRoleControllerDeleteRole } from '../fn/center/center-role-controller-delete-role';
import { CenterRoleControllerDeleteRole$Params } from '../fn/center/center-role-controller-delete-role';
import { centerRoleControllerFindRole } from '../fn/center/center-role-controller-find-role';
import { CenterRoleControllerFindRole$Params } from '../fn/center/center-role-controller-find-role';
import { centerRoleControllerFindRoles } from '../fn/center/center-role-controller-find-roles';
import { CenterRoleControllerFindRoles$Params } from '../fn/center/center-role-controller-find-roles';
import { centerRoleControllerUpdateRole } from '../fn/center/center-role-controller-update-role';
import { CenterRoleControllerUpdateRole$Params } from '../fn/center/center-role-controller-update-role';
import { CenterRoleDto } from '../models/center-role-dto';
import { centerUserControllerDelegate } from '../fn/center/center-user-controller-delegate';
import { CenterUserControllerDelegate$Params } from '../fn/center/center-user-controller-delegate';
import { centerUserControllerDeleteUser } from '../fn/center/center-user-controller-delete-user';
import { CenterUserControllerDeleteUser$Params } from '../fn/center/center-user-controller-delete-user';
import { centerUserControllerFindUserById } from '../fn/center/center-user-controller-find-user-by-id';
import { CenterUserControllerFindUserById$Params } from '../fn/center/center-user-controller-find-user-by-id';
import { centerUserControllerFindUsersByCenterId } from '../fn/center/center-user-controller-find-users-by-center-id';
import { CenterUserControllerFindUsersByCenterId$Params } from '../fn/center/center-user-controller-find-users-by-center-id';
import { centerUserControllerSearch } from '../fn/center/center-user-controller-search';
import { CenterUserControllerSearch$Params } from '../fn/center/center-user-controller-search';
import { centerUserControllerUpdateUser } from '../fn/center/center-user-controller-update-user';
import { CenterUserControllerUpdateUser$Params } from '../fn/center/center-user-controller-update-user';
import { CenterUserDto } from '../models/center-user-dto';
import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { InviteLogDto } from '../models/invite-log-dto';
import { JoinLogDto } from '../models/join-log-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { SearchCenterResponseDto } from '../models/search-center-response-dto';

@Injectable({ providedIn: 'root' })
export class CenterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `centerControllerFindPermissions()` */
  static readonly CenterControllerFindPermissionsPath = '/api/center/permissions';

  /**
   * 센터 권한 조회.
   *
   * 센터의 권한을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerFindPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerFindPermissions$Response(params?: CenterControllerFindPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return centerControllerFindPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 권한 조회.
   *
   * 센터의 권한을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerFindPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerFindPermissions(params?: CenterControllerFindPermissions$Params, context?: HttpContext): Observable<Array<string>> {
    return this.centerControllerFindPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `centerControllerGetMyCenterList()` */
  static readonly CenterControllerGetMyCenterListPath = '/api/center/me';

  /**
   * 내 센터 조회.
   *
   * 속해 있는 센터를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerGetMyCenterList()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerGetMyCenterList$Response(params?: CenterControllerGetMyCenterList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CenterDto>>> {
    return centerControllerGetMyCenterList(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 센터 조회.
   *
   * 속해 있는 센터를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerGetMyCenterList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerGetMyCenterList(params?: CenterControllerGetMyCenterList$Params, context?: HttpContext): Observable<Array<CenterDto>> {
    return this.centerControllerGetMyCenterList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CenterDto>>): Array<CenterDto> => r.body)
    );
  }

  /** Path part for operation `centerControllerFindById()` */
  static readonly CenterControllerFindByIdPath = '/api/center/{id}';

  /**
   * 센터 ID 기반 조회.
   *
   * 센터 ID를 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerFindById$Response(params: CenterControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return centerControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 ID 기반 조회.
   *
   * 센터 ID를 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerFindById(params: CenterControllerFindById$Params, context?: HttpContext): Observable<CenterDto> {
    return this.centerControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `centerControllerDelete()` */
  static readonly CenterControllerDeletePath = '/api/center/{id}';

  /**
   * 센터 삭제.
   *
   * 센터를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerDelete$Response(params: CenterControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return centerControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 삭제.
   *
   * 센터를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerDelete(params: CenterControllerDelete$Params, context?: HttpContext): Observable<CenterDto> {
    return this.centerControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `centerControllerUpdate()` */
  static readonly CenterControllerUpdatePath = '/api/center/{id}';

  /**
   * 센터 수정.
   *
   * 센터를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerControllerUpdate$Response(params: CenterControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return centerControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 수정.
   *
   * 센터를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerControllerUpdate(params: CenterControllerUpdate$Params, context?: HttpContext): Observable<CenterDto> {
    return this.centerControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `centerControllerSearchOffset()` */
  static readonly CenterControllerSearchOffsetPath = '/api/center/search/offset';

  /**
   * 센터 오프셋 기반 검색.
   *
   * 센터를 오프셋 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerSearchOffset$Response(params: CenterControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchCenterResponseDto>> {
    return centerControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 오프셋 기반 검색.
   *
   * 센터를 오프셋 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerSearchOffset(params: CenterControllerSearchOffset$Params, context?: HttpContext): Observable<SearchCenterResponseDto> {
    return this.centerControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchCenterResponseDto>): SearchCenterResponseDto => r.body)
    );
  }

  /** Path part for operation `centerControllerSearchCursor()` */
  static readonly CenterControllerSearchCursorPath = '/api/center/search/cursor';

  /**
   * 센터 커서 기반 검색.
   *
   * 센터를 커서 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerSearchCursor$Response(params: CenterControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return centerControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 커서 기반 검색.
   *
   * 센터를 커서 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerSearchCursor(params: CenterControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.centerControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerControllerCreate()` */
  static readonly CenterControllerCreatePath = '/api/center';

  /**
   * 센터 생성.
   *
   * 센터를 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerControllerCreate$Response(params: CenterControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return centerControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 생성.
   *
   * 센터를 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerControllerCreate(params: CenterControllerCreate$Params, context?: HttpContext): Observable<CenterDto> {
    return this.centerControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `centerControllerCreateByOwnerId()` */
  static readonly CenterControllerCreateByOwnerIdPath = '/api/center/{ownerId}';

  /**
   * 센터 생성(관리자).
   *
   * 센터를 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerCreateByOwnerId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerControllerCreateByOwnerId$Response(params: CenterControllerCreateByOwnerId$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return centerControllerCreateByOwnerId(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 생성(관리자).
   *
   * 센터를 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerCreateByOwnerId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerControllerCreateByOwnerId(params: CenterControllerCreateByOwnerId$Params, context?: HttpContext): Observable<CenterDto> {
    return this.centerControllerCreateByOwnerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `centerControllerAdminDelete()` */
  static readonly CenterControllerAdminDeletePath = '/api/center/{id}/admin';

  /**
   * 센터 삭제(관리자).
   *
   * 센터를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerAdminDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerAdminDelete$Response(params: CenterControllerAdminDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return centerControllerAdminDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 삭제(관리자).
   *
   * 센터를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerAdminDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerControllerAdminDelete(params: CenterControllerAdminDelete$Params, context?: HttpContext): Observable<CenterDto> {
    return this.centerControllerAdminDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `centerControllerAdminUpdate()` */
  static readonly CenterControllerAdminUpdatePath = '/api/center/{id}/admin';

  /**
   * 센터 수정(관리자).
   *
   * 센터를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerAdminUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerControllerAdminUpdate$Response(params: CenterControllerAdminUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return centerControllerAdminUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 수정(관리자).
   *
   * 센터를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerAdminUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerControllerAdminUpdate(params: CenterControllerAdminUpdate$Params, context?: HttpContext): Observable<CenterDto> {
    return this.centerControllerAdminUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `centerControllerActive()` */
  static readonly CenterControllerActivePath = '/api/center/{id}/active';

  /**
   * 센터 활성화.
   *
   * 센터를 활성화합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerControllerActive()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerControllerActive$Response(params: CenterControllerActive$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return centerControllerActive(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 활성화.
   *
   * 센터를 활성화합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerControllerActive$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerControllerActive(params: CenterControllerActive$Params, context?: HttpContext): Observable<CenterDto> {
    return this.centerControllerActive$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `centerUserControllerFindUsersByCenterId()` */
  static readonly CenterUserControllerFindUsersByCenterIdPath = '/api/center/{id}/users';

  /**
   * 센터 ID 기반 구성원 목록 조회.
   *
   * 센터 ID를 기반으로 구성원 전체를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerFindUsersByCenterId()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerFindUsersByCenterId$Response(params: CenterUserControllerFindUsersByCenterId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CenterUserDto>>> {
    return centerUserControllerFindUsersByCenterId(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 ID 기반 구성원 목록 조회.
   *
   * 센터 ID를 기반으로 구성원 전체를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerFindUsersByCenterId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerFindUsersByCenterId(params: CenterUserControllerFindUsersByCenterId$Params, context?: HttpContext): Observable<Array<CenterUserDto>> {
    return this.centerUserControllerFindUsersByCenterId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CenterUserDto>>): Array<CenterUserDto> => r.body)
    );
  }

  /** Path part for operation `centerUserControllerSearch()` */
  static readonly CenterUserControllerSearchPath = '/api/center/{id}/users/search';

  /**
   * 센터 ID 기반 구성원 검색.
   *
   * 센터 ID를 기반으로 구성원 전체를 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerSearch$Response(params: CenterUserControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return centerUserControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 ID 기반 구성원 검색.
   *
   * 센터 ID를 기반으로 구성원 전체를 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerSearch(params: CenterUserControllerSearch$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.centerUserControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerUserControllerFindUserById()` */
  static readonly CenterUserControllerFindUserByIdPath = '/api/center/{id}/users/{userId}';

  /**
   * 센터 구성원 ID 기반 조회.
   *
   * 센터 구성원을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerFindUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerFindUserById$Response(params: CenterUserControllerFindUserById$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterUserDto>> {
    return centerUserControllerFindUserById(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 구성원 ID 기반 조회.
   *
   * 센터 구성원을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerFindUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerFindUserById(params: CenterUserControllerFindUserById$Params, context?: HttpContext): Observable<CenterUserDto> {
    return this.centerUserControllerFindUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterUserDto>): CenterUserDto => r.body)
    );
  }

  /** Path part for operation `centerUserControllerDeleteUser()` */
  static readonly CenterUserControllerDeleteUserPath = '/api/center/{id}/users/{userId}';

  /**
   * 센터 구성원 추방.
   *
   * 센터 구성원을 추방합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerDeleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerDeleteUser$Response(params: CenterUserControllerDeleteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterUserDto>> {
    return centerUserControllerDeleteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 구성원 추방.
   *
   * 센터 구성원을 추방합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerDeleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerDeleteUser(params: CenterUserControllerDeleteUser$Params, context?: HttpContext): Observable<CenterUserDto> {
    return this.centerUserControllerDeleteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterUserDto>): CenterUserDto => r.body)
    );
  }

  /** Path part for operation `centerUserControllerUpdateUser()` */
  static readonly CenterUserControllerUpdateUserPath = '/api/center/{id}/users/{userId}';

  /**
   * 센터 구성원 역할 변경.
   *
   * 센터 구성원의 역할을 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerUpdateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerUserControllerUpdateUser$Response(params: CenterUserControllerUpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterUserDto>> {
    return centerUserControllerUpdateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 구성원 역할 변경.
   *
   * 센터 구성원의 역할을 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerUpdateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerUserControllerUpdateUser(params: CenterUserControllerUpdateUser$Params, context?: HttpContext): Observable<CenterUserDto> {
    return this.centerUserControllerUpdateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterUserDto>): CenterUserDto => r.body)
    );
  }

  /** Path part for operation `centerUserControllerDelegate()` */
  static readonly CenterUserControllerDelegatePath = '/api/center/{id}/users/{userId}/delegate';

  /**
   * 센터 대표 위임.
   *
   * 센터 대표를 위임합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerDelegate()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerDelegate$Response(params: CenterUserControllerDelegate$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterUserDto>> {
    return centerUserControllerDelegate(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 대표 위임.
   *
   * 센터 대표를 위임합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerDelegate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerDelegate(params: CenterUserControllerDelegate$Params, context?: HttpContext): Observable<CenterUserDto> {
    return this.centerUserControllerDelegate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterUserDto>): CenterUserDto => r.body)
    );
  }

  /** Path part for operation `centerRoleControllerFindRoles()` */
  static readonly CenterRoleControllerFindRolesPath = '/api/center/{id}/roles';

  /**
   * 센터 ID 기반 역할 조회.
   *
   * 센터 ID를 기반으로 모든 역할을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerRoleControllerFindRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerFindRoles$Response(params: CenterRoleControllerFindRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CenterRoleDto>>> {
    return centerRoleControllerFindRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 ID 기반 역할 조회.
   *
   * 센터 ID를 기반으로 모든 역할을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerRoleControllerFindRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerFindRoles(params: CenterRoleControllerFindRoles$Params, context?: HttpContext): Observable<Array<CenterRoleDto>> {
    return this.centerRoleControllerFindRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CenterRoleDto>>): Array<CenterRoleDto> => r.body)
    );
  }

  /** Path part for operation `centerRoleControllerCreateRole()` */
  static readonly CenterRoleControllerCreateRolePath = '/api/center/{id}/roles';

  /**
   * 센터 내 역할 생성.
   *
   * 센터 ID를 기반으로 역할을 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerRoleControllerCreateRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerRoleControllerCreateRole$Response(params: CenterRoleControllerCreateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterRoleDto>> {
    return centerRoleControllerCreateRole(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 내 역할 생성.
   *
   * 센터 ID를 기반으로 역할을 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerRoleControllerCreateRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerRoleControllerCreateRole(params: CenterRoleControllerCreateRole$Params, context?: HttpContext): Observable<CenterRoleDto> {
    return this.centerRoleControllerCreateRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterRoleDto>): CenterRoleDto => r.body)
    );
  }

  /** Path part for operation `centerRoleControllerFindRole()` */
  static readonly CenterRoleControllerFindRolePath = '/api/center/{id}/roles/{roleId}';

  /**
   * 센터 역할 ID 기반 조회.
   *
   * 센터의 역할을 ID 기반으로 조회
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerRoleControllerFindRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerFindRole$Response(params: CenterRoleControllerFindRole$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterRoleDto>> {
    return centerRoleControllerFindRole(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 역할 ID 기반 조회.
   *
   * 센터의 역할을 ID 기반으로 조회
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerRoleControllerFindRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerFindRole(params: CenterRoleControllerFindRole$Params, context?: HttpContext): Observable<CenterRoleDto> {
    return this.centerRoleControllerFindRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterRoleDto>): CenterRoleDto => r.body)
    );
  }

  /** Path part for operation `centerRoleControllerDeleteRole()` */
  static readonly CenterRoleControllerDeleteRolePath = '/api/center/{id}/roles/{roleId}';

  /**
   * 센터 역할 ID를 기반으로 역할을 삭제합니다.
   *
   * 센터 역할 삭제
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerRoleControllerDeleteRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerDeleteRole$Response(params: CenterRoleControllerDeleteRole$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterRoleDto>> {
    return centerRoleControllerDeleteRole(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 역할 ID를 기반으로 역할을 삭제합니다.
   *
   * 센터 역할 삭제
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerRoleControllerDeleteRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerDeleteRole(params: CenterRoleControllerDeleteRole$Params, context?: HttpContext): Observable<CenterRoleDto> {
    return this.centerRoleControllerDeleteRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterRoleDto>): CenterRoleDto => r.body)
    );
  }

  /** Path part for operation `centerRoleControllerUpdateRole()` */
  static readonly CenterRoleControllerUpdateRolePath = '/api/center/{id}/roles/{roleId}';

  /**
   * 센터 역할 수정.
   *
   * 센터 역할 ID를 기반으로 역할을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerRoleControllerUpdateRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerRoleControllerUpdateRole$Response(params: CenterRoleControllerUpdateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterRoleDto>> {
    return centerRoleControllerUpdateRole(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 역할 수정.
   *
   * 센터 역할 ID를 기반으로 역할을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerRoleControllerUpdateRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerRoleControllerUpdateRole(params: CenterRoleControllerUpdateRole$Params, context?: HttpContext): Observable<CenterRoleDto> {
    return this.centerRoleControllerUpdateRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterRoleDto>): CenterRoleDto => r.body)
    );
  }

  /** Path part for operation `centerJoinLogControllerSearchOffset()` */
  static readonly CenterJoinLogControllerSearchOffsetPath = '/api/center/join/search/offset';

  /**
   * 센터 가입 내역 오프셋 기반 검색.
   *
   * 센터 가입 내역을 오프셋 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerSearchOffset$Response(params: CenterJoinLogControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return centerJoinLogControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 가입 내역 오프셋 기반 검색.
   *
   * 센터 가입 내역을 오프셋 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerSearchOffset(params: CenterJoinLogControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.centerJoinLogControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerJoinLogControllerSearchCursor()` */
  static readonly CenterJoinLogControllerSearchCursorPath = '/api/center/join/search/cursor';

  /**
   * 센터 가입 내역 커서 기반 검색.
   *
   * 센터 가입 내역을 커서 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerSearchCursor$Response(params: CenterJoinLogControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return centerJoinLogControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 가입 내역 커서 기반 검색.
   *
   * 센터 가입 내역을 커서 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerSearchCursor(params: CenterJoinLogControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.centerJoinLogControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerJoinLogControllerJoinUser()` */
  static readonly CenterJoinLogControllerJoinUserPath = '/api/center/{id}/join';

  /**
   * 센터 가입 요청.
   *
   * 센터에 가입을 요청합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerJoinUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerJoinUser$Response(params: CenterJoinLogControllerJoinUser$Params, context?: HttpContext): Observable<StrictHttpResponse<JoinLogDto>> {
    return centerJoinLogControllerJoinUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 가입 요청.
   *
   * 센터에 가입을 요청합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerJoinUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerJoinUser(params: CenterJoinLogControllerJoinUser$Params, context?: HttpContext): Observable<JoinLogDto> {
    return this.centerJoinLogControllerJoinUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<JoinLogDto>): JoinLogDto => r.body)
    );
  }

  /** Path part for operation `centerJoinLogControllerAcceptJoinRequest()` */
  static readonly CenterJoinLogControllerAcceptJoinRequestPath = '/api/center/{id}/join/{joinId}/accept';

  /**
   * 센터 가입 승인.
   *
   * 센터에 가입을 요청한 사용자의 가입을 승인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerAcceptJoinRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerAcceptJoinRequest$Response(params: CenterJoinLogControllerAcceptJoinRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<JoinLogDto>> {
    return centerJoinLogControllerAcceptJoinRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 가입 승인.
   *
   * 센터에 가입을 요청한 사용자의 가입을 승인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerAcceptJoinRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerAcceptJoinRequest(params: CenterJoinLogControllerAcceptJoinRequest$Params, context?: HttpContext): Observable<JoinLogDto> {
    return this.centerJoinLogControllerAcceptJoinRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<JoinLogDto>): JoinLogDto => r.body)
    );
  }

  /** Path part for operation `centerJoinLogControllerRejectJoinRequest()` */
  static readonly CenterJoinLogControllerRejectJoinRequestPath = '/api/center/{id}/join/{joinId}/reject';

  /**
   * 센터 가입 거부.
   *
   * 센터에 가입을 요청한 사용자의 가입을 거부합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerRejectJoinRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerRejectJoinRequest$Response(params: CenterJoinLogControllerRejectJoinRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<JoinLogDto>> {
    return centerJoinLogControllerRejectJoinRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 가입 거부.
   *
   * 센터에 가입을 요청한 사용자의 가입을 거부합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerRejectJoinRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerRejectJoinRequest(params: CenterJoinLogControllerRejectJoinRequest$Params, context?: HttpContext): Observable<JoinLogDto> {
    return this.centerJoinLogControllerRejectJoinRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<JoinLogDto>): JoinLogDto => r.body)
    );
  }

  /** Path part for operation `centerJoinLogControllerCancelJoinRequest()` */
  static readonly CenterJoinLogControllerCancelJoinRequestPath = '/api/center/join/{id}/cancel';

  /**
   * 가입 요청 취소.
   *
   * 가입 요청을 취소합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerCancelJoinRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerCancelJoinRequest$Response(params: CenterJoinLogControllerCancelJoinRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<JoinLogDto>> {
    return centerJoinLogControllerCancelJoinRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * 가입 요청 취소.
   *
   * 가입 요청을 취소합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerCancelJoinRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerCancelJoinRequest(params: CenterJoinLogControllerCancelJoinRequest$Params, context?: HttpContext): Observable<JoinLogDto> {
    return this.centerJoinLogControllerCancelJoinRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<JoinLogDto>): JoinLogDto => r.body)
    );
  }

  /** Path part for operation `centerInviteLogControlllerSearchOffset()` */
  static readonly CenterInviteLogControlllerSearchOffsetPath = '/api/center/invite/search/offset';

  /**
   * 센터 초대 내역 오프셋 기반 검색.
   *
   * 센터 초대 내역을 오프셋 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerInviteLogControlllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerSearchOffset$Response(params: CenterInviteLogControlllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return centerInviteLogControlllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 초대 내역 오프셋 기반 검색.
   *
   * 센터 초대 내역을 오프셋 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerInviteLogControlllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerSearchOffset(params: CenterInviteLogControlllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.centerInviteLogControlllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerInviteLogControlllerSearchCursor()` */
  static readonly CenterInviteLogControlllerSearchCursorPath = '/api/center/invite/search/cursor';

  /**
   * 센터 초대 내역 커서 기반 검색.
   *
   * 센터 초대 내역을 커서 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerInviteLogControlllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerSearchCursor$Response(params: CenterInviteLogControlllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return centerInviteLogControlllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 초대 내역 커서 기반 검색.
   *
   * 센터 초대 내역을 커서 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerInviteLogControlllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerSearchCursor(params: CenterInviteLogControlllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.centerInviteLogControlllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerInviteLogControlllerInviteUser()` */
  static readonly CenterInviteLogControlllerInviteUserPath = '/api/center/{id}/invite';

  /**
   * 센터 구성원 초대.
   *
   * 센터 멤버를 초대합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerInviteLogControlllerInviteUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerInviteLogControlllerInviteUser$Response(params: CenterInviteLogControlllerInviteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<InviteLogDto>> {
    return centerInviteLogControlllerInviteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 구성원 초대.
   *
   * 센터 멤버를 초대합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerInviteLogControlllerInviteUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerInviteLogControlllerInviteUser(params: CenterInviteLogControlllerInviteUser$Params, context?: HttpContext): Observable<InviteLogDto> {
    return this.centerInviteLogControlllerInviteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<InviteLogDto>): InviteLogDto => r.body)
    );
  }

  /** Path part for operation `centerInviteLogControlllerAcceptInvite()` */
  static readonly CenterInviteLogControlllerAcceptInvitePath = '/api/center/invite/{id}/accept';

  /**
   * 센터 초대 수락.
   *
   * 센터 초대를 수락합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerInviteLogControlllerAcceptInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerAcceptInvite$Response(params: CenterInviteLogControlllerAcceptInvite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return centerInviteLogControlllerAcceptInvite(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 초대 수락.
   *
   * 센터 초대를 수락합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerInviteLogControlllerAcceptInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerAcceptInvite(params: CenterInviteLogControlllerAcceptInvite$Params, context?: HttpContext): Observable<void> {
    return this.centerInviteLogControlllerAcceptInvite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `centerInviteLogControlllerRejectInvite()` */
  static readonly CenterInviteLogControlllerRejectInvitePath = '/api/center/invite/{id}/reject';

  /**
   * 센터 초대 거절.
   *
   * 센터 초대를 거절합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerInviteLogControlllerRejectInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerRejectInvite$Response(params: CenterInviteLogControlllerRejectInvite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return centerInviteLogControlllerRejectInvite(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 초대 거절.
   *
   * 센터 초대를 거절합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerInviteLogControlllerRejectInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerRejectInvite(params: CenterInviteLogControlllerRejectInvite$Params, context?: HttpContext): Observable<void> {
    return this.centerInviteLogControlllerRejectInvite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
