@if (renderItem()) {
  <ng-template option />
} @else {
  <div
    class="theme-text menu-option"
    [attr.data-focus]="focus()"
    [attr.data-theme]="theme()"
    (mouseenter)="mouseEnter.emit()"
    (mouseleave)="mouseLeave.emit()"
  >
    <p>{{ label() }}</p>
    @if (active()) {
      <app-icon name="mdi:check" size="sm" class="text-primary" />
    }
  </div>
}
