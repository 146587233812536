import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'app-modal-adapter',
  template: '',
})
export class ModalAdapter<T = any> implements OnInit {
  readonly modalService = inject(ModalService);
  private readonly _props = inject(DIALOG_DATA);

  private props$ = new Subject<T>();
  props = toSignal(this.props$);

  ngOnInit(): void {
    this.props$.next(this._props);
  }

  close() {
    this.modalService.close();
  }
}
