<section
  class="w-screen md:w-auto md:min-w-[30rem] rounded-lg"
  [ngClass]="theme() === 'dark' ? 'bg-dark' : 'bg-white'"
>
  <form class="flex flex-col gap-4 p-4" [formGroup]="form">
    <app-select-color label="색상" formControlName="color" />
    <app-input
      formControlName="title"
      required
      label="제목"
      placeholder="제목을 입력해주세요."
    />
    <div class="flex flex-col gap-2">
      <div class="flex w-full justify-between items-center">
        <app-label [required]="true">날짜</app-label>
        <div class="flex gap-2 items-center">
          <app-checkbox formControlName="hasTime" label="시간 포함" />
          @if (hasTime()) {
            <app-checkbox formControlName="allDay" label="종일" />
          }
          <app-checkbox formControlName="hasEndDate" label="종료일 포함" />
        </div>
      </div>
      <div class="flex md:flex-row flex-col gap-2.5 md:items-center">
        <app-input-date-picker formControlName="startDate" class="w-full" />
        @if (hasTime() && !allDay()) {
          <app-input-time
            [required]="hasTime() && !allDay() ? 'true' : 'false'"
            formControlName="startTime"
            class="w-full"
          />
        }

        @if (hasEndDate()) {
          <p [attr.data-theme]="theme()" class="theme-text">-</p>
          <app-input-date-picker
            [required]="hasEndDate() ? 'true' : 'false'"
            formControlName="endDate"
            class="w-full"
          />
          @if (hasTime() && !allDay()) {
            <app-input-time
              [required]="hasTime() && !allDay() ? 'true' : 'false'"
              formControlName="endTime"
              class="w-full"
            />
          }
        }
      </div>
    </div>
    <app-textarea
      formControlName="content"
      label="내용"
      placeholder="내용을 입력해주세요."
    />
  </form>
  <app-divider />
  <div class="flex w-full p-4">
    <app-button
      (click)="submit()"
      expand
      class="w-full"
      [disabled]="!form.valid"
      >{{ event() ? "수정" : "등록" }}</app-button
    >
  </div>
</section>
