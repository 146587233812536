/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Hint } from '../hint/hint.component';
import { Icon } from '../icon/icon.component';
import { Label } from '../label/label.component';
import { Spinner } from '../spinner/spinner.component';
import { FileItem, FileUploaderAdapter } from './file-uploader.adapter';

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [
    CommonModule,
    DragDropModule,
    Icon,
    Label,
    Hint,
    FormsModule,
    Spinner,
  ],
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploader,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FileUploader,
      multi: true,
    },
  ],
})
export class FileUploader extends FileUploaderAdapter {
  isDragOvered = signal(false);

  onDragOver(e: DragEvent) {
    e.preventDefault();
    this.isDragOvered.set(true);
  }

  onDragLeave(e: DragEvent) {
    e.preventDefault();
    this.isDragOvered.set(false);
  }

  openImage(url: string) {
    window.open(url);
  }

  onDrop(ev: CdkDragDrop<FileItem[]>) {
    if (ev.container === ev.previousContainer) {
      this.items.update((items) => {
        const temp = items;
        moveItemInArray(temp, ev.previousIndex, ev.currentIndex);
        return temp;
      });

      this.writeValue(this.items().map((item) => item.url));
      this.onChange(this.value());
    }
  }
}
