import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable } from '@angular/core';
import { AlertComponent } from '../components/alert/alert.component';
import { AlertOptions, AlertResult } from '../components/alert/alert.type';

@Injectable({ providedIn: 'root' })
export class AlertService {
  dialog = inject(Dialog);

  open(options: AlertOptions) {
    const isMobile = window.innerWidth < 768;
    return this.dialog.open<AlertResult>(AlertComponent, {
      maxHeight: '100%',
      data: options,
      disableClose: true,
      ariaModal: true,
      restoreFocus: true,
    });
  }
}
