/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UserDto } from '../../models/user-dto';

export interface ParentControllerFindParent$Params {

/**
 * 보호자 이름
 */
  name?: string;

/**
 * 보호자 연락처
 */
  contact?: string;

/**
 * 보호자 계정
 */
  email?: string;

/**
 * 아동 ID
 */
  childId?: string;
}

export function parentControllerFindParent(http: HttpClient, rootUrl: string, params?: ParentControllerFindParent$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
  const rb = new RequestBuilder(rootUrl, parentControllerFindParent.PATH, 'get');
  if (params) {
    rb.query('name', params.name, {});
    rb.query('contact', params.contact, {});
    rb.query('email', params.email, {});
    rb.query('childId', params.childId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<UserDto>>;
    })
  );
}

parentControllerFindParent.PATH = '/api/user/parent/search';
