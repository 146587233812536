<section class="relative" [cdkMenuTriggerFor]="c">
  <app-input
    (click)="handleClick($event)"
    [color]="currentColor()"
    [size]="currentSize()"
    [rounded]="currentRounded()"
    [required]="required()"
    [readonly]="readonly()"
    [disabled]="disabled()"
    [autofocus]="autofocus()"
    [autocomplete]="autocomplete()"
    [spellcheck]="spellcheck()"
    [label]="label()"
    [(value)]="inputValue"
    [placeholder]="placeholder()"
    (valueChange)="handleInput($event)"
    (inputKeydown)="handleKeyDown($event)"
    type="date"
  >
    <app-icon
      (click)="handleOpenCalendar()"
      [attr.data-theme]="theme()"
      class="cursor-pointer theme-text"
      size="sm"
      icons
      [name]="icon() || 'solar:calendar-bold-duotone'"
    />
  </app-input>
</section>

<ng-template #c>
  <div
    cdkMenu
    class="shadow-md fixed md:min-w-max md:inset-auto md:w-auto md:h-auto inset-0 items-center flex p-4 md:p-0 w-screen md:bg-transparent bg-black/70 h-screen z-[999] md:absolute md:top-[calc(100%+4px)]"
  >
    <app-input-calendar
      [attr.data-theme]="theme()"
      [date]="date()"
      (select)="handleSelect($event)"
    />
  </div>
</ng-template>
