import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputPassword } from '../../../input/password/input-password.component';
import { InputFormFieldAdapter } from '../../adapters/input-form-field.adapter';
import { PasswordFormField } from '../../auto-form.type';
import { ErrorDirective } from '../../directives/error.directive';
import { PasswordValidator } from '../../validators/password.validator';

@Component({
  selector: 'app-password-form-field',
  standalone: true,
  imports: [InputPassword, ErrorDirective],
  templateUrl: './password-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PasswordFormFieldComponent,
      multi: true,
    },
  ],
})
export class PasswordFormFieldComponent extends InputFormFieldAdapter<
  PasswordFormField<any>
> {
  override ngOnInit(): void {
    super.ngOnInit();

    if (this.formControl) {
      this.formControl.addValidators([PasswordValidator()]);
    }
  }
}
