/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { FindEmailResponseDto } from '../models/find-email-response-dto';
import { FindPasswordConfirmResponseDto } from '../models/find-password-confirm-response-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { parentControllerFindParent } from '../fn/user/parent-controller-find-parent';
import { ParentControllerFindParent$Params } from '../fn/user/parent-controller-find-parent';
import { userControllerAdminUpdateEmail } from '../fn/user/user-controller-admin-update-email';
import { UserControllerAdminUpdateEmail$Params } from '../fn/user/user-controller-admin-update-email';
import { userControllerBlock } from '../fn/user/user-controller-block';
import { UserControllerBlock$Params } from '../fn/user/user-controller-block';
import { userControllerCheckEmail } from '../fn/user/user-controller-check-email';
import { UserControllerCheckEmail$Params } from '../fn/user/user-controller-check-email';
import { userControllerCheckPassword } from '../fn/user/user-controller-check-password';
import { UserControllerCheckPassword$Params } from '../fn/user/user-controller-check-password';
import { userControllerConnectAuthByEmail } from '../fn/user/user-controller-connect-auth-by-email';
import { UserControllerConnectAuthByEmail$Params } from '../fn/user/user-controller-connect-auth-by-email';
import { userControllerConnectAuthBySns } from '../fn/user/user-controller-connect-auth-by-sns';
import { UserControllerConnectAuthBySns$Params } from '../fn/user/user-controller-connect-auth-by-sns';
import { userControllerCreate } from '../fn/user/user-controller-create';
import { UserControllerCreate$Params } from '../fn/user/user-controller-create';
import { userControllerDelete } from '../fn/user/user-controller-delete';
import { UserControllerDelete$Params } from '../fn/user/user-controller-delete';
import { userControllerDeleteMany } from '../fn/user/user-controller-delete-many';
import { UserControllerDeleteMany$Params } from '../fn/user/user-controller-delete-many';
import { userControllerFindByContact } from '../fn/user/user-controller-find-by-contact';
import { UserControllerFindByContact$Params } from '../fn/user/user-controller-find-by-contact';
import { userControllerFindById } from '../fn/user/user-controller-find-by-id';
import { UserControllerFindById$Params } from '../fn/user/user-controller-find-by-id';
import { userControllerFindEmail } from '../fn/user/user-controller-find-email';
import { UserControllerFindEmail$Params } from '../fn/user/user-controller-find-email';
import { userControllerFindPasswordConfirm } from '../fn/user/user-controller-find-password-confirm';
import { UserControllerFindPasswordConfirm$Params } from '../fn/user/user-controller-find-password-confirm';
import { userControllerGetMe } from '../fn/user/user-controller-get-me';
import { UserControllerGetMe$Params } from '../fn/user/user-controller-get-me';
import { userControllerLogin } from '../fn/user/user-controller-login';
import { UserControllerLogin$Params } from '../fn/user/user-controller-login';
import { userControllerLogout } from '../fn/user/user-controller-logout';
import { UserControllerLogout$Params } from '../fn/user/user-controller-logout';
import { userControllerNoAuthUpdatePassword } from '../fn/user/user-controller-no-auth-update-password';
import { UserControllerNoAuthUpdatePassword$Params } from '../fn/user/user-controller-no-auth-update-password';
import { userControllerRefresh } from '../fn/user/user-controller-refresh';
import { UserControllerRefresh$Params } from '../fn/user/user-controller-refresh';
import { userControllerSearchCursor } from '../fn/user/user-controller-search-cursor';
import { UserControllerSearchCursor$Params } from '../fn/user/user-controller-search-cursor';
import { userControllerSearchOffset } from '../fn/user/user-controller-search-offset';
import { UserControllerSearchOffset$Params } from '../fn/user/user-controller-search-offset';
import { userControllerSearchOffsetAuth } from '../fn/user/user-controller-search-offset-auth';
import { UserControllerSearchOffsetAuth$Params } from '../fn/user/user-controller-search-offset-auth';
import { userControllerSendCode } from '../fn/user/user-controller-send-code';
import { UserControllerSendCode$Params } from '../fn/user/user-controller-send-code';
import { userControllerSendPasswordResetEmail } from '../fn/user/user-controller-send-password-reset-email';
import { UserControllerSendPasswordResetEmail$Params } from '../fn/user/user-controller-send-password-reset-email';
import { userControllerUnblock } from '../fn/user/user-controller-unblock';
import { UserControllerUnblock$Params } from '../fn/user/user-controller-unblock';
import { userControllerUpdate } from '../fn/user/user-controller-update';
import { UserControllerUpdate$Params } from '../fn/user/user-controller-update';
import { userControllerUpdateContact } from '../fn/user/user-controller-update-contact';
import { UserControllerUpdateContact$Params } from '../fn/user/user-controller-update-contact';
import { userControllerUpdateEmail } from '../fn/user/user-controller-update-email';
import { UserControllerUpdateEmail$Params } from '../fn/user/user-controller-update-email';
import { userControllerUpdateNotificationEnabled } from '../fn/user/user-controller-update-notification-enabled';
import { UserControllerUpdateNotificationEnabled$Params } from '../fn/user/user-controller-update-notification-enabled';
import { userControllerUpdatePasswordOnLogin } from '../fn/user/user-controller-update-password-on-login';
import { UserControllerUpdatePasswordOnLogin$Params } from '../fn/user/user-controller-update-password-on-login';
import { userControllerVerifyCode } from '../fn/user/user-controller-verify-code';
import { UserControllerVerifyCode$Params } from '../fn/user/user-controller-verify-code';
import { userControllerWithdraw } from '../fn/user/user-controller-withdraw';
import { UserControllerWithdraw$Params } from '../fn/user/user-controller-withdraw';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userControllerSearchOffset()` */
  static readonly UserControllerSearchOffsetPath = '/api/user/search/offset';

  /**
   * 사용자 오프셋 기반 조회.
   *
   * 사용자를 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerSearchOffset$Response(params: UserControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return userControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 오프셋 기반 조회.
   *
   * 사용자를 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerSearchOffset(params: UserControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.userControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `userControllerSearchCursor()` */
  static readonly UserControllerSearchCursorPath = '/api/user/search/cursor';

  /**
   * 사용자 커서 기반 조회.
   *
   * 사용자를 커서 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerSearchCursor$Response(params: UserControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return userControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 커서 기반 조회.
   *
   * 사용자를 커서 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerSearchCursor(params: UserControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.userControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `userControllerSearchOffsetAuth()` */
  static readonly UserControllerSearchOffsetAuthPath = '/api/user/search/offset/auth';

  /**
   * AUTH 오프셋 기반 조회.
   *
   * AUTH 를 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerSearchOffsetAuth()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerSearchOffsetAuth$Response(params: UserControllerSearchOffsetAuth$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return userControllerSearchOffsetAuth(this.http, this.rootUrl, params, context);
  }

  /**
   * AUTH 오프셋 기반 조회.
   *
   * AUTH 를 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerSearchOffsetAuth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerSearchOffsetAuth(params: UserControllerSearchOffsetAuth$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.userControllerSearchOffsetAuth$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `userControllerCheckEmail()` */
  static readonly UserControllerCheckEmailPath = '/api/user/check/email';

  /**
   * 이메일 중복 확인.
   *
   * 이메일 중복을 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerCheckEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerCheckEmail$Response(params: UserControllerCheckEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerCheckEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 이메일 중복 확인.
   *
   * 이메일 중복을 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerCheckEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerCheckEmail(params: UserControllerCheckEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerCheckEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerFindByContact()` */
  static readonly UserControllerFindByContactPath = '/api/user/check/contact';

  /**
   * 연락처 중복 확인.
   *
   * 연락처 중복을 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerFindByContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerFindByContact$Response(params: UserControllerFindByContact$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return userControllerFindByContact(this.http, this.rootUrl, params, context);
  }

  /**
   * 연락처 중복 확인.
   *
   * 연락처 중복을 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerFindByContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerFindByContact(params: UserControllerFindByContact$Params, context?: HttpContext): Observable<UserDto> {
    return this.userControllerFindByContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `userControllerGetMe()` */
  static readonly UserControllerGetMePath = '/api/user/me';

  /**
   * 내 정보 조회.
   *
   * 내 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerGetMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetMe$Response(params?: UserControllerGetMe$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return userControllerGetMe(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 정보 조회.
   *
   * 내 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerGetMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetMe(params?: UserControllerGetMe$Params, context?: HttpContext): Observable<UserDto> {
    return this.userControllerGetMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `userControllerFindById()` */
  static readonly UserControllerFindByIdPath = '/api/user/{id}';

  /**
   * 사용자 ID 기반 조회.
   *
   * 사용자를 ID 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerFindById$Response(params: UserControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return userControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 ID 기반 조회.
   *
   * 사용자를 ID 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerFindById(params: UserControllerFindById$Params, context?: HttpContext): Observable<UserDto> {
    return this.userControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `userControllerDelete()` */
  static readonly UserControllerDeletePath = '/api/user/{id}';

  /**
   * 사용자 삭제.
   *
   * 사용자를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerDelete$Response(params: UserControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return userControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 삭제.
   *
   * 사용자를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerDelete(params: UserControllerDelete$Params, context?: HttpContext): Observable<UserDto> {
    return this.userControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `userControllerFindEmail()` */
  static readonly UserControllerFindEmailPath = '/api/user/find/email';

  /**
   * 이메일 찾기.
   *
   * 사용자 연락처와 이름를 기반으로 해당 회원의 모든 이메일을 찾습니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerFindEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerFindEmail$Response(params: UserControllerFindEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FindEmailResponseDto>>> {
    return userControllerFindEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 이메일 찾기.
   *
   * 사용자 연락처와 이름를 기반으로 해당 회원의 모든 이메일을 찾습니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerFindEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerFindEmail(params: UserControllerFindEmail$Params, context?: HttpContext): Observable<Array<FindEmailResponseDto>> {
    return this.userControllerFindEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FindEmailResponseDto>>): Array<FindEmailResponseDto> => r.body)
    );
  }

  /** Path part for operation `userControllerFindPasswordConfirm()` */
  static readonly UserControllerFindPasswordConfirmPath = '/api/user/find-password-confirm';

  /**
   * 비밀번호 재설정 확인.
   *
   * 입력받은 정보로 비밀번호 재설정을 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerFindPasswordConfirm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerFindPasswordConfirm$Response(params: UserControllerFindPasswordConfirm$Params, context?: HttpContext): Observable<StrictHttpResponse<FindPasswordConfirmResponseDto>> {
    return userControllerFindPasswordConfirm(this.http, this.rootUrl, params, context);
  }

  /**
   * 비밀번호 재설정 확인.
   *
   * 입력받은 정보로 비밀번호 재설정을 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerFindPasswordConfirm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerFindPasswordConfirm(params: UserControllerFindPasswordConfirm$Params, context?: HttpContext): Observable<FindPasswordConfirmResponseDto> {
    return this.userControllerFindPasswordConfirm$Response(params, context).pipe(
      map((r: StrictHttpResponse<FindPasswordConfirmResponseDto>): FindPasswordConfirmResponseDto => r.body)
    );
  }

  /** Path part for operation `userControllerSendPasswordResetEmail()` */
  static readonly UserControllerSendPasswordResetEmailPath = '/api/user/find/password';

  /**
   * 비밀번호 재설정 인증 이메일 발송.
   *
   * 비밀번호 재설정 이메일을 발송합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerSendPasswordResetEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerSendPasswordResetEmail$Response(params: UserControllerSendPasswordResetEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerSendPasswordResetEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 비밀번호 재설정 인증 이메일 발송.
   *
   * 비밀번호 재설정 이메일을 발송합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerSendPasswordResetEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerSendPasswordResetEmail(params: UserControllerSendPasswordResetEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerSendPasswordResetEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerLogin()` */
  static readonly UserControllerLoginPath = '/api/user/signin';

  /**
   * 사용자 로그인.
   *
   * 사용자로 로그인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerLogin$Response(params: UserControllerLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'accessToken'?: string;
'refreshToken'?: string;
}>> {
    return userControllerLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 로그인.
   *
   * 사용자로 로그인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerLogin(params: UserControllerLogin$Params, context?: HttpContext): Observable<{
'accessToken'?: string;
'refreshToken'?: string;
}> {
    return this.userControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'accessToken'?: string;
'refreshToken'?: string;
}>): {
'accessToken'?: string;
'refreshToken'?: string;
} => r.body)
    );
  }

  /** Path part for operation `userControllerCreate()` */
  static readonly UserControllerCreatePath = '/api/user/signup';

  /**
   * 이메일 회원가입.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerCreate$Response(params: UserControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return userControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 이메일 회원가입.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerCreate(params: UserControllerCreate$Params, context?: HttpContext): Observable<UserDto> {
    return this.userControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `userControllerConnectAuthByEmail()` */
  static readonly UserControllerConnectAuthByEmailPath = '/api/user/connect-auth-by-email';

  /**
   * 이메일 회원가입 시 기존 SNS 계정과 연결.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerConnectAuthByEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerConnectAuthByEmail$Response(params: UserControllerConnectAuthByEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerConnectAuthByEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 이메일 회원가입 시 기존 SNS 계정과 연결.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerConnectAuthByEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerConnectAuthByEmail(params: UserControllerConnectAuthByEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerConnectAuthByEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerConnectAuthBySns()` */
  static readonly UserControllerConnectAuthBySnsPath = '/api/user/connect-auth-by-SNS';

  /**
   * 소셜 회원가입 시 기존 계정과 연결.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerConnectAuthBySns()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerConnectAuthBySns$Response(params: UserControllerConnectAuthBySns$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerConnectAuthBySns(this.http, this.rootUrl, params, context);
  }

  /**
   * 소셜 회원가입 시 기존 계정과 연결.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerConnectAuthBySns$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerConnectAuthBySns(params: UserControllerConnectAuthBySns$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerConnectAuthBySns$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerRefresh()` */
  static readonly UserControllerRefreshPath = '/api/user/refresh';

  /**
   * 엑세스 토큰 갱신.
   *
   * 엑세스 토큰을 갱신합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerRefresh$Response(params?: UserControllerRefresh$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'accessToken'?: string;
}>> {
    return userControllerRefresh(this.http, this.rootUrl, params, context);
  }

  /**
   * 엑세스 토큰 갱신.
   *
   * 엑세스 토큰을 갱신합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerRefresh(params?: UserControllerRefresh$Params, context?: HttpContext): Observable<{
'accessToken'?: string;
}> {
    return this.userControllerRefresh$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'accessToken'?: string;
}>): {
'accessToken'?: string;
} => r.body)
    );
  }

  /** Path part for operation `userControllerLogout()` */
  static readonly UserControllerLogoutPath = '/api/user/signout';

  /**
   * 로그아웃.
   *
   * 로그아웃합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerLogout$Response(params?: UserControllerLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * 로그아웃.
   *
   * 로그아웃합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerLogout(params?: UserControllerLogout$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerSendCode()` */
  static readonly UserControllerSendCodePath = '/api/user/code';

  /**
   * 연락처 인증 코드 발송.
   *
   * 연락처로 인증을 요청합니다. 이름과 함께 요청하는 경우, 계정과 이름이 일치하는지 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerSendCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerSendCode$Response(params: UserControllerSendCode$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'token'?: string;
}>> {
    return userControllerSendCode(this.http, this.rootUrl, params, context);
  }

  /**
   * 연락처 인증 코드 발송.
   *
   * 연락처로 인증을 요청합니다. 이름과 함께 요청하는 경우, 계정과 이름이 일치하는지 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerSendCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerSendCode(params: UserControllerSendCode$Params, context?: HttpContext): Observable<{
'token'?: string;
}> {
    return this.userControllerSendCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'token'?: string;
}>): {
'token'?: string;
} => r.body)
    );
  }

  /** Path part for operation `userControllerVerifyCode()` */
  static readonly UserControllerVerifyCodePath = '/api/user/code/verify';

  /**
   * 연락처 인증 코드 확인.
   *
   * 연락처로 전송된 인증 코드를 확인합니다
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerVerifyCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerVerifyCode$Response(params: UserControllerVerifyCode$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerVerifyCode(this.http, this.rootUrl, params, context);
  }

  /**
   * 연락처 인증 코드 확인.
   *
   * 연락처로 전송된 인증 코드를 확인합니다
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerVerifyCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerVerifyCode(params: UserControllerVerifyCode$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerVerifyCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerNoAuthUpdatePassword()` */
  static readonly UserControllerNoAuthUpdatePasswordPath = '/api/user/no-auth/password';

  /**
   * 비밀번호 변경(로그인X).
   *
   * 비밀번호를 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerNoAuthUpdatePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerNoAuthUpdatePassword$Response(params: UserControllerNoAuthUpdatePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerNoAuthUpdatePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * 비밀번호 변경(로그인X).
   *
   * 비밀번호를 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerNoAuthUpdatePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerNoAuthUpdatePassword(params: UserControllerNoAuthUpdatePassword$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerNoAuthUpdatePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerCheckPassword()` */
  static readonly UserControllerCheckPasswordPath = '/api/user/check/password';

  /**
   * 현재 비밀번호 확인.
   *
   * 현재 비밀번호가 일치하는지 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerCheckPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerCheckPassword$Response(params: UserControllerCheckPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerCheckPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * 현재 비밀번호 확인.
   *
   * 현재 비밀번호가 일치하는지 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerCheckPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerCheckPassword(params: UserControllerCheckPassword$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerCheckPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerUpdatePasswordOnLogin()` */
  static readonly UserControllerUpdatePasswordOnLoginPath = '/api/user/password';

  /**
   * 내 이메일 계정 비밀번호 변경 (로그인O).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdatePasswordOnLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdatePasswordOnLogin$Response(params: UserControllerUpdatePasswordOnLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerUpdatePasswordOnLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 이메일 계정 비밀번호 변경 (로그인O).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdatePasswordOnLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdatePasswordOnLogin(params: UserControllerUpdatePasswordOnLogin$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerUpdatePasswordOnLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerUpdateContact()` */
  static readonly UserControllerUpdateContactPath = '/api/user/contact';

  /**
   * 연락처 변경.
   *
   * 연락처를 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdateContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateContact$Response(params: UserControllerUpdateContact$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerUpdateContact(this.http, this.rootUrl, params, context);
  }

  /**
   * 연락처 변경.
   *
   * 연락처를 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdateContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateContact(params: UserControllerUpdateContact$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerUpdateContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerDeleteMany()` */
  static readonly UserControllerDeleteManyPath = '/api/user';

  /**
   * 사용자 다중 삭제.
   *
   * 여러 사용자를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerDeleteMany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerDeleteMany$Response(params: UserControllerDeleteMany$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'count'?: number;
}>> {
    return userControllerDeleteMany(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 다중 삭제.
   *
   * 여러 사용자를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerDeleteMany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerDeleteMany(params: UserControllerDeleteMany$Params, context?: HttpContext): Observable<{
'count'?: number;
}> {
    return this.userControllerDeleteMany$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>): {
'count'?: number;
} => r.body)
    );
  }

  /** Path part for operation `userControllerUpdate()` */
  static readonly UserControllerUpdatePath = '/api/user';

  /**
   * 사용자 정보 수정.
   *
   * 사용자 정보를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdate$Response(params: UserControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return userControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 정보 수정.
   *
   * 사용자 정보를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdate(params: UserControllerUpdate$Params, context?: HttpContext): Observable<UserDto> {
    return this.userControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `userControllerAdminUpdateEmail()` */
  static readonly UserControllerAdminUpdateEmailPath = '/api/user/admin/email';

  /**
   * 관리자에 의한 사용자 email 수정.
   *
   * 관리자가 사용자 이메일을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerAdminUpdateEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerAdminUpdateEmail$Response(params: UserControllerAdminUpdateEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerAdminUpdateEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자에 의한 사용자 email 수정.
   *
   * 관리자가 사용자 이메일을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerAdminUpdateEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerAdminUpdateEmail(params: UserControllerAdminUpdateEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerAdminUpdateEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerUpdateEmail()` */
  static readonly UserControllerUpdateEmailPath = '/api/user/email';

  /**
   * email 수정.
   *
   * 이메일을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdateEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateEmail$Response(params: UserControllerUpdateEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerUpdateEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * email 수정.
   *
   * 이메일을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdateEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateEmail(params: UserControllerUpdateEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerUpdateEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerWithdraw()` */
  static readonly UserControllerWithdrawPath = '/api/user/withdraw';

  /**
   * 회원 탈퇴.
   *
   * 회원을 탈퇴
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerWithdraw()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerWithdraw$Response(params: UserControllerWithdraw$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerWithdraw(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 탈퇴.
   *
   * 회원을 탈퇴
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerWithdraw$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerWithdraw(params: UserControllerWithdraw$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerWithdraw$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerBlock()` */
  static readonly UserControllerBlockPath = '/api/user/{id}/block';

  /**
   * 사용자 차단.
   *
   * 사용자를 차단합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerBlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerBlock$Response(params: UserControllerBlock$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerBlock(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 차단.
   *
   * 사용자를 차단합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerBlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerBlock(params: UserControllerBlock$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerUnblock()` */
  static readonly UserControllerUnblockPath = '/api/user/{id}/unblock';

  /**
   * 사용자 차단 해제.
   *
   * 사용자의 차단을 해제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUnblock()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerUnblock$Response(params: UserControllerUnblock$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerUnblock(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 차단 해제.
   *
   * 사용자의 차단을 해제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUnblock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerUnblock(params: UserControllerUnblock$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerUnblock$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerUpdateNotificationEnabled()` */
  static readonly UserControllerUpdateNotificationEnabledPath = '/api/user/toggle-notification';

  /**
   * 푸시 알림 변경.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdateNotificationEnabled()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerUpdateNotificationEnabled$Response(params?: UserControllerUpdateNotificationEnabled$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userControllerUpdateNotificationEnabled(this.http, this.rootUrl, params, context);
  }

  /**
   * 푸시 알림 변경.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdateNotificationEnabled$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerUpdateNotificationEnabled(params?: UserControllerUpdateNotificationEnabled$Params, context?: HttpContext): Observable<boolean> {
    return this.userControllerUpdateNotificationEnabled$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `parentControllerFindParent()` */
  static readonly ParentControllerFindParentPath = '/api/user/parent/search';

  /**
   * 보호자 조회.
   *
   * 보호자를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentControllerFindParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentControllerFindParent$Response(params?: ParentControllerFindParent$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
    return parentControllerFindParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 조회.
   *
   * 보호자를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentControllerFindParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentControllerFindParent(params?: ParentControllerFindParent$Params, context?: HttpContext): Observable<Array<UserDto>> {
    return this.parentControllerFindParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

}
