export const FamilyType = {
  MARRIED: '기혼',
  COHABITATION: '미혼(사실혼)',
  REMARRIED: '재혼',
  SINGLE_PARENT: '한부모가정',
  SINGLE: '독신',
  MULTICULTURAL: '다문화',
  OTHER: '기타',
} as const;

/**
 * 한부모 가정인 경우 가족형태 기타 입력값
 */
export const FamilyOtherType = {
  SEPARATION: '별거',
  DIVORCE: '이혼',
  DEATH: '사별',
} as const;
