import { registerLocaleData } from '@angular/common';
import localeKo from '@angular/common/locales/ko';
import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { provideRouter, withViewTransitions } from '@angular/router';

import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { ApiModule } from '@malirang/api-client';
import { provideComponentConfig } from '@malirang/front-share';
import { provideMarkdown } from 'ngx-markdown';
import { provideToastr } from 'ngx-toastr';
import { environment } from '../env/env';
import { appRoutes } from './app.routes';
import { HttpInterceptor } from './libs/http-interceptor';

registerLocaleData(localeKo);

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: false,
        filter: (req) => false,
      }),
    ),
    provideRouter(appRoutes, withViewTransitions()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      ApiModule.forRoot({
        rootUrl: environment.baseUrl,
      }),
    ),
    provideHttpClient(withFetch(), withInterceptors([HttpInterceptor])),
    provideToastr(),
    provideAnimations(),
    { provide: LOCALE_ID, useValue: 'ko' },
    provideMarkdown(),
    provideComponentConfig({}),
  ],
};
