/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { scheduleControllerCopyV1 } from '../fn/schedule/schedule-controller-copy-v-1';
import { ScheduleControllerCopyV1$Params } from '../fn/schedule/schedule-controller-copy-v-1';
import { scheduleControllerCreateV1 } from '../fn/schedule/schedule-controller-create-v-1';
import { ScheduleControllerCreateV1$Params } from '../fn/schedule/schedule-controller-create-v-1';
import { scheduleControllerDeleteRepeatV1 } from '../fn/schedule/schedule-controller-delete-repeat-v-1';
import { ScheduleControllerDeleteRepeatV1$Params } from '../fn/schedule/schedule-controller-delete-repeat-v-1';
import { scheduleControllerDeleteV1 } from '../fn/schedule/schedule-controller-delete-v-1';
import { ScheduleControllerDeleteV1$Params } from '../fn/schedule/schedule-controller-delete-v-1';
import { scheduleControllerFindByIdV1 } from '../fn/schedule/schedule-controller-find-by-id-v-1';
import { ScheduleControllerFindByIdV1$Params } from '../fn/schedule/schedule-controller-find-by-id-v-1';
import { scheduleControllerFindV1 } from '../fn/schedule/schedule-controller-find-v-1';
import { ScheduleControllerFindV1$Params } from '../fn/schedule/schedule-controller-find-v-1';
import { scheduleControllerSearchByParentV1 } from '../fn/schedule/schedule-controller-search-by-parent-v-1';
import { ScheduleControllerSearchByParentV1$Params } from '../fn/schedule/schedule-controller-search-by-parent-v-1';
import { scheduleControllerUpdateDateV1 } from '../fn/schedule/schedule-controller-update-date-v-1';
import { ScheduleControllerUpdateDateV1$Params } from '../fn/schedule/schedule-controller-update-date-v-1';
import { scheduleControllerUpdateRepeatV1 } from '../fn/schedule/schedule-controller-update-repeat-v-1';
import { ScheduleControllerUpdateRepeatV1$Params } from '../fn/schedule/schedule-controller-update-repeat-v-1';
import { scheduleControllerUpdateV1 } from '../fn/schedule/schedule-controller-update-v-1';
import { ScheduleControllerUpdateV1$Params } from '../fn/schedule/schedule-controller-update-v-1';
import { ScheduleDto } from '../models/schedule-dto';
import { ScheduleOffsetPaginationDto } from '../models/schedule-offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class ScheduleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `scheduleControllerFindV1()` */
  static readonly ScheduleControllerFindV1Path = '/api/v1/schedule';

  /**
   * 일정 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleControllerFindV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerFindV1$Response(params: ScheduleControllerFindV1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ScheduleDto>>> {
    return scheduleControllerFindV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 일정 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scheduleControllerFindV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerFindV1(params: ScheduleControllerFindV1$Params, context?: HttpContext): Observable<Array<ScheduleDto>> {
    return this.scheduleControllerFindV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ScheduleDto>>): Array<ScheduleDto> => r.body)
    );
  }

  /** Path part for operation `scheduleControllerCreateV1()` */
  static readonly ScheduleControllerCreateV1Path = '/api/v1/schedule';

  /**
   * 발달서비스 일정 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleControllerCreateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scheduleControllerCreateV1$Response(params: ScheduleControllerCreateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return scheduleControllerCreateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scheduleControllerCreateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scheduleControllerCreateV1(params: ScheduleControllerCreateV1$Params, context?: HttpContext): Observable<boolean> {
    return this.scheduleControllerCreateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `scheduleControllerSearchByParentV1()` */
  static readonly ScheduleControllerSearchByParentV1Path = '/api/v1/schedule/search-by-parent';

  /**
   * 보호자가 내 아동의 일정을 조회합니다.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleControllerSearchByParentV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerSearchByParentV1$Response(params: ScheduleControllerSearchByParentV1$Params, context?: HttpContext): Observable<StrictHttpResponse<ScheduleOffsetPaginationDto>> {
    return scheduleControllerSearchByParentV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 일정을 조회합니다.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scheduleControllerSearchByParentV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerSearchByParentV1(params: ScheduleControllerSearchByParentV1$Params, context?: HttpContext): Observable<ScheduleOffsetPaginationDto> {
    return this.scheduleControllerSearchByParentV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScheduleOffsetPaginationDto>): ScheduleOffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `scheduleControllerFindByIdV1()` */
  static readonly ScheduleControllerFindByIdV1Path = '/api/v1/schedule/{id}';

  /**
   * 일정 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleControllerFindByIdV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerFindByIdV1$Response(params: ScheduleControllerFindByIdV1$Params, context?: HttpContext): Observable<StrictHttpResponse<ScheduleDto>> {
    return scheduleControllerFindByIdV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 일정 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scheduleControllerFindByIdV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerFindByIdV1(params: ScheduleControllerFindByIdV1$Params, context?: HttpContext): Observable<ScheduleDto> {
    return this.scheduleControllerFindByIdV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScheduleDto>): ScheduleDto => r.body)
    );
  }

  /** Path part for operation `scheduleControllerDeleteV1()` */
  static readonly ScheduleControllerDeleteV1Path = '/api/v1/schedule/{id}';

  /**
   * 발달서비스 일정 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleControllerDeleteV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerDeleteV1$Response(params: ScheduleControllerDeleteV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return scheduleControllerDeleteV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scheduleControllerDeleteV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerDeleteV1(params: ScheduleControllerDeleteV1$Params, context?: HttpContext): Observable<boolean> {
    return this.scheduleControllerDeleteV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `scheduleControllerUpdateV1()` */
  static readonly ScheduleControllerUpdateV1Path = '/api/v1/schedule/{id}';

  /**
   * 발달서비스 일정 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleControllerUpdateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scheduleControllerUpdateV1$Response(params: ScheduleControllerUpdateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return scheduleControllerUpdateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scheduleControllerUpdateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scheduleControllerUpdateV1(params: ScheduleControllerUpdateV1$Params, context?: HttpContext): Observable<boolean> {
    return this.scheduleControllerUpdateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `scheduleControllerUpdateDateV1()` */
  static readonly ScheduleControllerUpdateDateV1Path = '/api/v1/schedule/date';

  /**
   * 발달서비스 일정 날짜 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleControllerUpdateDateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scheduleControllerUpdateDateV1$Response(params: ScheduleControllerUpdateDateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return scheduleControllerUpdateDateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 날짜 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scheduleControllerUpdateDateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scheduleControllerUpdateDateV1(params: ScheduleControllerUpdateDateV1$Params, context?: HttpContext): Observable<boolean> {
    return this.scheduleControllerUpdateDateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `scheduleControllerCopyV1()` */
  static readonly ScheduleControllerCopyV1Path = '/api/v1/schedule/copy';

  /**
   * 발달서비스 일정 복사.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleControllerCopyV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerCopyV1$Response(params: ScheduleControllerCopyV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return scheduleControllerCopyV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 복사.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scheduleControllerCopyV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerCopyV1(params: ScheduleControllerCopyV1$Params, context?: HttpContext): Observable<boolean> {
    return this.scheduleControllerCopyV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `scheduleControllerUpdateRepeatV1()` */
  static readonly ScheduleControllerUpdateRepeatV1Path = '/api/v1/schedule/{id}/repeat';

  /**
   * 발달서비스 반복 일정 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleControllerUpdateRepeatV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scheduleControllerUpdateRepeatV1$Response(params: ScheduleControllerUpdateRepeatV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return scheduleControllerUpdateRepeatV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 반복 일정 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scheduleControllerUpdateRepeatV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scheduleControllerUpdateRepeatV1(params: ScheduleControllerUpdateRepeatV1$Params, context?: HttpContext): Observable<boolean> {
    return this.scheduleControllerUpdateRepeatV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `scheduleControllerDeleteRepeatV1()` */
  static readonly ScheduleControllerDeleteRepeatV1Path = '/api/v1/schedule/repeat/{id}';

  /**
   * 발달서비스 반복 일정 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleControllerDeleteRepeatV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerDeleteRepeatV1$Response(params: ScheduleControllerDeleteRepeatV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return scheduleControllerDeleteRepeatV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 반복 일정 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scheduleControllerDeleteRepeatV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleControllerDeleteRepeatV1(params: ScheduleControllerDeleteRepeatV1$Params, context?: HttpContext): Observable<boolean> {
    return this.scheduleControllerDeleteRepeatV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
