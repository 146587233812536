/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CompanyInfoDto } from '../models/company-info-dto';
import { infoControllerGetCompanyInfoV1 } from '../fn/info/info-controller-get-company-info-v-1';
import { InfoControllerGetCompanyInfoV1$Params } from '../fn/info/info-controller-get-company-info-v-1';
import { infoControllerGetTermV1 } from '../fn/info/info-controller-get-term-v-1';
import { InfoControllerGetTermV1$Params } from '../fn/info/info-controller-get-term-v-1';
import { infoControllerUpdateCompanyInfoV1 } from '../fn/info/info-controller-update-company-info-v-1';
import { InfoControllerUpdateCompanyInfoV1$Params } from '../fn/info/info-controller-update-company-info-v-1';
import { infoControllerUpdateTermV1 } from '../fn/info/info-controller-update-term-v-1';
import { InfoControllerUpdateTermV1$Params } from '../fn/info/info-controller-update-term-v-1';
import { TermDto } from '../models/term-dto';

@Injectable({ providedIn: 'root' })
export class InfoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `infoControllerGetCompanyInfoV1()` */
  static readonly InfoControllerGetCompanyInfoV1Path = '/api/v1/info/company-info';

  /**
   * 회사 정보 조회.
   *
   * 회사 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoControllerGetCompanyInfoV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  infoControllerGetCompanyInfoV1$Response(params?: InfoControllerGetCompanyInfoV1$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyInfoDto>> {
    return infoControllerGetCompanyInfoV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 회사 정보 조회.
   *
   * 회사 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoControllerGetCompanyInfoV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  infoControllerGetCompanyInfoV1(params?: InfoControllerGetCompanyInfoV1$Params, context?: HttpContext): Observable<CompanyInfoDto> {
    return this.infoControllerGetCompanyInfoV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyInfoDto>): CompanyInfoDto => r.body)
    );
  }

  /** Path part for operation `infoControllerGetTermV1()` */
  static readonly InfoControllerGetTermV1Path = '/api/v1/info/term';

  /**
   * 약관 조회.
   *
   * 약관을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoControllerGetTermV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  infoControllerGetTermV1$Response(params: InfoControllerGetTermV1$Params, context?: HttpContext): Observable<StrictHttpResponse<TermDto>> {
    return infoControllerGetTermV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 약관 조회.
   *
   * 약관을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoControllerGetTermV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  infoControllerGetTermV1(params: InfoControllerGetTermV1$Params, context?: HttpContext): Observable<TermDto> {
    return this.infoControllerGetTermV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<TermDto>): TermDto => r.body)
    );
  }

  /** Path part for operation `infoControllerUpdateTermV1()` */
  static readonly InfoControllerUpdateTermV1Path = '/api/v1/info/term';

  /**
   * 약관 수정.
   *
   * 약관을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoControllerUpdateTermV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  infoControllerUpdateTermV1$Response(params: InfoControllerUpdateTermV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return infoControllerUpdateTermV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 약관 수정.
   *
   * 약관을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoControllerUpdateTermV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  infoControllerUpdateTermV1(params: InfoControllerUpdateTermV1$Params, context?: HttpContext): Observable<boolean> {
    return this.infoControllerUpdateTermV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `infoControllerUpdateCompanyInfoV1()` */
  static readonly InfoControllerUpdateCompanyInfoV1Path = '/api/v1/info/footer';

  /**
   * 회사 정보 수정.
   *
   * 회사 정보를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoControllerUpdateCompanyInfoV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  infoControllerUpdateCompanyInfoV1$Response(params: InfoControllerUpdateCompanyInfoV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return infoControllerUpdateCompanyInfoV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 회사 정보 수정.
   *
   * 회사 정보를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoControllerUpdateCompanyInfoV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  infoControllerUpdateCompanyInfoV1(params: InfoControllerUpdateCompanyInfoV1$Params, context?: HttpContext): Observable<boolean> {
    return this.infoControllerUpdateCompanyInfoV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
