/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  computed,
  inject,
  Injector,
  input,
  model,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
} from "@angular/forms";
import { BaseConfig } from "../common/config/config.adapter";
import { Size } from "../common/types";

@Component({
  selector: "app-toggle",
  templateUrl: "./toggle.component.html",
  styleUrls: ["./toggle.component.scss"],
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: Toggle,
      multi: true,
    },
  ],
})
export class Toggle
  extends BaseConfig
  implements AfterViewInit, ControlValueAccessor
{
  readonly injector = inject(Injector);

  disabled = input<boolean>(false);

  value = model(false);
  control?: FormControl;
  size = input<Size>();

  currentSize = computed(
    () => this.size() || this.globalConfig()?.toggle?.size || "md",
  );

  ngAfterViewInit(): void {
    const ngControl: NgControl | null = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control = ngControl.control as FormControl;
    }
  }

  onChange = (ev?: any) => {};

  onTouched = () => {};

  writeValue(obj: boolean): void {
    this.value.set(obj);
  }

  handleChange(ev: any) {
    ev.stopPropagation();
    this.value.set(ev.target.checked);
    this.onChange(this.value());
    this.onTouched();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched = () => {};

  setDisabledState?(disabled: boolean): void {}
}
