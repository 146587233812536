@if (option(); as option) {
  <div [attr.data-theme]="theme()" class="option">
    @if (option.icon; as icon) {
      <app-icon [attr.data-theme]="theme()" class="option-text" [name]="icon" />
    }
    <div
      [attr.data-color]="color()"
      [attr.data-theme]="theme()"
      [class.active]="active()"
      class="option-text text-base transition-colors duration-200 ease-in-out"
    >
      {{ option.label }}
    </div>
    @if (option.count; as count) {
      <div
        class="flex items-center justify-center px-2 py-0.5 pb-1 text-sm rounded-md min-w-max bg-primary-100"
      >
        {{ count | number }}
      </div>
    }
    @if (option.renderItem) {
      <ng-template option />
    }
  </div>
}
