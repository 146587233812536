import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Icon, ThemeStore } from '@malirang/front-share';

@Component({
  selector: 'app-side-menu-item',
  standalone: true,
  imports: [CommonModule, Icon, RouterModule],
  templateUrl: './side-menu-item.component.html',
  styleUrls: ['./side-menu-item.component.scss'],
})
export class SideMenuItem {
  readonly themeStore = inject(ThemeStore);
  theme = this.themeStore.theme;

  active = input<boolean>(false);
  icon = input.required<string>();
}
