/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { childMemoControllerAddSharedUser } from '../fn/child-memo/child-memo-controller-add-shared-user';
import { ChildMemoControllerAddSharedUser$Params } from '../fn/child-memo/child-memo-controller-add-shared-user';
import { childMemoControllerCreateGuardianCounselingMemo } from '../fn/child-memo/child-memo-controller-create-guardian-counseling-memo';
import { ChildMemoControllerCreateGuardianCounselingMemo$Params } from '../fn/child-memo/child-memo-controller-create-guardian-counseling-memo';
import { childMemoControllerCreateMemo } from '../fn/child-memo/child-memo-controller-create-memo';
import { ChildMemoControllerCreateMemo$Params } from '../fn/child-memo/child-memo-controller-create-memo';
import { childMemoControllerCreateMemoByParent } from '../fn/child-memo/child-memo-controller-create-memo-by-parent';
import { ChildMemoControllerCreateMemoByParent$Params } from '../fn/child-memo/child-memo-controller-create-memo-by-parent';
import { childMemoControllerDeleteMemo } from '../fn/child-memo/child-memo-controller-delete-memo';
import { ChildMemoControllerDeleteMemo$Params } from '../fn/child-memo/child-memo-controller-delete-memo';
import { childMemoControllerDeleteMemoByParent } from '../fn/child-memo/child-memo-controller-delete-memo-by-parent';
import { ChildMemoControllerDeleteMemoByParent$Params } from '../fn/child-memo/child-memo-controller-delete-memo-by-parent';
import { childMemoControllerFindGuardianCounselingMemo } from '../fn/child-memo/child-memo-controller-find-guardian-counseling-memo';
import { ChildMemoControllerFindGuardianCounselingMemo$Params } from '../fn/child-memo/child-memo-controller-find-guardian-counseling-memo';
import { childMemoControllerFindMemoByCenter } from '../fn/child-memo/child-memo-controller-find-memo-by-center';
import { ChildMemoControllerFindMemoByCenter$Params } from '../fn/child-memo/child-memo-controller-find-memo-by-center';
import { childMemoControllerFindOneMemoByParent } from '../fn/child-memo/child-memo-controller-find-one-memo-by-parent';
import { ChildMemoControllerFindOneMemoByParent$Params } from '../fn/child-memo/child-memo-controller-find-one-memo-by-parent';
import { childMemoControllerSearchMemoByParent } from '../fn/child-memo/child-memo-controller-search-memo-by-parent';
import { ChildMemoControllerSearchMemoByParent$Params } from '../fn/child-memo/child-memo-controller-search-memo-by-parent';
import { childMemoControllerShareMemoByParent } from '../fn/child-memo/child-memo-controller-share-memo-by-parent';
import { ChildMemoControllerShareMemoByParent$Params } from '../fn/child-memo/child-memo-controller-share-memo-by-parent';
import { childMemoControllerUpdateGuardianCounselingMemo } from '../fn/child-memo/child-memo-controller-update-guardian-counseling-memo';
import { ChildMemoControllerUpdateGuardianCounselingMemo$Params } from '../fn/child-memo/child-memo-controller-update-guardian-counseling-memo';
import { childMemoControllerUpdateMemo } from '../fn/child-memo/child-memo-controller-update-memo';
import { ChildMemoControllerUpdateMemo$Params } from '../fn/child-memo/child-memo-controller-update-memo';
import { childMemoControllerUpdateMemoByParent } from '../fn/child-memo/child-memo-controller-update-memo-by-parent';
import { ChildMemoControllerUpdateMemoByParent$Params } from '../fn/child-memo/child-memo-controller-update-memo-by-parent';
import { ChildMemoDto } from '../models/child-memo-dto';
import { SearchChildMemoResponseDto } from '../models/search-child-memo-response-dto';

@Injectable({ providedIn: 'root' })
export class ChildMemoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `childMemoControllerSearchMemoByParent()` */
  static readonly ChildMemoControllerSearchMemoByParentPath = '/api/child-memo/search-by-parent';

  /**
   * 보호자가 내 아동 1명의 배경정보 정보 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerSearchMemoByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerSearchMemoByParent$Response(params: ChildMemoControllerSearchMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchChildMemoResponseDto>> {
    return childMemoControllerSearchMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 1명의 배경정보 정보 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerSearchMemoByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerSearchMemoByParent(params: ChildMemoControllerSearchMemoByParent$Params, context?: HttpContext): Observable<SearchChildMemoResponseDto> {
    return this.childMemoControllerSearchMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchChildMemoResponseDto>): SearchChildMemoResponseDto => r.body)
    );
  }

  /** Path part for operation `childMemoControllerFindOneMemoByParent()` */
  static readonly ChildMemoControllerFindOneMemoByParentPath = '/api/child-memo/get-by-parent/{id}';

  /**
   * 보호자가 내 아동 메모 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerFindOneMemoByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerFindOneMemoByParent$Response(params: ChildMemoControllerFindOneMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return childMemoControllerFindOneMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 메모 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerFindOneMemoByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerFindOneMemoByParent(params: ChildMemoControllerFindOneMemoByParent$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.childMemoControllerFindOneMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `childMemoControllerFindMemoByCenter()` */
  static readonly ChildMemoControllerFindMemoByCenterPath = '/api/child-memo/{id}/memo';

  /**
   * 아동 메모 전체 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerFindMemoByCenter()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerFindMemoByCenter$Response(params: ChildMemoControllerFindMemoByCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChildMemoDto>>> {
    return childMemoControllerFindMemoByCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 전체 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerFindMemoByCenter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerFindMemoByCenter(params: ChildMemoControllerFindMemoByCenter$Params, context?: HttpContext): Observable<Array<ChildMemoDto>> {
    return this.childMemoControllerFindMemoByCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChildMemoDto>>): Array<ChildMemoDto> => r.body)
    );
  }

  /** Path part for operation `childMemoControllerFindGuardianCounselingMemo()` */
  static readonly ChildMemoControllerFindGuardianCounselingMemoPath = '/api/child-memo/{id}/memo/guardian-counseling';

  /**
   * 아동 메모 전체 조회 (보호자 상담).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerFindGuardianCounselingMemo()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerFindGuardianCounselingMemo$Response(params: ChildMemoControllerFindGuardianCounselingMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChildMemoDto>>> {
    return childMemoControllerFindGuardianCounselingMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 전체 조회 (보호자 상담).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerFindGuardianCounselingMemo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerFindGuardianCounselingMemo(params: ChildMemoControllerFindGuardianCounselingMemo$Params, context?: HttpContext): Observable<Array<ChildMemoDto>> {
    return this.childMemoControllerFindGuardianCounselingMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChildMemoDto>>): Array<ChildMemoDto> => r.body)
    );
  }

  /** Path part for operation `childMemoControllerCreateMemo()` */
  static readonly ChildMemoControllerCreateMemoPath = '/api/child-memo/memo';

  /**
   * 아동 메모 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerCreateMemo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerCreateMemo$Response(params: ChildMemoControllerCreateMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return childMemoControllerCreateMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerCreateMemo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerCreateMemo(params: ChildMemoControllerCreateMemo$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.childMemoControllerCreateMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `childMemoControllerCreateMemoByParent()` */
  static readonly ChildMemoControllerCreateMemoByParentPath = '/api/child-memo/create-by-parent';

  /**
   * 보호자가 아동 메모 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerCreateMemoByParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerCreateMemoByParent$Response(params: ChildMemoControllerCreateMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return childMemoControllerCreateMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 아동 메모 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerCreateMemoByParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerCreateMemoByParent(params: ChildMemoControllerCreateMemoByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.childMemoControllerCreateMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `childMemoControllerCreateGuardianCounselingMemo()` */
  static readonly ChildMemoControllerCreateGuardianCounselingMemoPath = '/api/child-memo/memo/guardian-counseling';

  /**
   * 아동 메모 등록 (보호자 상담).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerCreateGuardianCounselingMemo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerCreateGuardianCounselingMemo$Response(params: ChildMemoControllerCreateGuardianCounselingMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return childMemoControllerCreateGuardianCounselingMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 등록 (보호자 상담).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerCreateGuardianCounselingMemo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerCreateGuardianCounselingMemo(params: ChildMemoControllerCreateGuardianCounselingMemo$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.childMemoControllerCreateGuardianCounselingMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `childMemoControllerUpdateGuardianCounselingMemo()` */
  static readonly ChildMemoControllerUpdateGuardianCounselingMemoPath = '/api/child-memo/memo/guardian-counseling/{memoId}';

  /**
   * 아동 메모 수정 (보호자 상담).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerUpdateGuardianCounselingMemo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerUpdateGuardianCounselingMemo$Response(params: ChildMemoControllerUpdateGuardianCounselingMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return childMemoControllerUpdateGuardianCounselingMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 수정 (보호자 상담).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerUpdateGuardianCounselingMemo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerUpdateGuardianCounselingMemo(params: ChildMemoControllerUpdateGuardianCounselingMemo$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.childMemoControllerUpdateGuardianCounselingMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `childMemoControllerUpdateMemoByParent()` */
  static readonly ChildMemoControllerUpdateMemoByParentPath = '/api/child-memo/update-by-parent/{memoId}';

  /**
   * 보호자가 내 아동 메모 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerUpdateMemoByParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerUpdateMemoByParent$Response(params: ChildMemoControllerUpdateMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return childMemoControllerUpdateMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 메모 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerUpdateMemoByParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerUpdateMemoByParent(params: ChildMemoControllerUpdateMemoByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.childMemoControllerUpdateMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `childMemoControllerShareMemoByParent()` */
  static readonly ChildMemoControllerShareMemoByParentPath = '/api/child-memo/share-by-parent';

  /**
   * 보호자가 내 아동 메모를 센터와 공유.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerShareMemoByParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerShareMemoByParent$Response(params: ChildMemoControllerShareMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return childMemoControllerShareMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 메모를 센터와 공유.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerShareMemoByParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerShareMemoByParent(params: ChildMemoControllerShareMemoByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.childMemoControllerShareMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `childMemoControllerAddSharedUser()` */
  static readonly ChildMemoControllerAddSharedUserPath = '/api/child-memo/memo/shared/{memoId}';

  /**
   * 공유 여부 토글.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerAddSharedUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerAddSharedUser$Response(params: ChildMemoControllerAddSharedUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return childMemoControllerAddSharedUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 공유 여부 토글.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerAddSharedUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerAddSharedUser(params: ChildMemoControllerAddSharedUser$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.childMemoControllerAddSharedUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `childMemoControllerDeleteMemo()` */
  static readonly ChildMemoControllerDeleteMemoPath = '/api/child-memo/memo/{memoId}';

  /**
   * 아동 메모 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerDeleteMemo()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerDeleteMemo$Response(params: ChildMemoControllerDeleteMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return childMemoControllerDeleteMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerDeleteMemo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerDeleteMemo(params: ChildMemoControllerDeleteMemo$Params, context?: HttpContext): Observable<void> {
    return this.childMemoControllerDeleteMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `childMemoControllerUpdateMemo()` */
  static readonly ChildMemoControllerUpdateMemoPath = '/api/child-memo/memo/{memoId}';

  /**
   * 아동 메모 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerUpdateMemo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerUpdateMemo$Response(params: ChildMemoControllerUpdateMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return childMemoControllerUpdateMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerUpdateMemo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childMemoControllerUpdateMemo(params: ChildMemoControllerUpdateMemo$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.childMemoControllerUpdateMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `childMemoControllerDeleteMemoByParent()` */
  static readonly ChildMemoControllerDeleteMemoByParentPath = '/api/child-memo/delete-by-parent/{memoId}';

  /**
   * 보호자가 내 아동 메모 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childMemoControllerDeleteMemoByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerDeleteMemoByParent$Response(params: ChildMemoControllerDeleteMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return childMemoControllerDeleteMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 메모 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childMemoControllerDeleteMemoByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childMemoControllerDeleteMemoByParent(params: ChildMemoControllerDeleteMemoByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.childMemoControllerDeleteMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
