import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, tap } from 'rxjs';
import { BaseStore } from '../../stores/base.store';

type State = {
  defaultValues: any;
};

@Injectable({ providedIn: 'any' })
export class AutoFormStore extends BaseStore<State> {
  defaultValues = toSignal(
    this.state$.pipe(map((state) => state.defaultValues)),
    {
      initialValue: null,
    },
  );

  constructor() {
    super({ defaultValues: null });
  }

  setDefaultValues(data: any) {
    this.updateState({ defaultValues: data });
  }
}
