@if (event(); as event) {
  <div class="flex flex-col rounded-xl border">
    <div class="flex flex-col gap-3 p-5">
      <div class="flex w-full justify-between">
        <div class="flex gap-2 items-center">
          <p class="text-gray-500 font-semibold text-sm">{{ date() }}</p>
          @if (event.repeat) {
            <app-icon
              name="solar:history-2-broken"
              size="sm"
              class="text-primary"
            />
          }
        </div>
        <app-icon [cdkMenuTriggerFor]="menu" name="mdi:dots-vertical" />
      </div>
      <div class="flex gap-2 items-center">
        <p class="text-gray-800 font-semibold">{{ event.title }}</p>
      </div>
    </div>
    @if (event.metadata?.memo) {
      <app-divider />
      <div class="flex flex-col gap-3 p-5"></div>
    }
  </div>
}

<ng-template #menu>
  <app-menu cdkMenu>
    <app-menu-option cdkMenuItem icon="ic:round-drive-file-rename-outline"
      >수정</app-menu-option
    >
    <app-menu-option cdkMenuItem icon="material-symbols:delete-outline"
      >삭제</app-menu-option
    >
  </app-menu>
</ng-template>
