import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { InputEmail } from '../../../input/email/input-email.component';
import { InputFormFieldAdapter } from '../../adapters/input-form-field.adapter';
import { EmailFormField } from '../../auto-form.type';
import { ErrorDirective } from '../../directives/error.directive';

@Component({
  selector: 'app-email-form-field',
  standalone: true,
  imports: [InputEmail, ErrorDirective],
  templateUrl: './email-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EmailFormFieldComponent,
      multi: true,
    },
  ],
})
export class EmailFormFieldComponent extends InputFormFieldAdapter<
  EmailFormField<any>
> {
  override ngOnInit(): void {
    super.ngOnInit();

    if (this.formControl) {
      this.formControl.addValidators([Validators.email]);
    }
  }
}
