/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminControllerLogout } from '../fn/admin/admin-controller-logout';
import { AdminControllerLogout$Params } from '../fn/admin/admin-controller-logout';
import { adminControllerMe } from '../fn/admin/admin-controller-me';
import { AdminControllerMe$Params } from '../fn/admin/admin-controller-me';
import { adminControllerRefresh } from '../fn/admin/admin-controller-refresh';
import { AdminControllerRefresh$Params } from '../fn/admin/admin-controller-refresh';
import { adminControllerSignIn } from '../fn/admin/admin-controller-sign-in';
import { AdminControllerSignIn$Params } from '../fn/admin/admin-controller-sign-in';
import { AdminDto } from '../models/admin-dto';

@Injectable({ providedIn: 'root' })
export class AdminService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminControllerMe()` */
  static readonly AdminControllerMePath = '/api/admin/me';

  /**
   * 내 정보.
   *
   * 내 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerMe$Response(params?: AdminControllerMe$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminDto>> {
    return adminControllerMe(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 정보.
   *
   * 내 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerMe(params?: AdminControllerMe$Params, context?: HttpContext): Observable<AdminDto> {
    return this.adminControllerMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminDto>): AdminDto => r.body)
    );
  }

  /** Path part for operation `adminControllerSignIn()` */
  static readonly AdminControllerSignInPath = '/api/admin/signin';

  /**
   * 관리자 로그인.
   *
   * 관리자 로그인을 수행합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerSignIn()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerSignIn$Response(params: AdminControllerSignIn$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'accessToken'?: string;
'refreshToken'?: string;
}>> {
    return adminControllerSignIn(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 로그인.
   *
   * 관리자 로그인을 수행합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerSignIn$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerSignIn(params: AdminControllerSignIn$Params, context?: HttpContext): Observable<{
'accessToken'?: string;
'refreshToken'?: string;
}> {
    return this.adminControllerSignIn$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'accessToken'?: string;
'refreshToken'?: string;
}>): {
'accessToken'?: string;
'refreshToken'?: string;
} => r.body)
    );
  }

  /** Path part for operation `adminControllerRefresh()` */
  static readonly AdminControllerRefreshPath = '/api/admin/refresh';

  /**
   * 관리자 토큰 갱신.
   *
   * 관리자 토큰을 갱신합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerRefresh$Response(params?: AdminControllerRefresh$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'accessToken'?: string;
}>> {
    return adminControllerRefresh(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 토큰 갱신.
   *
   * 관리자 토큰을 갱신합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerRefresh(params?: AdminControllerRefresh$Params, context?: HttpContext): Observable<{
'accessToken'?: string;
}> {
    return this.adminControllerRefresh$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'accessToken'?: string;
}>): {
'accessToken'?: string;
} => r.body)
    );
  }

  /** Path part for operation `adminControllerLogout()` */
  static readonly AdminControllerLogoutPath = '/api/admin/signout';

  /**
   * 로그아웃.
   *
   * 로그아웃합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerLogout$Response(params?: AdminControllerLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return adminControllerLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * 로그아웃.
   *
   * 로그아웃합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerLogout(params?: AdminControllerLogout$Params, context?: HttpContext): Observable<boolean> {
    return this.adminControllerLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
