@if (dialogData) {
  <main
    [attr.data-theme]="theme()"
    #modalContainer
    id="modal-container"
    class="flex flex-col items-center theme-wrapper overflow-y-auto h-screen md:h-fit max-h-screen md:rounded-xl w-screen md:w-[400px] relative"
    (scroll)="onScroll($event)"
  >
    <header
      [attr.data-theme]="theme()"
      class="sticky top-0 z-10 flex flex-col items-center w-full gap-3 p-4 mb-6 theme-wrapper border-b theme-border"
    >
      <app-icon
        [name]="iconName()"
        [color]="iconColor()"
        [ngClass]="iconClass()"
        size="lg"
      />
      <h1 [attr.data-theme]="theme()" class="theme-text text-xl font-semibold">
        {{ dialogData.title }}
      </h1>
    </header>

    @if (dialogData.data) {
      <div
        class="flex flex-col w-full gap-3 px-4 py-3 mb-6 border-gray-200 border-y"
      >
        @for (data of dialogData.data; track $index) {
          <div class="flex items-center justify-between">
            <span class="text-sm text-gray-500">{{ data.key }}</span>
            <span class="text-sm">{{ data.value }}</span>
          </div>
        }
      </div>
    }

    @if (dialogData.inputs) {
      <form [formGroup]="form!" class="flex flex-col w-full gap-3 px-4 mb-6">
        @for (input of dialogData.inputs; track $index) {
          @if (input.type !== 'textarea') {
            <app-input
              [formControlName]="input.key"
              class="w-full"
              [label]="input.label"
              [type]="input.type"
              [placeholder]="input.placeholder ?? ''"
              [value]="input.defaultValue"
            />
          } @else {
            <app-textarea
              [formControlName]="input.key"
              class="w-full"
              [label]="input.label"
              [placeholder]="input.placeholder ?? ''"
              [value]="input.defaultValue"
            />
          }
        }
      </form>
    }

    @if (dialogData.content) {
      <section
        class="flex items-center justify-center basis-full grow-0 md:basis-0"
      >
        @if (!dialogData.markdown) {
          <div
            [attr.data-theme]="theme()"
            [ngClass]="theme() === 'dark' ? 'text-gray-200' : 'text-gray-600'"
            class="max-h-full px-4 text-center text-pretty whitespace-pre-line"
          >
            {{ dialogData.content }}
          </div>
        } @else {
          <markdown
            class="px-4 basis-full grow-0"
            [data]="dialogData.content"
          />
        }
      </section>
    }
    <footer
      [attr.data-theme]="theme()"
      class="sticky bottom-0 z-10 flex w-full gap-3 p-4 mt-6 theme-wrapper border-t theme-border"
    >
      @if (!dialogData.buttons?.cancel?.hidden) {
        <app-button
          class="flex-1"
          expand
          variant="outline"
          [color]="dialogData.buttons?.cancel?.color ?? 'neutral'"
          (click)="onCancel()"
          >{{ dialogData.buttons?.cancel?.text ?? '취소' }}</app-button
        >
      }
      <app-button
        class="flex-1"
        expand
        [color]="dialogData.buttons?.confirm?.color ?? 'primary'"
        (click)="onConfirm()"
        [disabled]="!!form && form.invalid"
        >{{ dialogData.buttons?.confirm?.text ?? '확인' }}</app-button
      >
    </footer>
  </main>
}
