/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { centerChildControllerDeleteMany } from '../fn/child/center-child-controller-delete-many';
import { CenterChildControllerDeleteMany$Params } from '../fn/child/center-child-controller-delete-many';
import { centerChildControllerFindAll } from '../fn/child/center-child-controller-find-all';
import { CenterChildControllerFindAll$Params } from '../fn/child/center-child-controller-find-all';
import { centerChildControllerFindById } from '../fn/child/center-child-controller-find-by-id';
import { CenterChildControllerFindById$Params } from '../fn/child/center-child-controller-find-by-id';
import { centerChildControllerSearch } from '../fn/child/center-child-controller-search';
import { CenterChildControllerSearch$Params } from '../fn/child/center-child-controller-search';
import { CenterChildDto } from '../models/center-child-dto';
import { childControllerCenterCreate } from '../fn/child/child-controller-center-create';
import { ChildControllerCenterCreate$Params } from '../fn/child/child-controller-center-create';
import { childControllerConnect } from '../fn/child/child-controller-connect';
import { ChildControllerConnect$Params } from '../fn/child/child-controller-connect';
import { childControllerDelete } from '../fn/child/child-controller-delete';
import { ChildControllerDelete$Params } from '../fn/child/child-controller-delete';
import { childControllerDisconnect } from '../fn/child/child-controller-disconnect';
import { ChildControllerDisconnect$Params } from '../fn/child/child-controller-disconnect';
import { childControllerDisconnectByParent } from '../fn/child/child-controller-disconnect-by-parent';
import { ChildControllerDisconnectByParent$Params } from '../fn/child/child-controller-disconnect-by-parent';
import { childControllerFilter } from '../fn/child/child-controller-filter';
import { ChildControllerFilter$Params } from '../fn/child/child-controller-filter';
import { childControllerFindById } from '../fn/child/child-controller-find-by-id';
import { ChildControllerFindById$Params } from '../fn/child/child-controller-find-by-id';
import { childControllerFindByParentEmail } from '../fn/child/child-controller-find-by-parent-email';
import { ChildControllerFindByParentEmail$Params } from '../fn/child/child-controller-find-by-parent-email';
import { childControllerFindByRegistrationNo } from '../fn/child/child-controller-find-by-registration-no';
import { ChildControllerFindByRegistrationNo$Params } from '../fn/child/child-controller-find-by-registration-no';
import { childControllerFindOneByParent } from '../fn/child/child-controller-find-one-by-parent';
import { ChildControllerFindOneByParent$Params } from '../fn/child/child-controller-find-one-by-parent';
import { childControllerParentCreate } from '../fn/child/child-controller-parent-create';
import { ChildControllerParentCreate$Params } from '../fn/child/child-controller-parent-create';
import { childControllerSearch } from '../fn/child/child-controller-search';
import { ChildControllerSearch$Params } from '../fn/child/child-controller-search';
import { childControllerSearchByParent } from '../fn/child/child-controller-search-by-parent';
import { ChildControllerSearchByParent$Params } from '../fn/child/child-controller-search-by-parent';
import { childControllerUpdate } from '../fn/child/child-controller-update';
import { ChildControllerUpdate$Params } from '../fn/child/child-controller-update';
import { childControllerUpdateByParent } from '../fn/child/child-controller-update-by-parent';
import { ChildControllerUpdateByParent$Params } from '../fn/child/child-controller-update-by-parent';
import { childControllerValidConflict } from '../fn/child/child-controller-valid-conflict';
import { ChildControllerValidConflict$Params } from '../fn/child/child-controller-valid-conflict';
import { ChildDto } from '../models/child-dto';
import { ChildSearchFilterItemsDto } from '../models/child-search-filter-items-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { SearchChildResponseDto } from '../models/search-child-response-dto';

@Injectable({ providedIn: 'root' })
export class ChildService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `childControllerFilter()` */
  static readonly ChildControllerFilterPath = '/api/child/filter';

  /**
   * 아동 필터 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerFilter()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerFilter$Response(params: ChildControllerFilter$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildSearchFilterItemsDto>> {
    return childControllerFilter(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 필터 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerFilter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerFilter(params: ChildControllerFilter$Params, context?: HttpContext): Observable<ChildSearchFilterItemsDto> {
    return this.childControllerFilter$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildSearchFilterItemsDto>): ChildSearchFilterItemsDto => r.body)
    );
  }

  /** Path part for operation `childControllerSearch()` */
  static readonly ChildControllerSearchPath = '/api/child/search';

  /**
   * 아동 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerSearch$Response(params: ChildControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return childControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerSearch(params: ChildControllerSearch$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.childControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `childControllerSearchByParent()` */
  static readonly ChildControllerSearchByParentPath = '/api/child/search-by-parent';

  /**
   * 보호자가 자신의 아동을 검색합니다.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerSearchByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerSearchByParent$Response(params: ChildControllerSearchByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchChildResponseDto>> {
    return childControllerSearchByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 자신의 아동을 검색합니다.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerSearchByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerSearchByParent(params: ChildControllerSearchByParent$Params, context?: HttpContext): Observable<SearchChildResponseDto> {
    return this.childControllerSearchByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchChildResponseDto>): SearchChildResponseDto => r.body)
    );
  }

  /** Path part for operation `childControllerFindByRegistrationNo()` */
  static readonly ChildControllerFindByRegistrationNoPath = '/api/child/registrationNo';

  /**
   * 아동 조회.
   *
   * 이름과 주민등록번호로 아동을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerFindByRegistrationNo()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerFindByRegistrationNo$Response(params: ChildControllerFindByRegistrationNo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return childControllerFindByRegistrationNo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 조회.
   *
   * 이름과 주민등록번호로 아동을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerFindByRegistrationNo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerFindByRegistrationNo(params: ChildControllerFindByRegistrationNo$Params, context?: HttpContext): Observable<ChildDto> {
    return this.childControllerFindByRegistrationNo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

  /** Path part for operation `childControllerFindByParentEmail()` */
  static readonly ChildControllerFindByParentEmailPath = '/api/child/my-children';

  /**
   * 보호자 계정으로 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerFindByParentEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerFindByParentEmail$Response(params: ChildControllerFindByParentEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChildDto>>> {
    return childControllerFindByParentEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 계정으로 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerFindByParentEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerFindByParentEmail(params: ChildControllerFindByParentEmail$Params, context?: HttpContext): Observable<Array<ChildDto>> {
    return this.childControllerFindByParentEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChildDto>>): Array<ChildDto> => r.body)
    );
  }

  /** Path part for operation `childControllerFindOneByParent()` */
  static readonly ChildControllerFindOneByParentPath = '/api/child/my-child/by-parent/{id}';

  /**
   * 보호자가 내 아동의 상세 정보를 조회합니다.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerFindOneByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerFindOneByParent$Response(params: ChildControllerFindOneByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return childControllerFindOneByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 상세 정보를 조회합니다.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerFindOneByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerFindOneByParent(params: ChildControllerFindOneByParent$Params, context?: HttpContext): Observable<ChildDto> {
    return this.childControllerFindOneByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

  /** Path part for operation `childControllerFindById()` */
  static readonly ChildControllerFindByIdPath = '/api/child/{id}';

  /**
   * 아동 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerFindById$Response(params: ChildControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return childControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerFindById(params: ChildControllerFindById$Params, context?: HttpContext): Observable<ChildDto> {
    return this.childControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

  /** Path part for operation `childControllerDelete()` */
  static readonly ChildControllerDeletePath = '/api/child/{id}';

  /**
   * 아동 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerDelete$Response(params: ChildControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return childControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerDelete(params: ChildControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.childControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `childControllerUpdate()` */
  static readonly ChildControllerUpdatePath = '/api/child/{id}';

  /**
   * 아동 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childControllerUpdate$Response(params: ChildControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return childControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childControllerUpdate(params: ChildControllerUpdate$Params, context?: HttpContext): Observable<void> {
    return this.childControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `childControllerCenterCreate()` */
  static readonly ChildControllerCenterCreatePath = '/api/child/center/{centerId}';

  /**
   * 센터 아동 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerCenterCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childControllerCenterCreate$Response(params: ChildControllerCenterCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return childControllerCenterCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 아동 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerCenterCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childControllerCenterCreate(params: ChildControllerCenterCreate$Params, context?: HttpContext): Observable<ChildDto> {
    return this.childControllerCenterCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

  /** Path part for operation `childControllerParentCreate()` */
  static readonly ChildControllerParentCreatePath = '/api/child/guardian';

  /**
   * 보호자 아동 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerParentCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childControllerParentCreate$Response(params: ChildControllerParentCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return childControllerParentCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 아동 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerParentCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childControllerParentCreate(params: ChildControllerParentCreate$Params, context?: HttpContext): Observable<boolean> {
    return this.childControllerParentCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `childControllerValidConflict()` */
  static readonly ChildControllerValidConflictPath = '/api/child/regitrationNo/conflict';

  /**
   * 아동 중복 조호.
   *
   * 주민등록번호로 중복을 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerValidConflict()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childControllerValidConflict$Response(params: ChildControllerValidConflict$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return childControllerValidConflict(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 중복 조호.
   *
   * 주민등록번호로 중복을 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerValidConflict$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childControllerValidConflict(params: ChildControllerValidConflict$Params, context?: HttpContext): Observable<ChildDto> {
    return this.childControllerValidConflict$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

  /** Path part for operation `childControllerUpdateByParent()` */
  static readonly ChildControllerUpdateByParentPath = '/api/child/update-by-parent/{childId}';

  /**
   * 보호자가 내 아동의 정보를 수정합니다.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerUpdateByParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childControllerUpdateByParent$Response(params: ChildControllerUpdateByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return childControllerUpdateByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 정보를 수정합니다.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerUpdateByParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  childControllerUpdateByParent(params: ChildControllerUpdateByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.childControllerUpdateByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `childControllerConnect()` */
  static readonly ChildControllerConnectPath = '/api/child/connect/{id}';

  /**
   * 아동 연결.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerConnect()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerConnect$Response(params: ChildControllerConnect$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return childControllerConnect(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 연결.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerConnect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerConnect(params: ChildControllerConnect$Params, context?: HttpContext): Observable<void> {
    return this.childControllerConnect$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `childControllerDisconnect()` */
  static readonly ChildControllerDisconnectPath = '/api/child/disconnect/{id}';

  /**
   * 아동 연결 해제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerDisconnect()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerDisconnect$Response(params: ChildControllerDisconnect$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return childControllerDisconnect(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 연결 해제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerDisconnect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerDisconnect(params: ChildControllerDisconnect$Params, context?: HttpContext): Observable<void> {
    return this.childControllerDisconnect$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `childControllerDisconnectByParent()` */
  static readonly ChildControllerDisconnectByParentPath = '/api/child/disconnect-by-parent/{childId}';

  /**
   * 보호자가 내 아동을 삭제합니다. (연결 해제).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `childControllerDisconnectByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerDisconnectByParent$Response(params: ChildControllerDisconnectByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return childControllerDisconnectByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동을 삭제합니다. (연결 해제).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `childControllerDisconnectByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  childControllerDisconnectByParent(params: ChildControllerDisconnectByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.childControllerDisconnectByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `centerChildControllerFindAll()` */
  static readonly CenterChildControllerFindAllPath = '/api/child/center-child/all';

  /**
   * 센터에 소속된 아동 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerChildControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerChildControllerFindAll$Response(params: CenterChildControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CenterChildDto>>> {
    return centerChildControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerChildControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerChildControllerFindAll(params: CenterChildControllerFindAll$Params, context?: HttpContext): Observable<Array<CenterChildDto>> {
    return this.centerChildControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CenterChildDto>>): Array<CenterChildDto> => r.body)
    );
  }

  /** Path part for operation `centerChildControllerSearch()` */
  static readonly CenterChildControllerSearchPath = '/api/child/center-child/search';

  /**
   * 센터에 소속된 아동 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerChildControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerChildControllerSearch$Response(params: CenterChildControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return centerChildControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerChildControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerChildControllerSearch(params: CenterChildControllerSearch$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.centerChildControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerChildControllerFindById()` */
  static readonly CenterChildControllerFindByIdPath = '/api/child/center-child/{id}';

  /**
   * 센터에 소속된 아동 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerChildControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerChildControllerFindById$Response(params: CenterChildControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterChildDto>> {
    return centerChildControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerChildControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerChildControllerFindById(params: CenterChildControllerFindById$Params, context?: HttpContext): Observable<CenterChildDto> {
    return this.centerChildControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterChildDto>): CenterChildDto => r.body)
    );
  }

  /** Path part for operation `centerChildControllerDeleteMany()` */
  static readonly CenterChildControllerDeleteManyPath = '/api/child/center-child/{id}';

  /**
   * 센터에 소속된 아동 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerChildControllerDeleteMany()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerChildControllerDeleteMany$Response(params: CenterChildControllerDeleteMany$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return centerChildControllerDeleteMany(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerChildControllerDeleteMany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerChildControllerDeleteMany(params: CenterChildControllerDeleteMany$Params, context?: HttpContext): Observable<boolean> {
    return this.centerChildControllerDeleteMany$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
