/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { googleAuthControllerConnect } from '../fn/google/google-auth-controller-connect';
import { GoogleAuthControllerConnect$Params } from '../fn/google/google-auth-controller-connect';
import { googleAuthControllerFirebaseLogin } from '../fn/google/google-auth-controller-firebase-login';
import { GoogleAuthControllerFirebaseLogin$Params } from '../fn/google/google-auth-controller-firebase-login';
import { googleAuthControllerLogin } from '../fn/google/google-auth-controller-login';
import { GoogleAuthControllerLogin$Params } from '../fn/google/google-auth-controller-login';
import { OAuthResponseDto } from '../models/o-auth-response-dto';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class GoogleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `googleAuthControllerLogin()` */
  static readonly GoogleAuthControllerLoginPath = '/api/oauth/google/signin';

  /**
   * 구글 로그인.
   *
   * 구글 로그인을 수행합니다. 로그인에 성공하면, 엑세스, 리프레쉬 토큰은 응답 헤더에 포함됩니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googleAuthControllerLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  googleAuthControllerLogin$Response(params: GoogleAuthControllerLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<OAuthResponseDto>> {
    return googleAuthControllerLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 구글 로그인.
   *
   * 구글 로그인을 수행합니다. 로그인에 성공하면, 엑세스, 리프레쉬 토큰은 응답 헤더에 포함됩니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `googleAuthControllerLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googleAuthControllerLogin(params: GoogleAuthControllerLogin$Params, context?: HttpContext): Observable<OAuthResponseDto> {
    return this.googleAuthControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<OAuthResponseDto>): OAuthResponseDto => r.body)
    );
  }

  /** Path part for operation `googleAuthControllerFirebaseLogin()` */
  static readonly GoogleAuthControllerFirebaseLoginPath = '/api/oauth/google/firebase/sign-in';

  /**
   * Firebase 구글 로그인.
   *
   * Firebase 구글 로그인을 수행합니다. 로그인에 성공하면, 엑세스, 리프레쉬 토큰은 응답 헤더에 포함됩니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googleAuthControllerFirebaseLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  googleAuthControllerFirebaseLogin$Response(params: GoogleAuthControllerFirebaseLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<OAuthResponseDto>> {
    return googleAuthControllerFirebaseLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * Firebase 구글 로그인.
   *
   * Firebase 구글 로그인을 수행합니다. 로그인에 성공하면, 엑세스, 리프레쉬 토큰은 응답 헤더에 포함됩니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `googleAuthControllerFirebaseLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  googleAuthControllerFirebaseLogin(params: GoogleAuthControllerFirebaseLogin$Params, context?: HttpContext): Observable<OAuthResponseDto> {
    return this.googleAuthControllerFirebaseLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<OAuthResponseDto>): OAuthResponseDto => r.body)
    );
  }

  /** Path part for operation `googleAuthControllerConnect()` */
  static readonly GoogleAuthControllerConnectPath = '/api/oauth/google/connect';

  /**
   * 구글 계정 연결.
   *
   * 기존에 가입된 계정이 있는 경우 소셜 계정을 연결합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googleAuthControllerConnect()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  googleAuthControllerConnect$Response(params: GoogleAuthControllerConnect$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return googleAuthControllerConnect(this.http, this.rootUrl, params, context);
  }

  /**
   * 구글 계정 연결.
   *
   * 기존에 가입된 계정이 있는 경우 소셜 계정을 연결합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `googleAuthControllerConnect$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  googleAuthControllerConnect(params: GoogleAuthControllerConnect$Params, context?: HttpContext): Observable<UserDto> {
    return this.googleAuthControllerConnect$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

}
