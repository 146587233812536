import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { BaseConfig } from '../common/config/config.adapter';

@Component({
  selector: 'app-divider',
  styleUrls: ['./divider.component.scss'],
  standalone: true,
  template: `
    <div class="flex items-center">
      <div
        [attr.data-type]="type()"
        [attr.data-theme]="theme()"
        class="w-full h-px theme-divider"
      ></div>
      @if (label()) {
        <div [attr.data-theme]="theme()" class="px-4 theme-text font-semibold">
          {{ label() }}
        </div>
      }
      <div
        [attr.data-type]="type()"
        [attr.data-theme]="theme()"
        class="w-full h-px theme-divider"
      ></div>
    </div>
  `,
  imports: [CommonModule],
})
export class Divider extends BaseConfig {
  label = input<string>();
  type = input<'solid' | 'dashed'>();
}
