import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { GridColumn } from '../../column/column.component';
import { GridRow } from '../../row/row.component';
import { GridViewAdapter } from '../view.adapter';
import { FADE_IN } from '../../../../animations/fade.animation';

@Component({
  selector: 'app-grid-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [GridRow, GridColumn, CommonModule],
  animations: [FADE_IN],
})
export class GridTable extends GridViewAdapter {}
