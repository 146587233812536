<div
  (click)="handleActionSheet($event)"
  class="flex flex-col gap-2 cursor-default"
>
  @if (label()) {
    <app-label [required]="required()" [size]="size()">{{ label() }}</app-label>
  }
  <div (clickOutside)="open.set(false)" class="relative flex flex-col gap-1">
    <app-input
      [size]="size()"
      (keyup)="handleKeyup($event)"
      (inputFocus)="handleOpen()"
      (inputBlur)="handleBlur()"
      (valueChange)="handleSearch($event)"
      [(value)]="inputValue"
      [readonly]="!search() || isMobile()"
      [placeholder]="placeholder()"
      [debounce]="search() ? 300 : 0"
      [suffixIcon]="suffixIcon() || 'ic:baseline-keyboard-arrow-down'"
      [prefixIcon]="prefixIcon()"
      [disabled]="disabled()"
    />
    @if (open() && options().length > 0) {
      <div
        #menuWrapper
        [attr.data-theme]="theme()"
        [class.invisible]="!menuRender()"
        class="z-10 bg-white menu-wrapper theme-wrapper theme-border"
      >
        @for (option of options(); track option) {
          <app-select-option
            (select)="handleSelect($event)"
            (mouseEnter)="currentIndex.set($index)"
            (mouseLeave)="currentIndex.set(-1)"
            [option]="option"
            [focus]="currentIndex() === $index"
            [renderItem]="renderItem()"
          />
        }
      </div>
    }
  </div>
</div>
