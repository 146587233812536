<label>
  @if (label(); as label) {
    <app-label [required]="required()">{{ label }}</app-label>
  }
  @if (hint() && hint().length > 0) {
    <div class="flex flex-col gap-2">
      @for (hint of hint(); track $index) {
        <app-hint>{{ hint }}</app-hint>
      }
    </div>
  }

  <div
    [attr.data-size]="currentSize()"
    [attr.data-rounded]="currentRounded()"
    [attr.data-theme]="theme()"
    [attr.data-disabled]="disabled()"
    class="input-wrapper input-rounded input-padding"
  >
    <div
      [attr.data-color]="currentColor()"
      [attr.data-rounded]="currentRounded()"
      [attr.data-theme]="theme()"
      class="input-outline input-rounded"
    ></div>
    <textarea
      [style.height]="height() + 'px'"
      class="w-full input-text-size theme-text"
      [attr.data-theme]="theme()"
      [placeholder]="placeholder()"
      [spellcheck]="spellcheck()"
      [autocomplete]="autocomplete()"
      [autofocus]="autofocus()"
      [readOnly]="readonly()"
      [maxLength]="maxlength() || 524288"
      [(ngModel)]="value"
    ></textarea>
  </div>
</label>
