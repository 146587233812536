import { CommonModule } from '@angular/common';
import { Component, ElementRef, viewChild } from '@angular/core';
import { BaseConfig } from '../../common/config/config.adapter';

@Component({
  selector: '[alertContainer]',
  template: `
    <section #alert [attr.data-theme]="theme()" class="alert-container">
      <ng-content />
    </section>
  `,
  imports: [CommonModule],
  styleUrls: ['./alert-container.component.scss'],
})
export class AlertContainer extends BaseConfig {
  alertRef = viewChild<ElementRef<HTMLElement>>('alert');

  ngAfterViewInit() {
    this.alertRef()?.nativeElement.animate(
      [
        { opacity: 0, transform: 'translateY(10px)' },
        { opacity: 1, transform: 'translateY(0)' },
      ],
      {
        duration: 200,
        easing: 'ease-in-out',
      },
    );
  }
}
