<div [attr.data-theme]="theme()" class="theme-border table-wrapper">
  <table [class.!border-t-0]="!options()?.header?.show">
    <thead>
      <tr [attr.data-theme]="theme()">
        @for (column of columns(); track column.field) {
          <th
            gridColumn
            [(align)]="align"
            [(orderBy)]="orderBy"
            [(selecteds)]="selecteds"
            [column]="column"
            [showHeaderOption]="showHeaderOption()"
            [attr.data-theme]="theme()"
            class="theme-text"
            [ngClass]="{
              'left-0 sticky z-10 bg-dark-surface border-r':
                column.type === 'checkbox',
            }"
          ></th>
        }
      </tr>
    </thead>
    <tbody>
      @for (row of rowData(); track row) {
        <tr
          @fadeIn
          gridRow
          class="theme-text group"
          [class.cursor-pointer]="options()?.row?.clickHandler"
          [attr.data-theme]="theme()"
          [columns]="columns()!"
          [row]="row"
          [(selecteds)]="selecteds"
        ></tr>
      } @empty {
        <tr>
          <td
            [attr.colspan]="columns()?.length"
            class="text-center theme-text"
            [attr.data-theme]="theme()"
          >
            <p class="py-[5rem]">검색 결과를 찾을 수 없습니다.</p>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>
