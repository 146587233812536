import { Component } from '@angular/core';
import {
  ArrayValidator,
  FileFormField,
  FileUploader,
  FormFieldAdapter,
} from '../../../components';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-form-field',
  standalone: true,
  imports: [FileUploader],
  templateUrl: './file-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileFormFieldComponent,
      multi: true,
    },
  ],
})
export class FileFormFieldComponent extends FormFieldAdapter<
  FileFormField<any>
> {
  override ngOnInit(): void {
    super.ngOnInit();

    if (this.formControl) {
      this.formControl.addValidators([ArrayValidator()]);
    }
  }
}
