<section class="relative w-full h-full">
  <div #actionSheet class="fixed bottom-0 left-0 w-full bg-white rounded-t-2xl">
    <app-action-sheet-header (close)="close()">{{
      title() ? title() : '선택'
    }}</app-action-sheet-header>
    <div class="w-full h-px bg-gray-200"></div>
    @for (option of options(); track option.label) {
      <app-action-sheet-option
        [currentValue]="currentValue()"
        [renderItem]="renderItem()"
        [option]="option"
        (click)="handleClick(option)"
      />
    }
  </div>
</section>
