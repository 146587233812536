import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxGroup } from '../../../checkbox/checkbox-group/checkbox-group.component';
import { Checkbox } from '../../../checkbox/checkbox.component';
import { FormFieldAdapter } from '../../adapters/form-field.adapter';
import { CheckboxFormField } from '../../auto-form.type';
import { ErrorDirective } from '../../directives/error.directive';
import { ArrayValidator } from '../../validators/array.validator';

@Component({
  selector: 'app-checkbox-form-field',
  standalone: true,
  imports: [Checkbox, CheckboxGroup, ErrorDirective],
  templateUrl: './checkbox-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxFormFieldComponent,
      multi: true,
    },
  ],
})
export class CheckboxFormFieldComponent extends FormFieldAdapter<
  CheckboxFormField<any>
> {
  override ngOnInit(): void {
    super.ngOnInit();

    if (this.formControl) {
      if (this.formField().group) {
        this.formControl.addValidators(ArrayValidator());
      }
    }
  }
}
