import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputAddress } from '../../../input/address/input-address.component';
import { FormFieldAdapter } from '../../adapters/form-field.adapter';
import { AddressFormField } from '../../auto-form.type';
import { ErrorDirective } from '../../directives/error.directive';

@Component({
  selector: 'app-address-form-field',
  templateUrl: './address-form-field.component.html',
  imports: [InputAddress, ErrorDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AddressFormFieldComponent,
      multi: true,
    },
  ],
})
export class AddressFormFieldComponent extends FormFieldAdapter<
  AddressFormField<any>
> {}
