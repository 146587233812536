import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { injectNavigationEnd } from 'ngxtension/navigation-end';
import { Container } from '../../components/container/container.component';
import { IMenu, LayoutStore } from '../../stores/layout.store';
import { SideMenuModule } from './side-menu/side-menu.module';
import { TopBar } from './top-bar/top-bar.component';

export const menus: IMenu[] = [
  {
    name: '회원 관리',
    icon: 'material-symbols:person',
    href: 'user',
  },
  {
    name: '센터 관리',
    icon: 'material-symbols:business-center',
    href: 'center',
  },
  {
    name: '아동 관리',
    icon: 'fa6-solid:child-reaching',
    href: 'child',
  },
  {
    name: '업무 관리',
    icon: 'material-symbols:folder-copy-rounded',
    children: [
      { href: 'voucher', name: '바우처 관리' },
      { href: 'ticket-type', name: '치료유형 관리' },
    ],
  },
  {
    name: '고객센터 관리',
    icon: 'tabler:message-question',
    children: [
      { href: 'notice', name: '공지사항', icon: 'tabler:article' },
      { href: 'faq', name: 'FAQ', icon: 'tabler:help-circle-filled' },
      { href: 'inquiry', name: '1:1 문의', icon: 'tabler:message-question' },
    ],
  },
  {
    name: '앱 관리',
    icon: 'tabler:device-mobile',
    children: [
      { href: 'company-info', name: '회사 정보 관리' },
      { href: 'term', name: '약관 관리' },
    ],
  },
];

@Component({
  host: {
    ngSkipHydration: 'true',
  },
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  standalone: true,
  imports: [RouterOutlet, CommonModule, TopBar, SideMenuModule, Container],
})
export default class LayoutComponent implements OnInit {
  readonly store = inject(LayoutStore);
  readonly router = inject(Router);

  currentMenu = this.store.currentMenu;
  currentChildMenu = this.store.currentChildMenu;
  path = this.store.path;
  menus = this.store.menus;
  navigationEnd$ = injectNavigationEnd();

  ngOnInit(): void {
    this.store.setMenus(menus);
    this.store.setPath(this.router.url);

    this.navigationEnd$.subscribe({
      next: () => {
        this.store.setPath(this.router.url);
      },
    });
  }

  navigate(menu: IMenu) {
    this.router.navigateByUrl(menu.href!);
  }
}
