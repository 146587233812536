@if (event(); as event) {
  <div
    [dndDraggable]="{ event, type: 'drop' }"
    [cdkContextMenuTriggerFor]="contextMenu"
    (cdkContextMenuOpened)="isContextOpen.set(true)"
    (cdkContextMenuClosed)="isContextOpen.set(false)"
    [attr.data-theme]="theme()"
    [attr.data-color]="event.color"
    dndDraggingClass="dragging"
    class="event truncate"
  >
    <div
      class="prefix"
      [attr.data-all-day]="event.allDay"
      [attr.data-color]="event.color"
      [class.active]="isContextOpen()"
    ></div>
    <div
      [class.disabled]="!options()?.event?.resize"
      [dndDisableIf]="!options()?.event?.resize"
      [dndDraggable]="{ event, type: 'resize', time: 'start' }"
      class="resizer-top"
    ></div>
    <p timelineEventContent [event]="event"></p>
    <div
      [class.disabled]="!options()?.event?.resize"
      [dndDisableIf]="!options()?.event?.resize"
      [dndDraggable]="{ event, type: 'resize', time: 'end' }"
      class="resizer-bottom"
    ></div>
  </div>
}

<ng-template #contextMenu>
  <app-menu cdkMenu>
    @for (option of menus; track $index) {
      <app-menu-option
        (click)="menu.emit(option.value)"
        cdkMenuItem
        [icon]="option.icon"
        >{{ option.label }}</app-menu-option
      >
    }
  </app-menu>
</ng-template>
