import { Component, HostBinding, input } from '@angular/core';
import { BaseConfig } from '../common/config/config.adapter';

@Component({
  selector: '[text]',
  template: ` <ng-content /> `,
})
export class Text extends BaseConfig {
  @HostBinding('attr.data-theme') get hostTheme() {
    return this.theme();
  }

  @HostBinding('class') get hostClass() {
    return this._class();
  }

  _class = input<string>('', { alias: 'class' });
}
