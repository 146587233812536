@if (options() && options().length > 0) {
  <section
    #container
    class="relative flex w-full overflow-y-visible border-b theme-border"
    [class.overflow-x-auto]="scrollEnabled()"
    [attr.data-theme]="theme()"
  >
    @for (option of options(); track $index) {
      <div
        segmentOption
        #item
        (click)="handleClick(option)"
        [active]="value() === option.value"
        [option]="option"
        [color]="color()"
      ></div>
    }
    <div
      #indicator
      [attr.data-color]="color()"
      class="indicator absolute transition-all duration-300 ease-in-out"
      style="height: 0.15rem"
      [style.bottom]="scrollEnabled() ? 0 : '-0.15rem'"
    ></div>
  </section>
}
