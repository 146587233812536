import { computed, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AdminDto } from '@malirang/api-client';
import { BaseStore } from '@malirang/front-share';
import { map } from 'rxjs';

type State = {
  admin: AdminDto | null;
};

@Injectable({ providedIn: 'root' })
export class AdminStore extends BaseStore<State> {
  admin$ = this.state$.pipe(map((state) => state.admin));
  admin = toSignal(this.admin$);

  isAuth = computed(() => !!this.admin());

  constructor() {
    super({
      admin: null,
    });
  }

  setAdmin(admin: AdminDto) {
    this.updateState({
      admin,
    });
  }
}
