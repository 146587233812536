import { Injectable, inject } from '@angular/core';
import { IModal } from '../../services/modal/modal.interface';
import { ModalService } from '../../services/modal/modal.service';
import ActionSheetComponent from './action-sheet.component';
import { ActionSheetProps } from './action-sheet.type';

@Injectable({ providedIn: 'root' })
export class ActionSheetService implements IModal {
  private readonly modalService = inject(ModalService);

  create(props: ActionSheetProps) {
    const actionSheet = this.modalService.create(ActionSheetComponent, {
      componentProps: {
        ...props,
      },
    });

    return actionSheet;
  }

  close(data?: any) {
    this.modalService.close(data);
  }
}
