import { Component, computed } from "@angular/core";
import { get } from "../../../../../helpers/object-get";
import { Badge } from "../../../../badge/badge.component";
import {
  BooleanColumnDefinition,
  EnumColumnDefinition,
} from "../../../types/colum-definition.type";
import { CellAdapter } from "../cell.adapter";

@Component({
  selector: "app-badge-cell",
  template: ` <app-badge
    [color]="props()?.color || 'primary'"
    [variant]="variant() || 'solid'"
    >{{ props()?.text }}</app-badge
  >`,
  standalone: true,
  imports: [Badge],
})
export class BadgeCell extends CellAdapter {
  gridOptions = this.store.tableOptions;
  variant = computed(() => this.gridOptions()?.badge?.variant);

  props = computed(() => {
    if (!this.column()) return;

    if (this.column().type === "boolean") {
      return this.handleBooleanColumn();
    } else {
      return this.handleEnumColumn();
    }
  });

  handleBooleanColumn() {
    const column = this.column() as BooleanColumnDefinition;
    const value = get(this.row(), this.column().field, "");
    return column.badgeProps![value === true ? "true" : "false"]!;
  }

  handleEnumColumn() {
    const column = this.column() as EnumColumnDefinition;
    const value = get(this.row(), this.column().field, "");
    return column.badgeProps![value];
  }
}
