import {
  booleanAttribute,
  Component,
  computed,
  effect,
  input,
  model,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Size } from '../../components';
import { Label } from '../../label/label.component';
import { Checkbox } from '../checkbox.component';

export type CheckboxOption = {
  label: string;
  value: string;
  disabled?: boolean;
  description?: string;
};

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  imports: [Checkbox, Label],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxGroup,
      multi: true,
    },
  ],
})
export class CheckboxGroup implements ControlValueAccessor {
  required = input<boolean, string>(false, { transform: booleanAttribute });
  label = input<string>();
  $options = input.required<string[] | CheckboxOption[]>({ alias: 'options' });
  options = computed(() => {
    return this.$options().map((option) => {
      if (typeof option === 'string') {
        return {
          label: option,
          value: option,
        };
      }
      return option;
    });
  });
  value = model<any[]>();
  size = input<Size>();

  constructor() {
    effect(() => {
      this.onChange(this.value());
    });
  }

  writeValue(obj: any): void {
    this.value.set(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}

  onChange = (ev?: any) => {};
  onTouched = () => {};

  handleValueChange(ev: boolean, value: any) {
    if (ev) {
      this.value.set([...(this.value() || []), value]);
    } else {
      this.value.set(this.value()?.filter((v) => v !== value));
    }
  }
}
