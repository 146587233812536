import { computed, Injectable } from '@angular/core';
import { BaseStore } from '../../stores/base.store';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { get } from '../../helpers/object-get';

type State = {
  search: boolean; // 검색 기능 사용 여부
  bindValue: string;
  bindLabel: string;
  currentValue: any; // 현재 선택된 값
};

@Injectable({ providedIn: 'any' })
export class SelectStore extends BaseStore<State> {
  bindLabel = toSignal(this.state$.pipe(map((state) => state.bindLabel)));
  bindValue = toSignal(this.state$.pipe(map((state) => state.bindValue)));
  search = toSignal(this.state$.pipe(map((state) => state.search)));
  currentValue = toSignal(this.state$.pipe(map((state) => state.currentValue)));

  constructor() {
    super({
      search: true,
      bindValue: '',
      bindLabel: '',
      currentValue: '',
    });
  }

  init(state: State) {
    this.updateState(state);
  }

  setCurrentValue(value: any) {
    this.updateState({ currentValue: value });
  }

  getLabel(option: any) {
    if (typeof option === 'object') {
      return get(option, this.bindLabel() || '');
    }

    return option;
  }

  getValue(option: any) {
    if (typeof option === 'object') {
      return get(option, this.bindValue() || '');
    }

    return option;
  }
}
