import { CommonModule } from "@angular/common";
import { Component, computed } from "@angular/core";
import dayjs from "dayjs";
import { InputBaseDay } from "../calendar-base-day";

@Component({
  selector: "app-range-day",
  imports: [CommonModule],
  standalone: true,
  styleUrls: ["../calendar.component.scss"],
  templateUrl: "./range-day.component.html",
})
export class RangeDay extends InputBaseDay {
  start = this.store.start;
  end = this.store.end;

  has = computed(
    () =>
      dayjs(this.start()).isBefore(this.day()) &&
      dayjs(this.end()).isAfter(this.day()),
  );

  override selected = computed(
    () =>
      dayjs(this.start()).format("YYYY-MM-DD") ===
        this.day().format("YYYY-MM-DD") ||
      dayjs(this.end()).format("YYYY-MM-DD") ===
        this.day().format("YYYY-MM-DD"),
  );

  textColor = computed(() => {
    if (dayjs(this.value()).get("month") !== this.day().get("month")) {
      return "prev-month";
    }

    if (this.selected()) {
      return "selected";
    }

    if (this.day().get("day") === 0) {
      return "sunday";
    }

    if (this.day().get("day") === 6) {
      return "saturday";
    }

    return "default";
  });

  constructor() {
    super();
  }

  handleClick() {
    const date = this.day().toDate();
    this.store.setValue(date);

    if (!this.start() && !this.end()) {
      this.store.setStart(date);
    } else if (this.start() && !this.end()) {
      this.store.setEnd(date);
    } else if (this.start() && this.end()) {
      this.store.setStart(date);
      this.store.setEnd(null);
    }

    this.select.emit();
  }
}
