/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreateTicketDto } from '../../models/create-ticket-dto';
import { TicketDto } from '../../models/ticket-dto';

export interface TicketControllerCreate$Params {
  centerId: string;
  teacherId: string;
  childId: string;
      body: CreateTicketDto
}

export function ticketControllerCreate(http: HttpClient, rootUrl: string, params: TicketControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketDto>> {
  const rb = new RequestBuilder(rootUrl, ticketControllerCreate.PATH, 'post');
  if (params) {
    rb.query('centerId', params.centerId, {});
    rb.query('teacherId', params.teacherId, {});
    rb.query('childId', params.childId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TicketDto>;
    })
  );
}

ticketControllerCreate.PATH = '/api/ticket';
