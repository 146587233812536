import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, output, signal } from '@angular/core';
import dayjs from 'dayjs';
import { Menu } from '../../../menu/menu.component';
import { BaseConfig } from '../../../common/config/config.adapter';
import { CalendarStore } from '../../calendar.store';
import { IconButton } from '../../../button/icon-button.component';

@Component({
  selector: 'app-calendar-select',
  styleUrls: ['./calendar-select.component.scss'],
  standalone: true,
  imports: [CommonModule, IconButton, Menu],
  template: `
    <app-menu>
      <div class="flex gap-2 items-center px-4 py-2">
        <app-icon-button
          (click)="handleNavigate('prev', 'year', 1)"
          icon="ic:baseline-chevron-left"
        />
        <p
          [attr.data-theme]="theme()"
          class="font-semibold theme-text text-base md:text-lg"
        >
          {{ date() | date: 'y년' }}
        </p>
        <app-icon-button
          (click)="handleNavigate('next', 'year', 1)"
          icon="ic:baseline-chevron-right"
        />
      </div>
      <div class="grid grid-cols-6 px-4 py-2 gap-2">
        @for (month of months; track month) {
          <div
            (click)="handleSelect(month)"
            [attr.data-active]="active(month)"
            [attr.data-theme]="theme()"
            class="month-item theme-hover theme-text"
          >
            {{ month }}월
          </div>
        }
      </div>
    </app-menu>
  `,
})
export class CalendarSelect extends BaseConfig implements OnInit {
  readonly store = inject(CalendarStore);

  select = output<Date>();

  date = signal<Date | null>(new Date());
  months = this.store.months;

  ngOnInit(): void {
    this.date.set(this.store.value() || new Date());
  }

  active(month: number) {
    return dayjs(this.date()).get('month') === month - 1;
  }

  /**
   * @description 년도와 달을 이동하는 통합 함수
   * @param type
   * @param unit
   * @param number
   */
  handleNavigate(
    type: 'prev' | 'next',
    unit: 'year' | 'month',
    number: number,
  ) {
    if (type === 'prev') {
      const value = dayjs(this.date()).subtract(number, unit).toDate();
      this.date.set(value);
    } else {
      const value = dayjs(this.date()).add(number, unit).toDate();
      this.date.set(value);
    }
  }

  /**
   * @description 년도와 달을 선택했을 경우 이벤트 핸들링
   * @param month
   */
  handleSelect(month: number) {
    const date = dayjs(this.date())
      .set('month', month - 1)
      .toDate();
    this.select.emit(date);
  }
}
