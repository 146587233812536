import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ThemeStore } from '@malirang/front-share';

@Component({
  selector: 'app-side-menu-item-child',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './side-menu-item-child.component.html',
  styleUrls: ['./side-menu-item-child.component.scss'],
})
export class SideMenuItemChild {
  readonly themeStore = inject(ThemeStore);
  theme = this.themeStore.theme;

  active = input<boolean>(false);
}
