import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Toggle } from '../../../toggle/toggle.component';
import { FormFieldAdapter } from '../../adapters/form-field.adapter';
import { ToggleFormField } from '../../auto-form.type';
import { Label } from '../../../label/label.component';

@Component({
  selector: 'app-toggle-form-field',
  standalone: true,
  imports: [Toggle, Label],
  templateUrl: './toggle-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ToggleFormFieldComponent,
      multi: true,
    },
  ],
})
export class ToggleFormFieldComponent extends FormFieldAdapter<
  ToggleFormField<any>
> {}
