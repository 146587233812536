/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { enrollmentScheduleControllerCopyV1 } from '../fn/enrollment-schedule/enrollment-schedule-controller-copy-v-1';
import { EnrollmentScheduleControllerCopyV1$Params } from '../fn/enrollment-schedule/enrollment-schedule-controller-copy-v-1';
import { enrollmentScheduleControllerCreateV1 } from '../fn/enrollment-schedule/enrollment-schedule-controller-create-v-1';
import { EnrollmentScheduleControllerCreateV1$Params } from '../fn/enrollment-schedule/enrollment-schedule-controller-create-v-1';
import { enrollmentScheduleControllerDeleteRepeatV1 } from '../fn/enrollment-schedule/enrollment-schedule-controller-delete-repeat-v-1';
import { EnrollmentScheduleControllerDeleteRepeatV1$Params } from '../fn/enrollment-schedule/enrollment-schedule-controller-delete-repeat-v-1';
import { enrollmentScheduleControllerDeleteV1 } from '../fn/enrollment-schedule/enrollment-schedule-controller-delete-v-1';
import { EnrollmentScheduleControllerDeleteV1$Params } from '../fn/enrollment-schedule/enrollment-schedule-controller-delete-v-1';
import { enrollmentScheduleControllerFindByIdV1 } from '../fn/enrollment-schedule/enrollment-schedule-controller-find-by-id-v-1';
import { EnrollmentScheduleControllerFindByIdV1$Params } from '../fn/enrollment-schedule/enrollment-schedule-controller-find-by-id-v-1';
import { enrollmentScheduleControllerFindV1 } from '../fn/enrollment-schedule/enrollment-schedule-controller-find-v-1';
import { EnrollmentScheduleControllerFindV1$Params } from '../fn/enrollment-schedule/enrollment-schedule-controller-find-v-1';
import { enrollmentScheduleControllerSearchByParentV1 } from '../fn/enrollment-schedule/enrollment-schedule-controller-search-by-parent-v-1';
import { EnrollmentScheduleControllerSearchByParentV1$Params } from '../fn/enrollment-schedule/enrollment-schedule-controller-search-by-parent-v-1';
import { enrollmentScheduleControllerUpdateDateV1 } from '../fn/enrollment-schedule/enrollment-schedule-controller-update-date-v-1';
import { EnrollmentScheduleControllerUpdateDateV1$Params } from '../fn/enrollment-schedule/enrollment-schedule-controller-update-date-v-1';
import { enrollmentScheduleControllerUpdateRepeatV1 } from '../fn/enrollment-schedule/enrollment-schedule-controller-update-repeat-v-1';
import { EnrollmentScheduleControllerUpdateRepeatV1$Params } from '../fn/enrollment-schedule/enrollment-schedule-controller-update-repeat-v-1';
import { enrollmentScheduleControllerUpdateV1 } from '../fn/enrollment-schedule/enrollment-schedule-controller-update-v-1';
import { EnrollmentScheduleControllerUpdateV1$Params } from '../fn/enrollment-schedule/enrollment-schedule-controller-update-v-1';
import { EnrollmentScheduleDto } from '../models/enrollment-schedule-dto';
import { SearchEnrollmentScheduleResponseDto } from '../models/search-enrollment-schedule-response-dto';

@Injectable({ providedIn: 'root' })
export class EnrollmentScheduleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `enrollmentScheduleControllerFindV1()` */
  static readonly EnrollmentScheduleControllerFindV1Path = '/api/v1/enrollment-schedule';

  /**
   * 발달서비스 일정 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentScheduleControllerFindV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerFindV1$Response(params: EnrollmentScheduleControllerFindV1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EnrollmentScheduleDto>>> {
    return enrollmentScheduleControllerFindV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentScheduleControllerFindV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerFindV1(params: EnrollmentScheduleControllerFindV1$Params, context?: HttpContext): Observable<Array<EnrollmentScheduleDto>> {
    return this.enrollmentScheduleControllerFindV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EnrollmentScheduleDto>>): Array<EnrollmentScheduleDto> => r.body)
    );
  }

  /** Path part for operation `enrollmentScheduleControllerCreateV1()` */
  static readonly EnrollmentScheduleControllerCreateV1Path = '/api/v1/enrollment-schedule';

  /**
   * 발달서비스 일정 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentScheduleControllerCreateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentScheduleControllerCreateV1$Response(params: EnrollmentScheduleControllerCreateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return enrollmentScheduleControllerCreateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentScheduleControllerCreateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentScheduleControllerCreateV1(params: EnrollmentScheduleControllerCreateV1$Params, context?: HttpContext): Observable<boolean> {
    return this.enrollmentScheduleControllerCreateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `enrollmentScheduleControllerFindByIdV1()` */
  static readonly EnrollmentScheduleControllerFindByIdV1Path = '/api/v1/enrollment-schedule/{id}';

  /**
   * 발달서비스 일정 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentScheduleControllerFindByIdV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerFindByIdV1$Response(params: EnrollmentScheduleControllerFindByIdV1$Params, context?: HttpContext): Observable<StrictHttpResponse<EnrollmentScheduleDto>> {
    return enrollmentScheduleControllerFindByIdV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentScheduleControllerFindByIdV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerFindByIdV1(params: EnrollmentScheduleControllerFindByIdV1$Params, context?: HttpContext): Observable<EnrollmentScheduleDto> {
    return this.enrollmentScheduleControllerFindByIdV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnrollmentScheduleDto>): EnrollmentScheduleDto => r.body)
    );
  }

  /** Path part for operation `enrollmentScheduleControllerDeleteV1()` */
  static readonly EnrollmentScheduleControllerDeleteV1Path = '/api/v1/enrollment-schedule/{id}';

  /**
   * 발달서비스 일정 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentScheduleControllerDeleteV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerDeleteV1$Response(params: EnrollmentScheduleControllerDeleteV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return enrollmentScheduleControllerDeleteV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentScheduleControllerDeleteV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerDeleteV1(params: EnrollmentScheduleControllerDeleteV1$Params, context?: HttpContext): Observable<boolean> {
    return this.enrollmentScheduleControllerDeleteV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `enrollmentScheduleControllerUpdateV1()` */
  static readonly EnrollmentScheduleControllerUpdateV1Path = '/api/v1/enrollment-schedule/{id}';

  /**
   * 발달서비스 일정 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentScheduleControllerUpdateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentScheduleControllerUpdateV1$Response(params: EnrollmentScheduleControllerUpdateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return enrollmentScheduleControllerUpdateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentScheduleControllerUpdateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentScheduleControllerUpdateV1(params: EnrollmentScheduleControllerUpdateV1$Params, context?: HttpContext): Observable<boolean> {
    return this.enrollmentScheduleControllerUpdateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `enrollmentScheduleControllerSearchByParentV1()` */
  static readonly EnrollmentScheduleControllerSearchByParentV1Path = '/api/v1/enrollment-schedule/search-by-parent';

  /**
   * 보호자가 발달서비스를 기준으로 내 아동의 발달서비스 일정 검색 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentScheduleControllerSearchByParentV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerSearchByParentV1$Response(params: EnrollmentScheduleControllerSearchByParentV1$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchEnrollmentScheduleResponseDto>> {
    return enrollmentScheduleControllerSearchByParentV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 발달서비스를 기준으로 내 아동의 발달서비스 일정 검색 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentScheduleControllerSearchByParentV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerSearchByParentV1(params: EnrollmentScheduleControllerSearchByParentV1$Params, context?: HttpContext): Observable<SearchEnrollmentScheduleResponseDto> {
    return this.enrollmentScheduleControllerSearchByParentV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchEnrollmentScheduleResponseDto>): SearchEnrollmentScheduleResponseDto => r.body)
    );
  }

  /** Path part for operation `enrollmentScheduleControllerUpdateDateV1()` */
  static readonly EnrollmentScheduleControllerUpdateDateV1Path = '/api/v1/enrollment-schedule/date';

  /**
   * 발달서비스 일정 날짜 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentScheduleControllerUpdateDateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentScheduleControllerUpdateDateV1$Response(params: EnrollmentScheduleControllerUpdateDateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return enrollmentScheduleControllerUpdateDateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 날짜 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentScheduleControllerUpdateDateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentScheduleControllerUpdateDateV1(params: EnrollmentScheduleControllerUpdateDateV1$Params, context?: HttpContext): Observable<boolean> {
    return this.enrollmentScheduleControllerUpdateDateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `enrollmentScheduleControllerCopyV1()` */
  static readonly EnrollmentScheduleControllerCopyV1Path = '/api/v1/enrollment-schedule/copy';

  /**
   * 발달서비스 일정 복사.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentScheduleControllerCopyV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerCopyV1$Response(params: EnrollmentScheduleControllerCopyV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return enrollmentScheduleControllerCopyV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일정 복사.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentScheduleControllerCopyV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerCopyV1(params: EnrollmentScheduleControllerCopyV1$Params, context?: HttpContext): Observable<boolean> {
    return this.enrollmentScheduleControllerCopyV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `enrollmentScheduleControllerUpdateRepeatV1()` */
  static readonly EnrollmentScheduleControllerUpdateRepeatV1Path = '/api/v1/enrollment-schedule/{id}/repeat';

  /**
   * 발달서비스 반복 일정 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentScheduleControllerUpdateRepeatV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentScheduleControllerUpdateRepeatV1$Response(params: EnrollmentScheduleControllerUpdateRepeatV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return enrollmentScheduleControllerUpdateRepeatV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 반복 일정 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentScheduleControllerUpdateRepeatV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentScheduleControllerUpdateRepeatV1(params: EnrollmentScheduleControllerUpdateRepeatV1$Params, context?: HttpContext): Observable<boolean> {
    return this.enrollmentScheduleControllerUpdateRepeatV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `enrollmentScheduleControllerDeleteRepeatV1()` */
  static readonly EnrollmentScheduleControllerDeleteRepeatV1Path = '/api/v1/enrollment-schedule/repeat/{id}';

  /**
   * 발달서비스 반복 일정 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentScheduleControllerDeleteRepeatV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerDeleteRepeatV1$Response(params: EnrollmentScheduleControllerDeleteRepeatV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return enrollmentScheduleControllerDeleteRepeatV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 반복 일정 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentScheduleControllerDeleteRepeatV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentScheduleControllerDeleteRepeatV1(params: EnrollmentScheduleControllerDeleteRepeatV1$Params, context?: HttpContext): Observable<boolean> {
    return this.enrollmentScheduleControllerDeleteRepeatV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
