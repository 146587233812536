import {
  Component,
  computed,
  effect,
  input,
  model,
  output,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BaseConfig } from '../common/config/config.adapter';
import { Size } from '../components';

@Component({
  selector: 'app-checkbox-adapter',
  template: '',
})
export class CheckboxAdapter
  extends BaseConfig
  implements ControlValueAccessor
{
  label = input<string | null>('');
  description = input<string | null>('');
  $size = input<Size | null>(null, { alias: 'size' });
  _size = computed(() => this.globalConfig()?.checkbox?.size);

  size = computed(() => this.$size() || this._size() || 'md');

  checked = output<boolean>();

  modelValue = model<any>(null);
  value = model<boolean>(false);

  constructor() {
    super();

    effect(() => {
      this.onChange(this.value());
    });
  }

  toggle() {
    this.value.set(!this.value());
    this.checked.emit(this.value());
  }

  writeValue(obj: any): void {
    this.value.set(!!obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}

  onChange = (value: any) => {};
  onTouched = () => {};
}
