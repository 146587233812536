import { Component, input, numberAttribute } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Hint } from '../hint/hint.component';
import { BaseInput } from '../input/base-input';
import { Label } from '../label/label.component';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss', '../input/input.component.scss'],
  standalone: true,
  imports: [Label, FormsModule, Hint],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: Textarea,
      multi: true,
    },
  ],
})
export class Textarea extends BaseInput<string> {
  height = input<number, string>(100, { transform: numberAttribute });
  hint = input<string[]>([]);
}
