@switch (provider()) {
  @case (fnGetKeyByValue(oathProvider, oathProvider.EMAIL)) {
    <img
      src="https://api.iconify.design/mdi:email.svg?color=%231693c9"
      alt="이메일 아이콘"
      class="size-6"
    />
  }

  @case (fnGetKeyByValue(oathProvider, oathProvider.KAKAO)) {
    <img
      src="https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/kakao.svg"
      alt="카카오 아이콘"
      class="size-6"
    />
  }
  @case (fnGetKeyByValue(oathProvider, oathProvider.NAVER)) {
    <div
      class="bg-[#03C759] text-sm font-extrabold size-6 flex items-center justify-center rounded-full"
    >
      <img
        src="https://api.iconify.design/simple-icons:naver.svg?color=%23ffffff"
        alt="네이버 아이콘"
        class="size-3"
      />
    </div>
  }
  @case (fnGetKeyByValue(oathProvider, oathProvider.GOOGLE)) {
    <div
      class="flex items-center justify-center bg-white border rounded-full size-6"
    >
      <img
        src="https://api.iconify.design/logos:google-icon.svg"
        alt="구글 아이콘"
        class="size-3"
      />
    </div>
  }
  @case (fnGetKeyByValue(oathProvider, oathProvider.APPLE)) {
    <div
      class="flex items-center justify-center bg-white border rounded-full size-6"
    >
      <img
        src="https://api.iconify.design/ic:round-apple.svg?color=black"
        alt="애플 아이콘"
        class="size-3"
      />
    </div>
  }
}
