<div
  [attr.data-theme]="theme()"
  [attr.data-time]="time()"
  class="theme-text timeline w-full"
>
  <div class="absolute">
    @for (day of days(); track day.format('DD')) {
      <app-timeline-event-wrapper
        [slots]="slots()"
        [events]="renderEvents()!"
        [day]="day"
        [time]="time()"
        (menu)="menu($event)"
      />
    }
  </div>
  <div class="flex w-full min-w-max items-center">
    <div class="sticky left-0 z-10">
      <p [attr.data-theme]="theme()" class="time theme-border">
        {{ time() | time }}
      </p>
      @if (!last()) {
        <app-divider />
      }
    </div>
    <div [attr.data-theme]="theme()" class="flex w-full">
      @for (day of days(); track day.format('DD')) {
        <div
          dndDropzone
          dndDragoverClass="drag-over"
          (dndDrop)="drop($event, day)"
          [attr.data-theme]="theme()"
          [attr.data-day]="day.format('DD')"
          [class.border-r]="!$last"
          [class.border-b]="!last()"
          class="day flex-1 min-h-12 h-full min-w-[8rem] theme-border"
        ></div>
      }
    </div>
  </div>
</div>
