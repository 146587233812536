import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  inject,
  model,
  OnInit,
  signal,
} from '@angular/core';
import dayjs from 'dayjs';
import { ClickOutside } from 'ngxtension/click-outside';
import { CalendarSelect } from '../select/calendar-select.component';
import { BaseConfig } from '../../../common/config/config.adapter';
import { CalendarStore } from '../../calendar.store';
import { CalendarService } from '../../calendar.service';
import { Button } from '../../../button/button.component';
import { IconButton } from '../../../button/icon-button.component';
import { TabOption, Tabs } from '../../../tabs/tabs.component';
import { Icon } from '../../../icon/icon.component';
import { CalendarMode } from '../../calendar.types';
import { toObservableSignal } from 'ngxtension/to-observable-signal';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  standalone: true,
  imports: [
    Button,
    CalendarSelect,
    ClickOutside,
    IconButton,
    CommonModule,
    Tabs,
    Icon,
  ],
})
export class CalendarHeader extends BaseConfig implements OnInit {
  readonly store = inject(CalendarStore);
  readonly service = inject(CalendarService);

  modes: TabOption[] = [
    {
      icon: 'material-symbols:calendar-today-outline',
      label: '일',
      value: 'day',
    },
    {
      icon: 'material-symbols:calendar-view-month-outline',
      label: '주',
      value: 'week',
    },
    {
      icon: 'material-symbols:calendar-view-month-outline-sharp',
      label: '월',
      value: 'month',
    },
  ];

  views: TabOption[] = [
    {
      icon: 'majesticons:noteblock',
      label: '캘린더',
      value: 'block',
    },
    {
      icon: 'material-symbols:view-timeline',
      label: '타임라인',
      value: 'timeline',
    },
  ];

  view = this.store.view;

  mode = model<CalendarMode>();
  _mode = toObservableSignal(this.store.mode);

  value = this.store.value;
  isOpen = signal<boolean>(false); // 년도와 달을 선택하는 섹션 띄우는 flag

  ngOnInit(): void {
    this._mode.subscribe((mode) => {
      this.mode.set(mode);
    });
  }

  /**
   * @description 년도와 달을 이동하는 통합 함수
   * @param type
   * @param unit
   * @param number
   */
  handleNavigate(
    type: 'prev' | 'next',
    unit: 'year' | 'month' | 'week' | 'day',
    number: number,
  ) {
    if (type === 'prev') {
      const value = dayjs(this.value()).subtract(number, unit).toDate();
      this.store.setValue(value);
    } else {
      const value = dayjs(this.value()).add(number, unit).toDate();
      this.store.setValue(value);
    }
  }

  /**
   * @description 년도와 달을 선택했을 경우 이벤트 핸들링
   * @param ev
   */
  handleSelectMonthAndYear(ev: Date) {
    this.store.setValue(ev);
    this.isOpen.set(false);
  }

  /**
   * @description 일,주,월 중 선택한 모드에 따라 스토어에 저장
   * @param mode
   */
  handleModeChange(mode: any) {
    this.store.setMode(mode);

    if (mode === 'day') {
      this.store.setView('timeline');
    } else {
      this.store.setView('block');
    }
  }

  /**
   * @description 블록, 타임라인 중 선택한 뷰에 따라 스토어에 저장
   * @param view
   */
  handleViewChange(view: any) {
    this.store.setView(view);
  }

  handleCreate() {
    this.service.handleCreate();
  }
}
