<button
  [attr.data-theme]="theme()"
  [attr.data-size]="currentSize()"
  [attr.data-color]="currentColor()"
  [attr.data-rounded]="currentRounded()"
  [attr.data-variant]="currentVariant()"
  [attr.data-expand]="expand()"
  [attr.data-loading]="loading()"
  [disabled]="disabled() || loading()"
  [type]="type()"
  [ngClass]="currentClass()"
  class="min-w-max"
>
  @if (currentIcon(); as icon) {
    <app-icon
      class="shrink-0"
      size="sm"
      [name]="icon"
      [attr.data-color]="currentColor()"
      [attr.data-variant]="currentVariant()"
      [ngClass]="iconClass()"
    />
  }
  <ng-content />
</button>
