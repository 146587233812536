/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { faqControllerCreateV1 } from '../fn/faq/faq-controller-create-v-1';
import { FaqControllerCreateV1$Params } from '../fn/faq/faq-controller-create-v-1';
import { faqControllerDeleteV1 } from '../fn/faq/faq-controller-delete-v-1';
import { FaqControllerDeleteV1$Params } from '../fn/faq/faq-controller-delete-v-1';
import { faqControllerFindAllV1 } from '../fn/faq/faq-controller-find-all-v-1';
import { FaqControllerFindAllV1$Params } from '../fn/faq/faq-controller-find-all-v-1';
import { faqControllerFindByIdV1 } from '../fn/faq/faq-controller-find-by-id-v-1';
import { FaqControllerFindByIdV1$Params } from '../fn/faq/faq-controller-find-by-id-v-1';
import { faqControllerSearchV1 } from '../fn/faq/faq-controller-search-v-1';
import { FaqControllerSearchV1$Params } from '../fn/faq/faq-controller-search-v-1';
import { faqControllerTogglePinnedV1 } from '../fn/faq/faq-controller-toggle-pinned-v-1';
import { FaqControllerTogglePinnedV1$Params } from '../fn/faq/faq-controller-toggle-pinned-v-1';
import { faqControllerUpdateV1 } from '../fn/faq/faq-controller-update-v-1';
import { FaqControllerUpdateV1$Params } from '../fn/faq/faq-controller-update-v-1';
import { FaqDto } from '../models/faq-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class FaqService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `faqControllerSearchV1()` */
  static readonly FaqControllerSearchV1Path = '/api/v1/faq/search';

  /**
   * FAQ 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerSearchV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerSearchV1$Response(params: FaqControllerSearchV1$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return faqControllerSearchV1(this.http, this.rootUrl, params, context);
  }

  /**
   * FAQ 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerSearchV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerSearchV1(params: FaqControllerSearchV1$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.faqControllerSearchV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `faqControllerFindAllV1()` */
  static readonly FaqControllerFindAllV1Path = '/api/v1/faq';

  /**
   * 전체 FAQ 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerFindAllV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerFindAllV1$Response(params?: FaqControllerFindAllV1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FaqDto>>> {
    return faqControllerFindAllV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 전체 FAQ 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerFindAllV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerFindAllV1(params?: FaqControllerFindAllV1$Params, context?: HttpContext): Observable<Array<FaqDto>> {
    return this.faqControllerFindAllV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaqDto>>): Array<FaqDto> => r.body)
    );
  }

  /** Path part for operation `faqControllerCreateV1()` */
  static readonly FaqControllerCreateV1Path = '/api/v1/faq';

  /**
   * FAQ 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerCreateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqControllerCreateV1$Response(params: FaqControllerCreateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqDto>> {
    return faqControllerCreateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * FAQ 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerCreateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqControllerCreateV1(params: FaqControllerCreateV1$Params, context?: HttpContext): Observable<FaqDto> {
    return this.faqControllerCreateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqDto>): FaqDto => r.body)
    );
  }

  /** Path part for operation `faqControllerFindByIdV1()` */
  static readonly FaqControllerFindByIdV1Path = '/api/v1/faq/{id}';

  /**
   * FAQ 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerFindByIdV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerFindByIdV1$Response(params: FaqControllerFindByIdV1$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqDto>> {
    return faqControllerFindByIdV1(this.http, this.rootUrl, params, context);
  }

  /**
   * FAQ 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerFindByIdV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerFindByIdV1(params: FaqControllerFindByIdV1$Params, context?: HttpContext): Observable<FaqDto> {
    return this.faqControllerFindByIdV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqDto>): FaqDto => r.body)
    );
  }

  /** Path part for operation `faqControllerDeleteV1()` */
  static readonly FaqControllerDeleteV1Path = '/api/v1/faq/{id}';

  /**
   * FAQ 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerDeleteV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerDeleteV1$Response(params: FaqControllerDeleteV1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return faqControllerDeleteV1(this.http, this.rootUrl, params, context);
  }

  /**
   * FAQ 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerDeleteV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerDeleteV1(params: FaqControllerDeleteV1$Params, context?: HttpContext): Observable<void> {
    return this.faqControllerDeleteV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `faqControllerUpdateV1()` */
  static readonly FaqControllerUpdateV1Path = '/api/v1/faq/{id}';

  /**
   * FAQ 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerUpdateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqControllerUpdateV1$Response(params: FaqControllerUpdateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqDto>> {
    return faqControllerUpdateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * FAQ 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerUpdateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqControllerUpdateV1(params: FaqControllerUpdateV1$Params, context?: HttpContext): Observable<FaqDto> {
    return this.faqControllerUpdateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqDto>): FaqDto => r.body)
    );
  }

  /** Path part for operation `faqControllerTogglePinnedV1()` */
  static readonly FaqControllerTogglePinnedV1Path = '/api/v1/faq/{id}/pin';

  /**
   * FAQ 고정/고정해제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerTogglePinnedV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerTogglePinnedV1$Response(params: FaqControllerTogglePinnedV1$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqDto>> {
    return faqControllerTogglePinnedV1(this.http, this.rootUrl, params, context);
  }

  /**
   * FAQ 고정/고정해제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerTogglePinnedV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerTogglePinnedV1(params: FaqControllerTogglePinnedV1$Params, context?: HttpContext): Observable<FaqDto> {
    return this.faqControllerTogglePinnedV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqDto>): FaqDto => r.body)
    );
  }

}
