/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { tagControllerConnectTagToChilds } from '../fn/tag/tag-controller-connect-tag-to-childs';
import { TagControllerConnectTagToChilds$Params } from '../fn/tag/tag-controller-connect-tag-to-childs';
import { tagControllerCreate } from '../fn/tag/tag-controller-create';
import { TagControllerCreate$Params } from '../fn/tag/tag-controller-create';
import { tagControllerFindAll } from '../fn/tag/tag-controller-find-all';
import { TagControllerFindAll$Params } from '../fn/tag/tag-controller-find-all';
import { TagDto } from '../models/tag-dto';

@Injectable({ providedIn: 'root' })
export class TagService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tagControllerFindAll()` */
  static readonly TagControllerFindAllPath = '/api/tag';

  /**
   * 태그 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagControllerFindAll$Response(params: TagControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagDto>>> {
    return tagControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 태그 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tagControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagControllerFindAll(params: TagControllerFindAll$Params, context?: HttpContext): Observable<Array<TagDto>> {
    return this.tagControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagDto>>): Array<TagDto> => r.body)
    );
  }

  /** Path part for operation `tagControllerCreate()` */
  static readonly TagControllerCreatePath = '/api/tag';

  /**
   * 태그 생성.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagControllerCreate$Response(params: TagControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<TagDto>> {
    return tagControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 태그 생성.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tagControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagControllerCreate(params: TagControllerCreate$Params, context?: HttpContext): Observable<TagDto> {
    return this.tagControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagDto>): TagDto => r.body)
    );
  }

  /** Path part for operation `tagControllerConnectTagToChilds()` */
  static readonly TagControllerConnectTagToChildsPath = '/api/tag/connect';

  /**
   * 태그와 아동 연결.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagControllerConnectTagToChilds()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagControllerConnectTagToChilds$Response(params: TagControllerConnectTagToChilds$Params, context?: HttpContext): Observable<StrictHttpResponse<TagDto>> {
    return tagControllerConnectTagToChilds(this.http, this.rootUrl, params, context);
  }

  /**
   * 태그와 아동 연결.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tagControllerConnectTagToChilds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagControllerConnectTagToChilds(params: TagControllerConnectTagToChilds$Params, context?: HttpContext): Observable<TagDto> {
    return this.tagControllerConnectTagToChilds$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagDto>): TagDto => r.body)
    );
  }

}
