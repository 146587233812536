import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  inject,
  viewChild,
} from '@angular/core';
import { ModalAdapter } from '../components';
import { ActionSheetService } from './action-sheet.service';
import { ActionSheetOption } from './action-sheet.type';
import { ActionSheetHeader } from './header/action-sheet-header.component';
import { ActionSheetOptionComponent } from './option/action-sheet-option.component';

@Component({
  selector: 'app-action-sheet',
  standalone: true,
  templateUrl: './action-sheet.component.html',
  imports: [CommonModule, ActionSheetOptionComponent, ActionSheetHeader],
})
export default class ActionSheetComponent
  extends ModalAdapter
  implements AfterViewInit
{
  actionSheetRef = viewChild<ElementRef<HTMLElement>>('actionSheet');
  options = computed<ActionSheetOption[]>(() => this.props()?.options || []);
  title = computed<string>(() => this.props()?.title || '');
  renderItem = computed<any>(() => this.props()?.renderItem || '');
  currentValue = computed<any>(() => this.props()?.currentValue || '');

  readonly actionSheetService = inject(ActionSheetService);

  ngAfterViewInit(): void {
    this.actionSheetRef()?.nativeElement.animate(
      [
        { opacity: 0, transform: 'translateY(10px)' },
        { opacity: 1, transform: 'translateY(0)' },
      ],
      {
        duration: 200,
        easing: 'ease-in-out',
      },
    );
  }

  handleClick(option: ActionSheetOption) {
    this.actionSheetService.close(option.value);

    if (option.handler) {
      option.handler();
    }
  }
}
