/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FileDto } from '../../models/file-dto';

export interface StorageControllerDeleteFile$Params {

/**
 * 파일 URL
 */
  url: string;
}

export function storageControllerDeleteFile(http: HttpClient, rootUrl: string, params: StorageControllerDeleteFile$Params, context?: HttpContext): Observable<StrictHttpResponse<FileDto>> {
  const rb = new RequestBuilder(rootUrl, storageControllerDeleteFile.PATH, 'delete');
  if (params) {
    rb.path('url', params.url, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FileDto>;
    })
  );
}

storageControllerDeleteFile.PATH = '/api/storage/{url}';
