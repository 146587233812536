<div class="flex flex-col gap-2">
  @if (label(); as label) {
    <app-label [required]="required()">{{ label }}</app-label>
  }
  <div class="flex gap-4 text-sm" [class.flex-col]="direction() === 'vertical'">
    @for (option of options(); track $index) {
      <app-radio-option
        (click)="writeValue(option.value)"
        [active]="value() === option.value"
        [label]="option.label"
        [description]="option.description"
        [disabled]="option.disabled!"
      />
    }
  </div>
</div>
