import {
  Component,
  effect,
  inject,
  Injector,
  input,
  model,
  signal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroup,
  NgControl,
  NgModel,
} from '@angular/forms';
import { BaseConfig } from '../../common/config/config.adapter';
import { AutoFormStore } from '../auto-form.store';

@Component({
  selector: 'app-form-field-adapter',
  template: '',
})
export abstract class FormFieldAdapter<T>
  extends BaseConfig
  implements ControlValueAccessor
{
  readonly injector = inject(Injector);
  readonly store = inject(AutoFormStore);

  defaultValues = this.store.defaultValues;
  defaultValues$ = toObservable(this.defaultValues);

  formField = input.required<T>();
  hasError = signal(false);

  formControl: FormControl | null = null;

  value = model<any>();
  value$ = toObservable(this.value);

  constructor() {
    super();

    effect(() => {
      this.onChange(this.value());
    });
  }

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl, null, {
      self: true,
      optional: true,
    });

    if (ngControl instanceof NgModel) {
      this.formControl = ngControl.control as FormControl;
    } else if (ngControl instanceof FormControlDirective) {
      this.formControl = ngControl.control;
    } else if (ngControl instanceof FormControlName) {
      const container = this.injector.get(ControlContainer, null)
        ?.control as FormGroup;
      this.formControl = container.controls[ngControl.name!] as FormControl;
    }
  }

  writeValue(obj: any): void {
    this.value.set(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}

  onChange = (value: any) => {};
  onTouched = () => {};
}
