/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { noticeControllerCreateV1 } from '../fn/notice/notice-controller-create-v-1';
import { NoticeControllerCreateV1$Params } from '../fn/notice/notice-controller-create-v-1';
import { noticeControllerDeleteV1 } from '../fn/notice/notice-controller-delete-v-1';
import { NoticeControllerDeleteV1$Params } from '../fn/notice/notice-controller-delete-v-1';
import { noticeControllerFindAllV1 } from '../fn/notice/notice-controller-find-all-v-1';
import { NoticeControllerFindAllV1$Params } from '../fn/notice/notice-controller-find-all-v-1';
import { noticeControllerFindByIdV1 } from '../fn/notice/notice-controller-find-by-id-v-1';
import { NoticeControllerFindByIdV1$Params } from '../fn/notice/notice-controller-find-by-id-v-1';
import { noticeControllerSearchV1 } from '../fn/notice/notice-controller-search-v-1';
import { NoticeControllerSearchV1$Params } from '../fn/notice/notice-controller-search-v-1';
import { noticeControllerTogglePinnedV1 } from '../fn/notice/notice-controller-toggle-pinned-v-1';
import { NoticeControllerTogglePinnedV1$Params } from '../fn/notice/notice-controller-toggle-pinned-v-1';
import { noticeControllerUpdateV1 } from '../fn/notice/notice-controller-update-v-1';
import { NoticeControllerUpdateV1$Params } from '../fn/notice/notice-controller-update-v-1';
import { NoticeDto } from '../models/notice-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class NoticeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `noticeControllerSearchV1()` */
  static readonly NoticeControllerSearchV1Path = '/api/v1/notice/search';

  /**
   * Notice 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `noticeControllerSearchV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  noticeControllerSearchV1$Response(params: NoticeControllerSearchV1$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return noticeControllerSearchV1(this.http, this.rootUrl, params, context);
  }

  /**
   * Notice 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `noticeControllerSearchV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  noticeControllerSearchV1(params: NoticeControllerSearchV1$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.noticeControllerSearchV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `noticeControllerFindAllV1()` */
  static readonly NoticeControllerFindAllV1Path = '/api/v1/notice';

  /**
   * 모든 Notice 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `noticeControllerFindAllV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  noticeControllerFindAllV1$Response(params?: NoticeControllerFindAllV1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NoticeDto>>> {
    return noticeControllerFindAllV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 Notice 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `noticeControllerFindAllV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  noticeControllerFindAllV1(params?: NoticeControllerFindAllV1$Params, context?: HttpContext): Observable<Array<NoticeDto>> {
    return this.noticeControllerFindAllV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NoticeDto>>): Array<NoticeDto> => r.body)
    );
  }

  /** Path part for operation `noticeControllerCreateV1()` */
  static readonly NoticeControllerCreateV1Path = '/api/v1/notice';

  /**
   * Notice 생성.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `noticeControllerCreateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  noticeControllerCreateV1$Response(params: NoticeControllerCreateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<NoticeDto>> {
    return noticeControllerCreateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * Notice 생성.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `noticeControllerCreateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  noticeControllerCreateV1(params: NoticeControllerCreateV1$Params, context?: HttpContext): Observable<NoticeDto> {
    return this.noticeControllerCreateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoticeDto>): NoticeDto => r.body)
    );
  }

  /** Path part for operation `noticeControllerFindByIdV1()` */
  static readonly NoticeControllerFindByIdV1Path = '/api/v1/notice/{id}';

  /**
   * Notice id로 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `noticeControllerFindByIdV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  noticeControllerFindByIdV1$Response(params: NoticeControllerFindByIdV1$Params, context?: HttpContext): Observable<StrictHttpResponse<NoticeDto>> {
    return noticeControllerFindByIdV1(this.http, this.rootUrl, params, context);
  }

  /**
   * Notice id로 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `noticeControllerFindByIdV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  noticeControllerFindByIdV1(params: NoticeControllerFindByIdV1$Params, context?: HttpContext): Observable<NoticeDto> {
    return this.noticeControllerFindByIdV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoticeDto>): NoticeDto => r.body)
    );
  }

  /** Path part for operation `noticeControllerDeleteV1()` */
  static readonly NoticeControllerDeleteV1Path = '/api/v1/notice/{id}';

  /**
   * Notice 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `noticeControllerDeleteV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  noticeControllerDeleteV1$Response(params: NoticeControllerDeleteV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return noticeControllerDeleteV1(this.http, this.rootUrl, params, context);
  }

  /**
   * Notice 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `noticeControllerDeleteV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  noticeControllerDeleteV1(params: NoticeControllerDeleteV1$Params, context?: HttpContext): Observable<boolean> {
    return this.noticeControllerDeleteV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `noticeControllerUpdateV1()` */
  static readonly NoticeControllerUpdateV1Path = '/api/v1/notice/{id}';

  /**
   * Notice 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `noticeControllerUpdateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  noticeControllerUpdateV1$Response(params: NoticeControllerUpdateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<NoticeDto>> {
    return noticeControllerUpdateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * Notice 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `noticeControllerUpdateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  noticeControllerUpdateV1(params: NoticeControllerUpdateV1$Params, context?: HttpContext): Observable<NoticeDto> {
    return this.noticeControllerUpdateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoticeDto>): NoticeDto => r.body)
    );
  }

  /** Path part for operation `noticeControllerTogglePinnedV1()` */
  static readonly NoticeControllerTogglePinnedV1Path = '/api/v1/notice/{id}/pin';

  /**
   * 공지사항 고정/고정해제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `noticeControllerTogglePinnedV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  noticeControllerTogglePinnedV1$Response(params: NoticeControllerTogglePinnedV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return noticeControllerTogglePinnedV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 공지사항 고정/고정해제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `noticeControllerTogglePinnedV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  noticeControllerTogglePinnedV1(params: NoticeControllerTogglePinnedV1$Params, context?: HttpContext): Observable<boolean> {
    return this.noticeControllerTogglePinnedV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
