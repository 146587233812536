import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Directive,
  inject,
  viewChild,
  ViewContainerRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Label } from '../../../label/label.component';
import { FormFieldAdapter } from '../../adapters/form-field.adapter';
import { CustomFormField } from '../../auto-form.type';

@Directive({
  selector: '[customForm]',
  standalone: true,
})
export class CustomFormDirective {
  readonly viewContainerRef = inject(ViewContainerRef);
}

@Component({
  selector: 'app-custom-form-field',
  templateUrl: './custom-form-field.component.html',
  imports: [CommonModule, CustomFormDirective, Label],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomFormFieldComponent,
      multi: true,
    },
  ],
})
export class CustomFormFieldComponent
  extends FormFieldAdapter<CustomFormField<any>>
  implements AfterViewInit
{
  changeRef = inject(ChangeDetectorRef);
  customForm = viewChild(CustomFormDirective);

  ngAfterViewInit(): void {
    const field = this.formField();

    if (this.customForm() && field.type === 'custom' && field.renderItem) {
      const viewContainerRef = this.customForm()?.viewContainerRef;
      viewContainerRef?.clear();

      const componentRef = viewContainerRef?.createComponent<
        FormFieldAdapter<CustomFormField<any>>
      >(field.renderItem);

      if (componentRef && this.formField()) {
        componentRef.setInput('formField', this.formField());
        this.changeRef.detectChanges();
      }

      if (componentRef?.instance.value$) {
        componentRef.instance.value$.subscribe((value) => {
          this.writeValue(value);
          this.onChange(value);
        });
      }
    }
  }
}
