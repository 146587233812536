/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { oauthControllerDisconnect } from '../fn/o-auth/oauth-controller-disconnect';
import { OauthControllerDisconnect$Params } from '../fn/o-auth/oauth-controller-disconnect';
import { oauthControllerSignUp } from '../fn/o-auth/oauth-controller-sign-up';
import { OauthControllerSignUp$Params } from '../fn/o-auth/oauth-controller-sign-up';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class OAuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `oauthControllerSignUp()` */
  static readonly OauthControllerSignUpPath = '/api/oauth/signup';

  /**
   * 소셜 회원가입.
   *
   * 소셜 회원가입을 진행합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oauthControllerSignUp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  oauthControllerSignUp$Response(params: OauthControllerSignUp$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return oauthControllerSignUp(this.http, this.rootUrl, params, context);
  }

  /**
   * 소셜 회원가입.
   *
   * 소셜 회원가입을 진행합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oauthControllerSignUp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  oauthControllerSignUp(params: OauthControllerSignUp$Params, context?: HttpContext): Observable<UserDto> {
    return this.oauthControllerSignUp$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `oauthControllerDisconnect()` */
  static readonly OauthControllerDisconnectPath = '/api/oauth/{id}/disconnect';

  /**
   * 소셜 계정 연결 해제.
   *
   * 소셜 계정을 연결 해제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oauthControllerDisconnect()` instead.
   *
   * This method doesn't expect any request body.
   */
  oauthControllerDisconnect$Response(params: OauthControllerDisconnect$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return oauthControllerDisconnect(this.http, this.rootUrl, params, context);
  }

  /**
   * 소셜 계정 연결 해제.
   *
   * 소셜 계정을 연결 해제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oauthControllerDisconnect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  oauthControllerDisconnect(params: OauthControllerDisconnect$Params, context?: HttpContext): Observable<UserDto> {
    return this.oauthControllerDisconnect$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

}
