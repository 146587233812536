/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { settingControllerGetSettings } from '../fn/setting/setting-controller-get-settings';
import { SettingControllerGetSettings$Params } from '../fn/setting/setting-controller-get-settings';
import { settingControllerSetSettings } from '../fn/setting/setting-controller-set-settings';
import { SettingControllerSetSettings$Params } from '../fn/setting/setting-controller-set-settings';
import { SettingDto } from '../models/setting-dto';

@Injectable({ providedIn: 'root' })
export class SettingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `settingControllerGetSettings()` */
  static readonly SettingControllerGetSettingsPath = '/api/setting';

  /**
   * 설정값을 조회.
   *
   * 설정값을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingControllerGetSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingControllerGetSettings$Response(params?: SettingControllerGetSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SettingDto>>> {
    return settingControllerGetSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * 설정값을 조회.
   *
   * 설정값을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingControllerGetSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingControllerGetSettings(params?: SettingControllerGetSettings$Params, context?: HttpContext): Observable<Array<SettingDto>> {
    return this.settingControllerGetSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SettingDto>>): Array<SettingDto> => r.body)
    );
  }

  /** Path part for operation `settingControllerSetSettings()` */
  static readonly SettingControllerSetSettingsPath = '/api/setting';

  /**
   * 설정값을 변경.
   *
   * 설정값을 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingControllerSetSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settingControllerSetSettings$Response(params: SettingControllerSetSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return settingControllerSetSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * 설정값을 변경.
   *
   * 설정값을 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingControllerSetSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settingControllerSetSettings(params: SettingControllerSetSettings$Params, context?: HttpContext): Observable<void> {
    return this.settingControllerSetSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
