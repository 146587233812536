@if (src()) {
  <img [ngClass]="currentSize()" [src]="src()" class="rounded-full border" />
} @else {
  <div
    class="flex items-center justify-center bg-gray-100 rounded-full"
    [ngClass]="currentSize()"
  >
    <app-icon
      [size]="size()"
      name="heroicons:user-16-solid"
      class="text-gray-300"
    />
  </div>
}
