<label
  class="theme-border"
  [class.active]="value()"
  [attr.data-size]="currentSize()"
  [attr.data-theme]="theme()"
>
  <input
    type="checkbox"
    class="hidden"
    [(ngModel)]="value"
    (change)="handleChange($event)"
    [disabled]="disabled()"
  />
  <div
    [attr.data-size]="currentSize()"
    class="circle"
    [class.active]="value()"
  ></div>
</label>
