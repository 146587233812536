import { runInInjectionContext, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, NavigationEnd } from '@angular/router';
import { assertInjector } from 'ngxtension/assert-injector';
import { filter } from 'rxjs';

/**
 * Creates an Observable that emits when a navigation ends.
 * @returns An Observable of NavigationEnd events.
 */
function injectNavigationEnd(injector) {
  injector = assertInjector(injectNavigationEnd, injector);
  return runInInjectionContext(injector, () => {
    return inject(Router).events.pipe(filter(event => event instanceof NavigationEnd), takeUntilDestroyed());
  });
}

/**
 * Generated bundle index. Do not edit.
 */

export { injectNavigationEnd };
