import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Input } from '../../../input/input.component';
import { InputFormFieldAdapter } from '../../adapters/input-form-field.adapter';
import { TextFormField } from '../../auto-form.type';
import { ErrorDirective } from '../../directives/error.directive';

@Component({
  selector: 'app-text-form-field',
  standalone: true,
  imports: [Input, ErrorDirective],
  templateUrl: './text-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextFormFieldComponent,
      multi: true,
    },
  ],
})
export class TextFormFieldComponent extends InputFormFieldAdapter<
  TextFormField<any>
> {}
