import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  computed,
  HostListener,
  inject,
  input,
  output,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { BaseConfig } from '../../common/config/config.adapter';
import { SelectStore } from '../select.store';

export type SelectOutput = {
  value: any;
  label: string;
};

@Component({
  selector: 'app-select-option-adapter',
  template: ``,
  standalone: true,
  imports: [CommonModule],
})
export class SelectOptionAdapter<T> extends BaseConfig {
  readonly store = inject(SelectStore);

  @HostListener('click') onClick() {
    this.select.emit({
      label: this.label(),
      value: this.value(),
    });
  }

  currentValue = this.store.currentValue; // 부모 컴포넌트의 value
  bindLabel = this.store.bindLabel;
  bindValue = this.store.bindValue;

  // 아이템이 선택되었을 때 발생하는 이벤트
  select = output<SelectOutput>();

  // 부모 컴포넌트의 keyboard 이벤트가 css hover이벤트와 따로 동작해서 추가함
  mouseEnter = output<void>();
  mouseLeave = output<void>();

  option = input<T>();

  // 포커스 상태(키보드 이벤트 시 사용)
  focus = input<boolean>(false);
  focus$ = toObservable(this.focus);

  renderItem = input<any>();
  multiple = input<boolean, string>(false, { transform: booleanAttribute });

  $label = input<string>('', { alias: 'label' });
  $value = input<any>(null, { alias: 'value' });
  $active = input<boolean>(false, { alias: 'active' });

  label = computed(() => this.$label() || this.store.getLabel(this.option()));
  value = computed(() => this.$value() || this.store.getValue(this.option()));
  active = computed<boolean>(
    () => this.$active() || this.currentValue() === this.value(),
  );
}
