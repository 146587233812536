<section class="flex flex-col gap-6">
  <form
    [formGroup]="form"
    (ngSubmit)="handleSubmit()"
    class="grid w-full h-full gap-6"
    [ngStyle]="{
      'grid-template-columns':
        'repeat(' + (options()?.ui?.grid || 1) + ', 1fr)',
    }"
  >
    @for (data of schema(); track $index) {
      @if (data && data.active !== false) {
        <div class="flex flex-col gap-2">
          @switch (data.type) {
            @case ('text') {
              <app-text-form-field
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
            @case ('address') {
              <app-address-form-field
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
            @case ('number') {
              <app-number-form-field
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
            @case ('email') {
              <app-email-form-field
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
            @case ('textarea') {
              <app-textarea-form-field
                class="col-span-2"
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
            @case ('password') {
              <app-password-form-field
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
            @case ('select') {
              <app-select-form-field
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
            @case ('radio') {
              <app-radio-form-field
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
            @case ('checkbox') {
              <app-checkbox-form-field
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
            @case ('toggle') {
              <app-toggle-form-field
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
            @case ('date') {
              <app-date-form-field
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
            @case ('file') {
              <app-file-form-field
                [formField]="data"
                [formControlName]="data.field | fieldName"
              />
            }
            @case ('custom') {
              <app-custom-form-field
                [class.col-span-2]="data.expand"
                [formControlName]="data.field | fieldName"
                [formField]="data"
              />
            }
          }

          @if (data.hint) {
            <app-hint>{{ data.hint }}</app-hint>
          }
        </div>
      }
    }
  </form>
  @if (options()?.ui?.showSubmitButton) {
    @if (!options()?.ui?.type || options()?.ui?.type === 'page') {
      <div class="flex justify-between w-full">
        <app-button routerLink="..">목록</app-button>
        <app-button (click)="handleSubmit()" [disabled]="invalid()!"
          >전송</app-button
        >
      </div>
    } @else {
      <div class="flex items-center w-full gap-3">
        <app-button
          expand
          color="white"
          variant="outline"
          (click)="close.emit()"
          >취소</app-button
        >
        <app-button expand (click)="handleSubmit()" [disabled]="invalid()!"
          >전송</app-button
        >
      </div>
    }
  }
</section>
