import { isDevMode, assertInInjectionContext, isSignal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
function toObservableSignal(source, options) {
  if (isDevMode() && !options?.injector) {
    assertInInjectionContext(toObservableSignal);
  }
  let s;
  let obs;
  if (isSignal(source)) {
    s = source;
    obs = toObservable(source, options);
  } else {
    obs = source;
    s = toSignal(obs, {
      injector: options?.injector
    });
  }
  return new Proxy(s, {
    get(_, prop) {
      if (prop in s) {
        return s[prop];
      }
      return obs[prop];
    }
  });
}

/**
 * Generated bundle index. Do not edit.
 */

export { toObservableSignal };
