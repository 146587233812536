@if (formField(); as field) {
  <app-input-address
    [color]="hasError() ? 'red' : 'primary'"
    [label]="field.label!"
    [(value)]="value"
    [disabled]="field.disabled!"
    [required]="field.required ? 'true' : 'false'"
  />
  <p
    formError
    [formControl]="formControl!"
    (hasError)="hasError.set($event)"
  ></p>
}
