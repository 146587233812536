<div class="relative" (clickOutside)="open.set(false)">
  <app-input
    [color]="currentColor()"
    [size]="currentSize()"
    [rounded]="currentRounded()"
    [label]="label()"
    list="emails"
    [required]="required()"
    [placeholder]="placeholder()"
    [spellcheck]="spellcheck()"
    [autocomplete]="autocomplete()"
    [autofocus]="autofocus()"
    [readonly]="readonly()"
    [disabled]="disabled()"
    [(value)]="value"
    (inputKeydown)="handleKeydown($event)"
    (inputFocus)="handleFoucs()"
    (inputBlur)="open.set(false)"
    inputmode="email"
  />
  @if (!readonly() && open() && has()) {
    <datalist id="emails">
      @for (domain of domains; track domain) {
        <option value="{{ currentValue() }}{{ '@' }}{{ domain }}"></option>
      }
    </datalist>
  }
</div>
