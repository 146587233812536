import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '@malirang/api-client';
import { ADMIN_ACCESS_TOKEN_KEY } from '@malirang/common';
import { LocalStorageService } from '@malirang/front-share';
import { AdminStore } from '../stores/admin.store';

export const AuthGuard = () => {
  const localStorageService = inject(LocalStorageService);
  const adminService = inject(AdminService);
  const adminStore = inject(AdminStore);
  const accessToken = localStorageService.get(ADMIN_ACCESS_TOKEN_KEY);
  const router = inject(Router);

  if (!accessToken) {
    router.navigate(['/login']);
    return false;
  }

  adminService.adminControllerMe().subscribe({
    next: (res) => {
      adminStore.setAdmin(res);
    },
  });

  return true;
};
