import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import dayjs, { Dayjs } from 'dayjs';
import { BaseStore } from 'libs/front-share/src/stores/base.store';
import { map } from 'rxjs';

export type InputCalendarMode = 'pick' | 'range';

type State = {
  mode: InputCalendarMode;
  days: Dayjs[];
  value: Date;
  start: Date | null;
  end: Date | null;
};

@Injectable()
export class InputCalendarStore extends BaseStore<State> {
  readonly days$ = this.state$.pipe(map((state) => state.days));
  readonly value$ = this.state$.pipe(map((state) => state.value));
  readonly start$ = this.state$.pipe(map((state) => state.start));
  readonly end$ = this.state$.pipe(map((state) => state.end));
  readonly mode$ = this.state$.pipe(map((state) => state.mode));

  readonly days = toSignal(this.days$);
  readonly value = toSignal(this.value$);
  readonly start = toSignal(this.start$);
  readonly end = toSignal(this.end$);
  readonly mode = toSignal(this.mode$);

  readonly months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  constructor() {
    super({
      mode: 'pick',
      days: [],
      value: new Date(),
      start: null,
      end: null,
    });
  }

  setValue(value: Date) {
    this.updateState({ value });
    this.generateDays();
  }

  setStart(start: Date | null) {
    this.updateState({ start });
  }

  setEnd(end: Date | null) {
    this.updateState({ end });
    this.handleRange();
  }

  setDays(days: Dayjs[]) {
    this.updateState({ days });
  }

  setMode(mode: InputCalendarMode) {
    this.updateState({ mode });
  }

  private handleRange() {
    const { start, end } = this.get();
    if (start && end) {
      const [_start, _end] = [dayjs(start), dayjs(end)];

      if (_start.isAfter(_end)) {
        this.updateState({ start: end, end: start });
      }
    }
  }

  private generateDays() {
    const days: Dayjs[] = [];
    const current = dayjs(this.value());

    const firstDayOfMonth = current.startOf('month');
    const lastDayOfMonth = current.endOf('month');
    const daysInMonth = current.daysInMonth();

    // Add days from the previous month to fill the first week
    for (let i = firstDayOfMonth.day() - 1; i >= 0; i--) {
      days.push(firstDayOfMonth.subtract(i + 1, 'day'));
    }

    // Add days of the current month
    for (let i = 0; i < daysInMonth; i++) {
      days.push(firstDayOfMonth.add(i, 'day'));
    }

    // Add days from the next month to fill the last week
    for (let i = 1; i <= 6 - lastDayOfMonth.day(); i++) {
      days.push(lastDayOfMonth.add(i, 'day'));
    }

    this.setDays(days);
  }
}
