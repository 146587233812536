@if (formField(); as field) {
  <app-select
    [color]="hasError() ? 'red' : 'primary'"
    [label]="field.label"
    [(value)]="value"
    [required]="field.required!"
    [placeholder]="_placeholder()"
    [options]="field.options"
    [renderItem]="field.renderItem"
    [readonly]="field.readonly!"
    [disabled]="field.disabled!"
    [search]="field.search ? 'true' : 'false'"
  ></app-select>
  <p
    formError
    [formControl]="formControl!"
    (hasError)="hasError.set($event)"
  ></p>
}
