export const OAuthProvider = {
  NAVER: '네이버',
  KAKAO: '카카오',
  GOOGLE: '구글',
  APPLE: '애플',
  EMAIL: '이메일',
} as const;

export type OAuthProvider = keyof typeof OAuthProvider;

export const OAuthResponseState = {
  CONFLICT: 'CONFLICT', // 이미 가입된 회원
  SUCCESS: 'SUCCESS', // 로그인 성공
  FAIL: 'FAIL', // 로그인 실패
  SIGNUP: 'SIGNUP', // 회원가입 필요
} as const;
