@if (column()?.type === 'checkbox') {
  <app-checkbox-column [(selecteds)]="selecteds" />
} @else {
  <div class="relative flex items-center gap-2 w-full justify-between group">
    <div>
      {{ column()?.name }}
    </div>

    @if (!column()?.filter?.disabled) {
      @if (showHeaderOption()) {
        @if (column()?.field !== 'rowNumber') {
          <app-icon-button
            [cdkMenuTriggerFor]="menu"
            [attr.data-theme]="theme()"
            class="theme-text"
            icon="mdi:dots-vertical"
            size="sm"
          />
        }
      }
    }
  </div>
}

<ng-template #menu>
  <app-menu cdkMenu>
    <app-menu-option
      cdkMenuItem
      (click)="handleOrderBy('desc')"
      icon="ic:outline-arrow-downward"
      [active]="orderBy() === this.column()?.field && align() === 'desc'"
      >내림차순</app-menu-option
    >
    <app-menu-option
      cdkMenuItem
      (click)="handleOrderBy('asc')"
      icon="ic:outline-arrow-upward"
      [active]="orderBy() === this.column()?.field && align() === 'asc'"
      >오름차순</app-menu-option
    >
    <app-divider class="my-2" />
    <app-menu-option
      (click)="handleGroupBy()"
      cdkMenuItem
      icon="material-symbols:groups-2"
    >
      그룹화
    </app-menu-option>
  </app-menu>
</ng-template>
