import { Pipe } from '@angular/core';

@Pipe({
  name: 'contact',
  standalone: true,
})
export class ContactPipe {
  transform(value: string): string {
    if (!value) return '-';

    return value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }
}
