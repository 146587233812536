/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { notificationControllerAdminCreateNotificationsV1 } from '../fn/notification/notification-controller-admin-create-notifications-v-1';
import { NotificationControllerAdminCreateNotificationsV1$Params } from '../fn/notification/notification-controller-admin-create-notifications-v-1';
import { notificationControllerCountNewNotificationV1 } from '../fn/notification/notification-controller-count-new-notification-v-1';
import { NotificationControllerCountNewNotificationV1$Params } from '../fn/notification/notification-controller-count-new-notification-v-1';
import { notificationControllerSearchV1 } from '../fn/notification/notification-controller-search-v-1';
import { NotificationControllerSearchV1$Params } from '../fn/notification/notification-controller-search-v-1';
import { notificationControllerSendNotificationV1 } from '../fn/notification/notification-controller-send-notification-v-1';
import { NotificationControllerSendNotificationV1$Params } from '../fn/notification/notification-controller-send-notification-v-1';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class NotificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `notificationControllerSendNotificationV1()` */
  static readonly NotificationControllerSendNotificationV1Path = '/api/v1/notification/send';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationControllerSendNotificationV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerSendNotificationV1$Response(params?: NotificationControllerSendNotificationV1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationControllerSendNotificationV1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationControllerSendNotificationV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerSendNotificationV1(params?: NotificationControllerSendNotificationV1$Params, context?: HttpContext): Observable<void> {
    return this.notificationControllerSendNotificationV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationControllerSearchV1()` */
  static readonly NotificationControllerSearchV1Path = '/api/v1/notification/search';

  /**
   * 알림 검색.
   *
   * 회원이 검색할 경우 내 알림을 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationControllerSearchV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerSearchV1$Response(params: NotificationControllerSearchV1$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return notificationControllerSearchV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 알림 검색.
   *
   * 회원이 검색할 경우 내 알림을 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationControllerSearchV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerSearchV1(params: NotificationControllerSearchV1$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.notificationControllerSearchV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `notificationControllerCountNewNotificationV1()` */
  static readonly NotificationControllerCountNewNotificationV1Path = '/api/v1/notification/count';

  /**
   * 새로운 알림 개수 조회.
   *
   * 회원이 조회할 경우 새로운 알림 개수를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationControllerCountNewNotificationV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerCountNewNotificationV1$Response(params?: NotificationControllerCountNewNotificationV1$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return notificationControllerCountNewNotificationV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 새로운 알림 개수 조회.
   *
   * 회원이 조회할 경우 새로운 알림 개수를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationControllerCountNewNotificationV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerCountNewNotificationV1(params?: NotificationControllerCountNewNotificationV1$Params, context?: HttpContext): Observable<number> {
    return this.notificationControllerCountNewNotificationV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `notificationControllerAdminCreateNotificationsV1()` */
  static readonly NotificationControllerAdminCreateNotificationsV1Path = '/api/v1/notification';

  /**
   * 알림 생성.
   *
   * 관리자가 알림을 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationControllerAdminCreateNotificationsV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationControllerAdminCreateNotificationsV1$Response(params: NotificationControllerAdminCreateNotificationsV1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationControllerAdminCreateNotificationsV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 알림 생성.
   *
   * 관리자가 알림을 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationControllerAdminCreateNotificationsV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationControllerAdminCreateNotificationsV1(params: NotificationControllerAdminCreateNotificationsV1$Params, context?: HttpContext): Observable<void> {
    return this.notificationControllerAdminCreateNotificationsV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
