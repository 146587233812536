var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};

// src/_internal/constants.ts
var _JASO_HANGUL_NFD = [..."\uAC01\uD7A3".normalize("NFD")].map(char => char.charCodeAt(0));
var COMPLETE_HANGUL_START_CHARCODE = "\uAC00".charCodeAt(0);
var COMPLETE_HANGUL_END_CHARCODE = "\uD7A3".charCodeAt(0);
var NUMBER_OF_JONGSEONG = 28;
var NUMBER_OF_JUNGSEONG = 21;
var DISASSEMBLED_CONSONANTS_BY_CONSONANT = {
  // 종성이 없는 경우 '빈' 초성으로 관리하는 것이 편리하여, 빈 문자열도 포함한다.
  "": "",
  ㄱ: "\u3131",
  ㄲ: "\u3132",
  ㄳ: "\u3131\u3145",
  ㄴ: "\u3134",
  ㄵ: "\u3134\u3148",
  ㄶ: "\u3134\u314E",
  ㄷ: "\u3137",
  ㄸ: "\u3138",
  ㄹ: "\u3139",
  ㄺ: "\u3139\u3131",
  ㄻ: "\u3139\u3141",
  ㄼ: "\u3139\u3142",
  ㄽ: "\u3139\u3145",
  ㄾ: "\u3139\u314C",
  ㄿ: "\u3139\u314D",
  ㅀ: "\u3139\u314E",
  ㅁ: "\u3141",
  ㅂ: "\u3142",
  ㅃ: "\u3143",
  ㅄ: "\u3142\u3145",
  ㅅ: "\u3145",
  ㅆ: "\u3146",
  ㅇ: "\u3147",
  ㅈ: "\u3148",
  ㅉ: "\u3149",
  ㅊ: "\u314A",
  ㅋ: "\u314B",
  ㅌ: "\u314C",
  ㅍ: "\u314D",
  ㅎ: "\u314E"
};
var DISASSEMBLED_VOWELS_BY_VOWEL = {
  ㅏ: "\u314F",
  ㅐ: "\u3150",
  ㅑ: "\u3151",
  ㅒ: "\u3152",
  ㅓ: "\u3153",
  ㅔ: "\u3154",
  ㅕ: "\u3155",
  ㅖ: "\u3156",
  ㅗ: "\u3157",
  ㅘ: "\u3157\u314F",
  ㅙ: "\u3157\u3150",
  ㅚ: "\u3157\u3163",
  ㅛ: "\u315B",
  ㅜ: "\u315C",
  ㅝ: "\u315C\u3153",
  ㅞ: "\u315C\u3154",
  ㅟ: "\u315C\u3163",
  ㅠ: "\u3160",
  ㅡ: "\u3161",
  ㅢ: "\u3161\u3163",
  ㅣ: "\u3163"
};
var CHOSEONGS = ["\u3131", "\u3132", "\u3134", "\u3137", "\u3138", "\u3139", "\u3141", "\u3142", "\u3143", "\u3145", "\u3146", "\u3147", "\u3148", "\u3149", "\u314A", "\u314B", "\u314C", "\u314D", "\u314E"];
var JUNSEONGS = Object.values(DISASSEMBLED_VOWELS_BY_VOWEL);
var JONGSEONGS = ["", "\u3131", "\u3132", "\u3133", "\u3134", "\u3135", "\u3136", "\u3137", "\u3139", "\u313A", "\u313B", "\u313C", "\u313D", "\u313E", "\u313F", "\u3140", "\u3141", "\u3142", "\u3144", "\u3145", "\u3146", "\u3147", "\u3148", "\u314A", "\u314B", "\u314C", "\u314D", "\u314E"].map(consonant => DISASSEMBLED_CONSONANTS_BY_CONSONANT[consonant]);
var HANGUL_DIGITS = ["", "\uB9CC", "\uC5B5", "\uC870", "\uACBD", "\uD574", "\uC790", "\uC591", "\uAD6C", "\uAC04", "\uC815", "\uC7AC", "\uADF9", "\uD56D\uD558\uC0AC", "\uC544\uC2B9\uAE30", "\uB098\uC720\uD0C0", "\uBD88\uAC00\uC0AC\uC758", "\uBB34\uB7C9\uB300\uC218", "\uAC81", "\uC5C5"];
var HANGUL_DIGITS_MAX = HANGUL_DIGITS.length * 4;
var HANGUL_NUMBERS = ["", "\uC77C", "\uC774", "\uC0BC", "\uC0AC", "\uC624", "\uC721", "\uCE60", "\uD314", "\uAD6C"];
var HANGUL_NUMBERS_FOR_DECIMAL = ["\uC601", "\uC77C", "\uC774", "\uC0BC", "\uC0AC", "\uC624", "\uC721", "\uCE60", "\uD314", "\uAD6C"];
var HANGUL_CARDINAL = ["", "\uC2ED", "\uBC31", "\uCC9C"];

// src/amountToHangul/amountToHangul.ts
function amountToHangul(amount) {
  const [rawIntegerPart, rawDecimalPart] = String(amount).replace(/[^\d.]+/g, "").split(".");
  const integerPart = rawIntegerPart !== "0" ? rawIntegerPart.replace(/^0+/, "") : rawIntegerPart;
  if (integerPart.length > HANGUL_DIGITS_MAX) {
    throw new Error(`convert range exceeded : ${amount}`);
  }
  const decimalPart = rawDecimalPart == null ? void 0 : rawDecimalPart.replace(/0+$/, "");
  const result = [];
  let pronunDigits = true;
  if (integerPart === "0" || integerPart === "" && rawDecimalPart) {
    result.push(HANGUL_NUMBERS_FOR_DECIMAL[0]);
  } else {
    for (let i = 0; i < integerPart.length - 1; i++) {
      const digit = integerPart.length - i - 1;
      if (integerPart[i] > "1" || digit % 4 === 0 || i === 0) {
        const hangulNumber = HANGUL_NUMBERS[Number(integerPart[i])];
        if (hangulNumber) {
          result.push(hangulNumber);
          pronunDigits = true;
        }
      }
      if (pronunDigits && digit % 4 === 0) {
        result.push(HANGUL_DIGITS[digit / 4]);
        pronunDigits = false;
      }
      if (integerPart[i] !== "0") {
        result.push(HANGUL_CARDINAL[digit % 4]);
      }
    }
    result.push(HANGUL_NUMBERS[Number(integerPart[integerPart.length - 1])]);
  }
  if (decimalPart) {
    result.push("\uC810");
    for (let i = 0; i < decimalPart.length; i++) {
      result.push(HANGUL_NUMBERS_FOR_DECIMAL[Number(decimalPart[i])]);
    }
  }
  return result.join("");
}

// src/_internal/index.ts
function excludeLastElement(array) {
  const lastElement = array[array.length - 1];
  return [array.slice(0, -1), lastElement != null ? lastElement : ""];
}
function joinString(...args) {
  return args.join("");
}
function isBlank(character) {
  return /^\s$/.test(character);
}
function assert(condition, errorMessage) {
  if (condition === false) {
    throw new Error(errorMessage != null ? errorMessage : "Invalid condition");
  }
}
function isNotUndefined(value) {
  return value !== void 0;
}
function arrayIncludes(array, item, fromIndex) {
  return array.includes(item, fromIndex);
}
function hasValueInReadOnlyStringList(list, value) {
  return list.some(item => item === value);
}
function hasProperty(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

// src/disassembleCompleteCharacter/disassembleCompleteCharacter.ts
function disassembleCompleteCharacter(letter) {
  const charCode = letter.charCodeAt(0);
  const isCompleteHangul = COMPLETE_HANGUL_START_CHARCODE <= charCode && charCode <= COMPLETE_HANGUL_END_CHARCODE;
  if (!isCompleteHangul) {
    return void 0;
  }
  const hangulCode = charCode - COMPLETE_HANGUL_START_CHARCODE;
  const jongseongIndex = hangulCode % NUMBER_OF_JONGSEONG;
  const jungseongIndex = (hangulCode - jongseongIndex) / NUMBER_OF_JONGSEONG % NUMBER_OF_JUNGSEONG;
  const choseongIndex = Math.floor((hangulCode - jongseongIndex) / NUMBER_OF_JONGSEONG / NUMBER_OF_JUNGSEONG);
  return {
    choseong: CHOSEONGS[choseongIndex],
    jungseong: JUNSEONGS[jungseongIndex],
    jongseong: JONGSEONGS[jongseongIndex]
  };
}

// src/disassembleToGroups/disassembleToGroups.ts
function disassembleToGroups(str) {
  const result = [];
  for (const letter of str) {
    const disassembledComplete = disassembleCompleteCharacter(letter);
    if (disassembledComplete != null) {
      result.push([...disassembledComplete.choseong, ...disassembledComplete.jungseong, ...disassembledComplete.jongseong]);
      continue;
    }
    if (hasProperty(DISASSEMBLED_CONSONANTS_BY_CONSONANT, letter)) {
      const disassembledConsonant = DISASSEMBLED_CONSONANTS_BY_CONSONANT[letter];
      result.push([...disassembledConsonant]);
      continue;
    }
    if (hasProperty(DISASSEMBLED_VOWELS_BY_VOWEL, letter)) {
      const disassembledVowel = DISASSEMBLED_VOWELS_BY_VOWEL[letter];
      result.push([...disassembledVowel]);
      continue;
    }
    result.push([letter]);
  }
  return result;
}

// src/disassemble/disassemble.ts
function disassemble(str) {
  return disassembleToGroups(str).reduce((hanguls, disassembleds) => `${hanguls}${disassembleds.join("")}`, "");
}

// src/canBeChoseong/canBeChoseong.ts
function canBeChoseong(character) {
  return hasValueInReadOnlyStringList(CHOSEONGS, character);
}

// src/canBeJongseong/canBeJongseong.ts
function canBeJongseong(character) {
  return hasValueInReadOnlyStringList(JONGSEONGS, character);
}

// src/canBeJungseong/canBeJungseong.ts
function canBeJungseong(character) {
  return hasValueInReadOnlyStringList(JUNSEONGS, character);
}

// src/combineVowels/combineVowels.ts
function combineVowels(vowel1, vowel2) {
  var _a, _b;
  return (_b = (_a = Object.entries(DISASSEMBLED_VOWELS_BY_VOWEL).find(([, value]) => value === `${vowel1}${vowel2}`)) == null ? void 0 : _a[0]) != null ? _b : `${vowel1}${vowel2}`;
}

// src/combineCharacter/combineCharacter.ts
function combineCharacter(choseong, jungseong, jongseong = "") {
  if (canBeChoseong(choseong) === false || canBeJungseong(jungseong) === false || canBeJongseong(jongseong) === false) {
    throw new Error(`Invalid hangul Characters: ${choseong}, ${jungseong}, ${jongseong}`);
  }
  const numOfJungseongs = JUNSEONGS.length;
  const numOfJongseongs = JONGSEONGS.length;
  const choseongIndex = CHOSEONGS.indexOf(choseong);
  const jungseongIndex = JUNSEONGS.indexOf(jungseong);
  const jongseongIndex = JONGSEONGS.indexOf(jongseong);
  const choseongOfTargetConsonant = choseongIndex * numOfJungseongs * numOfJongseongs;
  const choseongOfTargetVowel = jungseongIndex * numOfJongseongs;
  const unicode = COMPLETE_HANGUL_START_CHARCODE + choseongOfTargetConsonant + choseongOfTargetVowel + jongseongIndex;
  return String.fromCharCode(unicode);
}

// src/hasBatchim/hasBatchim.ts
function hasBatchim(str, options) {
  const lastChar = str[str.length - 1];
  if (lastChar == null) {
    return false;
  }
  const charCode = lastChar.charCodeAt(0);
  const isNotCompleteHangul = charCode < COMPLETE_HANGUL_START_CHARCODE || charCode > COMPLETE_HANGUL_END_CHARCODE;
  if (isNotCompleteHangul) {
    return false;
  }
  const batchimCode = (charCode - COMPLETE_HANGUL_START_CHARCODE) % NUMBER_OF_JONGSEONG;
  const batchimLength = JONGSEONGS[batchimCode].length;
  switch (options == null ? void 0 : options.only) {
    case "single":
      {
        return batchimLength === 1;
      }
    case "double":
      {
        return batchimLength === 2;
      }
    default:
      {
        return batchimCode > 0;
      }
  }
}

// src/removeLastCharacter/removeLastCharacter.ts
function removeLastCharacter(words) {
  const lastCharacter = words[words.length - 1];
  if (lastCharacter == null) {
    return "";
  }
  const result = (() => {
    const disassembleLastCharacter = disassembleToGroups(lastCharacter);
    const [lastCharacterWithoutLastAlphabet] = excludeLastElement(disassembleLastCharacter[0]);
    if (lastCharacterWithoutLastAlphabet.length <= 3) {
      const [first, middle, last] = lastCharacterWithoutLastAlphabet;
      if (middle != null) {
        return canBeJungseong(last) ? combineCharacter(first, `${middle}${last}`) : combineCharacter(first, middle, last);
      }
      return first;
    } else {
      const [first, firstJungsung, secondJungsung, firstJongsung] = lastCharacterWithoutLastAlphabet;
      return combineCharacter(first, `${firstJungsung}${secondJungsung}`, firstJongsung);
    }
  })();
  return [words.substring(0, words.length - 1), result].join("");
}

// src/_internal/hangul.ts
function isHangulCharacter(character) {
  return /^[가-힣]$/.test(character);
}
function isHangulAlphabet(character) {
  return /^[ㄱ-ㅣ]$/.test(character);
}
function binaryAssembleAlphabets(source, nextCharacter) {
  if (canBeJungseong(`${source}${nextCharacter}`)) {
    return combineVowels(source, nextCharacter);
  }
  const isConsonantSource = canBeJungseong(source) === false;
  if (isConsonantSource && canBeJungseong(nextCharacter)) {
    return combineCharacter(source, nextCharacter);
  }
  return joinString(source, nextCharacter);
}
function linkHangulCharacters(source, nextCharacter) {
  const sourceJamo = disassembleToGroups(source)[0];
  const [, lastJamo] = excludeLastElement(sourceJamo);
  return joinString(removeLastCharacter(source), combineCharacter(lastJamo, nextCharacter));
}
function binaryAssembleCharacters(source, nextCharacter) {
  assert(isHangulCharacter(source) || isHangulAlphabet(source), `Invalid source character: ${source}. Source must be one character.`);
  assert(isHangulAlphabet(nextCharacter), `Invalid next character: ${nextCharacter}. Next character must be one of the choseong, jungseong, or jongseong.`);
  const sourceJamos = disassembleToGroups(source)[0];
  const isSingleCharacter = sourceJamos.length === 1;
  if (isSingleCharacter) {
    const sourceCharacter = sourceJamos[0];
    return binaryAssembleAlphabets(sourceCharacter, nextCharacter);
  }
  const [restJamos, lastJamo] = excludeLastElement(sourceJamos);
  const secondaryLastJamo = excludeLastElement(restJamos)[1];
  const needLinking = canBeChoseong(lastJamo) && canBeJungseong(nextCharacter);
  if (needLinking) {
    return linkHangulCharacters(source, nextCharacter);
  }
  const fixConsonant = curriedCombineCharacter;
  const combineJungseong = fixConsonant(restJamos[0]);
  if (canBeJungseong(`${lastJamo}${nextCharacter}`)) {
    return combineJungseong(`${lastJamo}${nextCharacter}`)();
  }
  if (canBeJungseong(`${secondaryLastJamo}${lastJamo}`) && canBeJongseong(nextCharacter)) {
    return combineJungseong(`${secondaryLastJamo}${lastJamo}`)(nextCharacter);
  }
  if (canBeJungseong(lastJamo) && canBeJongseong(nextCharacter)) {
    return combineJungseong(lastJamo)(nextCharacter);
  }
  const fixVowel = combineJungseong;
  const combineJongseong = fixVowel(canBeJungseong(`${restJamos[1]}${restJamos[2]}`) ? `${restJamos[1]}${restJamos[2]}` : restJamos[1]);
  const lastConsonant = lastJamo;
  if (hasBatchim(source, {
    only: "single"
  }) && canBeJongseong(`${lastConsonant}${nextCharacter}`)) {
    return combineJongseong(`${lastConsonant}${nextCharacter}`);
  }
  return joinString(source, nextCharacter);
}
function binaryAssemble(source, nextCharacter) {
  const [rest, lastCharacter] = excludeLastElement(source.split(""));
  const needJoinString = isBlank(lastCharacter) || isBlank(nextCharacter);
  return joinString(...rest, needJoinString ? joinString(lastCharacter, nextCharacter) : binaryAssembleCharacters(lastCharacter, nextCharacter));
}
var curriedCombineCharacter = choseong => jungseong => (jongseong = "") => combineCharacter(choseong, jungseong, jongseong);

// src/assemble/assemble.ts
function assemble(words) {
  const disassembled = disassemble(words.join("")).split("");
  return disassembled.reduce(binaryAssemble);
}

// src/convertQwertyToAlphabet/constants.ts
var QWERTY_KEYBOARD_MAP = {
  q: "\u3142",
  Q: "\u3143",
  w: "\u3148",
  W: "\u3149",
  e: "\u3137",
  E: "\u3138",
  r: "\u3131",
  R: "\u3132",
  t: "\u3145",
  T: "\u3146",
  y: "\u315B",
  Y: "\u315B",
  u: "\u3155",
  U: "\u3155",
  i: "\u3151",
  I: "\u3151",
  o: "\u3150",
  O: "\u3152",
  p: "\u3154",
  P: "\u3156",
  a: "\u3141",
  A: "\u3141",
  s: "\u3134",
  S: "\u3134",
  d: "\u3147",
  D: "\u3147",
  f: "\u3139",
  F: "\u3139",
  g: "\u314E",
  G: "\u314E",
  h: "\u3157",
  H: "\u3157",
  j: "\u3153",
  J: "\u3153",
  k: "\u314F",
  K: "\u314F",
  l: "\u3163",
  L: "\u3163",
  z: "\u314B",
  Z: "\u314B",
  x: "\u314C",
  X: "\u314C",
  c: "\u314A",
  C: "\u314A",
  v: "\u314D",
  V: "\u314D",
  b: "\u3160",
  B: "\u3160",
  n: "\u315C",
  N: "\u315C",
  m: "\u3161",
  M: "\u3161"
};

// src/convertQwertyToAlphabet/convertQwertyToAlphabet.ts
function convertQwertyToAlphabet(word) {
  return word.split("").map(inputText => hasProperty(QWERTY_KEYBOARD_MAP, inputText) ? QWERTY_KEYBOARD_MAP[inputText] : inputText).join("");
}

// src/convertQwertyToHangul/convertQwertyToHangul.ts
function convertQwertyToHangul(word) {
  if (!word) {
    return "";
  }
  return assemble([...convertQwertyToAlphabet(word)]);
}

// src/days/constants.ts
var DAYS_MAP = {
  1: "\uD558\uB8E8",
  2: "\uC774\uD2C0",
  3: "\uC0AC\uD758",
  4: "\uB098\uD758",
  5: "\uB2F7\uC0C8",
  6: "\uC5FF\uC0C8",
  7: "\uC774\uB808",
  8: "\uC5EC\uB4DC\uB808",
  9: "\uC544\uD750\uB808",
  10: "\uC5F4",
  20: "\uC2A4\uBB34"
};
var DAYS_ONLY_TENS_MAP = {
  10: "\uC5F4\uD758",
  20: "\uC2A4\uBB34\uB0A0",
  30: "\uC11C\uB978\uB0A0"
};

// src/days/days.ts
function days(num) {
  return getNumberWord(num);
}
function getNumberWord(num) {
  validateNumber(num);
  const tens = Math.floor(num / 10) * 10;
  const ones = num % 10;
  if (ones === 0 && hasProperty(DAYS_ONLY_TENS_MAP, tens)) {
    return DAYS_ONLY_TENS_MAP[tens];
  }
  const tensWord = hasProperty(DAYS_MAP, tens) ? DAYS_MAP[tens] : "";
  const onesWord = DAYS_MAP[ones];
  return `${tensWord}${onesWord}`;
}
function validateNumber(num) {
  if (Number.isNaN(num) || num <= 0 || num > 30 || !Number.isInteger(num) || !Number.isFinite(num)) {
    throw new Error("\uC9C0\uC6D0\uD558\uC9C0 \uC54A\uB294 \uC22B\uC790\uC785\uB2C8\uB2E4.");
  }
}

// src/getChoseong/constants.ts
var JASO_HANGUL_NFD = {
  START_CHOSEONG: _JASO_HANGUL_NFD[0],
  // ㄱ
  START_JUNGSEONG: _JASO_HANGUL_NFD[1],
  // ㅏ
  START_JONGSEONG: _JASO_HANGUL_NFD[2],
  // ㄱ
  END_CHOSEONG: _JASO_HANGUL_NFD[3],
  // ㅎ
  END_JUNGSEONG: _JASO_HANGUL_NFD[4],
  // ㅣ
  END_JONGSEONG: _JASO_HANGUL_NFD[5]
  // ㅎ
};

// src/getChoseong/getChoseong.ts
function getChoseong(word) {
  return word.normalize("NFD").replace(EXTRACT_CHOSEONG_REGEX, "").replace(CHOOSE_NFD_CHOSEONG_REGEX, $0 => CHOSEONGS[$0.charCodeAt(0) - 4352]);
}
var EXTRACT_CHOSEONG_REGEX = new RegExp(`[^\\u${JASO_HANGUL_NFD.START_CHOSEONG.toString(16)}-\\u${JASO_HANGUL_NFD.END_CHOSEONG.toString(16)}\u3131-\u314E\\s]+`, "ug");
var CHOOSE_NFD_CHOSEONG_REGEX = new RegExp(`[\\u${JASO_HANGUL_NFD.START_CHOSEONG.toString(16)}-\\u${JASO_HANGUL_NFD.END_CHOSEONG.toString(16)}]`, "g");

// src/josa/josa.ts
var 로_조사 = ["\uC73C\uB85C/\uB85C", "\uC73C\uB85C\uC11C/\uB85C\uC11C", "\uC73C\uB85C\uC368/\uB85C\uC368", "\uC73C\uB85C\uBD80\uD130/\uB85C\uBD80\uD130"];
function josa(word, josa2) {
  if (word.length === 0) {
    return word;
  }
  return word + josaPicker(word, josa2);
}
josa.pick = josaPicker;
function josaPicker(word, josa2) {
  var _a;
  if (word.length === 0) {
    return josa2.split("/")[0];
  }
  const has받침 = hasBatchim(word);
  let index = has받침 ? 0 : 1;
  const is종성ㄹ = has받침 && ((_a = disassembleCompleteCharacter(word[word.length - 1])) == null ? void 0 : _a.jongseong) === "\u3139";
  const isCaseOf로 = has받침 && is종성ㄹ && 로_조사.includes(josa2);
  if (josa2 === "\uC640/\uACFC" || isCaseOf로) {
    index = index === 0 ? 1 : 0;
  }
  const isEndsWith이 = word[word.length - 1] === "\uC774";
  if (josa2 === "\uC774\uC5D0\uC694/\uC608\uC694" && isEndsWith이) {
    index = 1;
  }
  return josa2.split("/")[index];
}

// src/numberToHangul/numberToHangul.ts
function numberToHangul(input, options) {
  if (!Number.isFinite(input) || Number.isNaN(input) || !Number.isInteger(input) || input < 0) {
    throw new Error("\uC720\uD6A8\uD55C 0 \uC774\uC0C1\uC758 \uC815\uC218\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.");
  }
  if (input === 0) {
    return "\uC601";
  }
  const koreanParts = [];
  let remainingDigits = input.toString();
  let placeIndex = 0;
  while (remainingDigits.length > 0) {
    const currentPart = remainingDigits.slice(-4);
    const koreanNumber = numberToKoreanUpToThousand(Number(currentPart));
    if (koreanNumber !== "") {
      koreanParts.unshift(`${koreanNumber}${HANGUL_DIGITS[placeIndex]}`);
    }
    remainingDigits = remainingDigits.slice(0, -4);
    placeIndex++;
  }
  if (options == null ? void 0 : options.spacing) {
    return koreanParts.filter(part => part !== "").join(" ").trim();
  }
  return koreanParts.join("");
}
function numberToKoreanUpToThousand(num) {
  const koreanDigits = num.toString().split("").reverse().map((digit, index) => digit === "0" ? "" : HANGUL_NUMBERS[Number(digit)] + HANGUL_CARDINAL[index]).reverse().join("");
  return koreanDigits.replace(/일천/, "\uCC9C").replace(/일백/, "\uBC31").replace(/일십/, "\uC2ED") || "";
}

// src/numberToHangulMixed/numberToHangulMixed.ts
function numberToHangulMixed(input, options) {
  if (input === 0) {
    return "0";
  }
  const koreanParts = [];
  let remainingDigits = input.toString();
  let placeIndex = 0;
  while (remainingDigits.length > 0) {
    const currentPart = remainingDigits.slice(-4);
    if (Number(currentPart) > 0) {
      koreanParts.unshift(`${Number(currentPart).toLocaleString()}${HANGUL_DIGITS[placeIndex]}`);
    }
    koreanParts.unshift("");
    remainingDigits = remainingDigits.slice(0, -4);
    placeIndex++;
  }
  if (options == null ? void 0 : options.spacing) {
    return koreanParts.filter(part => part !== "").join(" ").trim();
  }
  return koreanParts.join("");
}

// src/standardizePronunciation/constants.ts
var 음가가_없는_자음 = "\u3147";
var 한글_자모 = ["\uAE30\uC5ED", "\uB2C8\uC740", "\uB9AC\uC744", "\uBBF8\uC74C", "\uBE44\uC74D", "\uC2DC\uC637", "\uC774\uC751"];
var 특별한_한글_자모 = ["\uB514\uADFF", "\uC9C0\uC752", "\uCE58\uC753", "\uD0A4\uC754", "\uD2F0\uC755", "\uD53C\uC756", "\uD788\uC757"];
var 특별한_한글_자모의_발음 = {
  ㄷ: "\u3145",
  ㅈ: "\u3145",
  ㅊ: "\u3145",
  ㅌ: "\u3145",
  ㅎ: "\u3145",
  ㅋ: "\u3131",
  ㅍ: "\u3142"
};
var 음의_동화_받침 = {
  ㄷ: "\u3148",
  ㅌ: "\u314A",
  ㄹㅌ: "\u314A"
};
var ㄴㄹ이_덧나는_모음 = ["\u314F", "\u3150", "\u3153", "\u3154", "\u3157", "\u315C", "\u315F"];
var ㄴㄹ이_덧나는_후속음절_모음 = ["\u3151", "\u3155", "\u315B", "\u3160", "\u3163", "\u3152", "\u3156"];
var ㄴㄹ이_덧나서_받침_ㄴ_변환 = ["\u3131", "\u3134", "\u3137", "\u3141", "\u3142", "\u3147"];
var ㄴㄹ이_덧나서_받침_ㄹ_변환 = ["\u3139"];
var 자음동화_받침_ㄴ_변환 = ["\u3141", "\u3147", "\u3131", "\u3142"];
var 비음화_받침_ㅇ_변환 = ["\u3131", "\u3132", "\u314B", "\u3131\u3145", "\u3139\u3131"];
var 비음화_받침_ㄴ_변환 = ["\u3137", "\u3145", "\u3146", "\u3148", "\u314A", "\u314C", "\u314E"];
var 비음화_받침_ㅁ_변환 = ["\u3142", "\u314D", "\u3139\u3142", "\u3139\u314D", "\u3142\u3145"];
var 발음변환_받침_ㅎ = ["\u314E", "\u3134\u314E", "\u3139\u314E"];
var 발음변환_받침_ㅎ_발음 = {
  ㄱ: "\u314B",
  ㄷ: "\u314C",
  ㅈ: "\u314A",
  ㅅ: "\u3146"
};
var 발음변환_첫소리_ㅎ = ["\u3131", "\u3139\u3131", "\u3137", "\u3142", "\u3139\u3142", "\u3148", "\u3134\u3148"];
var 발음변환_첫소리_ㅎ_발음 = {
  ㄱ: "\u314B",
  ㄹㄱ: "\u314B",
  ㄷ: "\u314C",
  ㅂ: "\u314D",
  ㄹㅂ: "\u314D",
  ㅈ: "\u314A",
  ㄴㅈ: "\u314A"
};
var 받침_대표음_발음 = {
  ㄲ: "\u3131",
  ㅋ: "\u3131",
  ㄱㅅ: "\u3131",
  ㄹㄱ: "\u3131",
  ㅅ: "\u3137",
  ㅆ: "\u3137",
  ㅈ: "\u3137",
  ㅊ: "\u3137",
  ㅌ: "\u3137",
  ㅍ: "\u3142",
  ㅂㅅ: "\u3142",
  ㄹㅍ: "\u3142",
  ㄴㅈ: "\u3134",
  ㄹㅂ: "\u3139",
  ㄹㅅ: "\u3139",
  ㄹㅌ: "\u3139",
  ㄹㅁ: "\u3141"
};
var 된소리 = {
  ㄱ: "\u3132",
  ㄷ: "\u3138",
  ㅂ: "\u3143",
  ㅅ: "\u3146",
  ㅈ: "\u3149"
};
var 된소리_받침 = ["\u3131", "\u3132", "\u314B", "\u3131\u3145", "\u3139\u3131", "\u3137", "\u3145", "\u3146", "\u3148", "\u314A", "\u314C", "\u3142", "\u314D", "\u3139\u3142", "\u3139\u314D", "\u3142\u3145"];
var 어간_받침 = ["\u3134", "\u3134\u3148", "\u3141", "\u3139\u3141", "\u3139\u3142", "\u3139\u314C"];

// src/standardizePronunciation/rules/rules.utils.ts
function replace받침ㅎ(currentSyllable) {
  return currentSyllable.jongseong.replace("\u314E", "");
}

// src/standardizePronunciation/rules/transform12th.ts
function transform12th(currentSyllable, nextSyllable) {
  let current = __spreadValues({}, currentSyllable);
  let next = nextSyllable ? __spreadValues({}, nextSyllable) : nextSyllable;
  if (!current.jongseong) {
    return {
      current,
      next
    };
  }
  if (arrayIncludes(발음변환_받침_ㅎ, current.jongseong)) {
    if (next) {
      ({
        current,
        next
      } = handleNextChoseongIsㄱㄷㅈㅅ(current, next));
      ({
        current,
        next
      } = handleNextChoseongIsㄴ(current, next));
      ({
        current,
        next
      } = handleNextChoseongIsㅇ(current, next));
    }
    if (!next) {
      ({
        current
      } = handleCurrentJongseongIsㅇ(current));
    }
  }
  ({
    current,
    next
  } = handleNextChoseongIsㅎ(current, next));
  return {
    current,
    next
  };
}
function handleNextChoseongIsㄱㄷㅈㅅ(current, next) {
  const updatedCurrent = __spreadValues({}, current);
  const updatedNext = __spreadValues({}, next);
  if (arrayIncludes(["\u3131", "\u3137", "\u3148", "\u3145"], updatedNext.choseong)) {
    updatedNext.choseong = 발음변환_받침_ㅎ_발음[updatedNext.choseong];
    updatedCurrent.jongseong = replace받침ㅎ(updatedCurrent);
  }
  return {
    current: updatedCurrent,
    next: updatedNext
  };
}
function handleNextChoseongIsㄴ(current, next) {
  const updatedCurrent = __spreadValues({}, current);
  const updatedNext = __spreadValues({}, next);
  if (updatedNext.choseong === "\u3134" && arrayIncludes(["\u3134\u314E", "\u3139\u314E"], updatedCurrent.jongseong)) {
    updatedCurrent.jongseong = replace받침ㅎ(updatedCurrent);
  }
  return {
    current: updatedCurrent,
    next: updatedNext
  };
}
function handleNextChoseongIsㅇ(current, next) {
  const updatedCurrent = __spreadValues({}, current);
  const updatedNext = __spreadValues({}, next);
  if (updatedNext.choseong === 음가가_없는_자음) {
    if (arrayIncludes(["\u3134\u314E", "\u3139\u314E"], updatedCurrent.jongseong)) {
      updatedCurrent.jongseong = replace받침ㅎ(updatedCurrent);
    } else {
      updatedCurrent.jongseong = "";
    }
  } else {
    updatedCurrent.jongseong = replace받침ㅎ(updatedCurrent);
  }
  return {
    current: updatedCurrent,
    next: updatedNext
  };
}
function handleCurrentJongseongIsㅇ(current) {
  const updatedCurrent = __spreadValues({}, current);
  updatedCurrent.jongseong = replace받침ㅎ(updatedCurrent);
  return {
    current: updatedCurrent
  };
}
function handleNextChoseongIsㅎ(current, next) {
  const updatedCurrent = __spreadValues({}, current);
  const updatedNext = next ? __spreadValues({}, next) : next;
  if (arrayIncludes(발음변환_첫소리_ㅎ, updatedCurrent.jongseong) && arrayIncludes(["\u314E"], updatedNext == null ? void 0 : updatedNext.choseong)) {
    updatedNext.choseong = 발음변환_첫소리_ㅎ_발음[updatedCurrent.jongseong];
    if (updatedCurrent.jongseong.length === 1) {
      updatedCurrent.jongseong = "";
    } else {
      updatedCurrent.jongseong = updatedCurrent.jongseong[0];
    }
  }
  return {
    current: updatedCurrent,
    next: updatedNext
  };
}

// src/standardizePronunciation/rules/transform13And14th.ts
var 받침의길이 = {
  홀받침: 1,
  쌍_겹받침: 2
};
function transform13And14th(currentSyllable, nextSyllable) {
  let current = __spreadValues({}, currentSyllable);
  let next = __spreadValues({}, nextSyllable);
  const 제13_14항주요조건 = current.jongseong && next.choseong === 음가가_없는_자음;
  if (!제13_14항주요조건) {
    return {
      current,
      next
    };
  }
  ({
    current,
    next
  } = handle홑받침or쌍받침(current, next));
  ({
    current,
    next
  } = handle겹받침(current, next));
  return {
    current,
    next
  };
}
function is홑받침(current) {
  return current.jongseong.length === 받침의길이["\uD640\uBC1B\uCE68"];
}
function is쌍받침(current) {
  return current.jongseong.length === 받침의길이["\uC30D_\uACB9\uBC1B\uCE68"] && current.jongseong[0] === current.jongseong[1];
}
function is겹받침(current) {
  return current.jongseong.length === 받침의길이["\uC30D_\uACB9\uBC1B\uCE68"] && current.jongseong[0] !== current.jongseong[1];
}
function handle홑받침or쌍받침(current, next) {
  const updatedCurrent = __spreadValues({}, current);
  const updatedNext = __spreadValues({}, next);
  if (!arrayIncludes(["\u3147", ""], updatedCurrent.jongseong) && (is홑받침(updatedCurrent) || is쌍받침(updatedCurrent))) {
    updatedNext.choseong = updatedCurrent.jongseong;
    updatedCurrent.jongseong = "";
  }
  return {
    current: updatedCurrent,
    next: updatedNext
  };
}
function handle겹받침(current, next) {
  const updatedCurrent = __spreadValues({}, current);
  const updatedNext = __spreadValues({}, next);
  if (is겹받침(updatedCurrent)) {
    if (updatedCurrent.jongseong[1] === "\u3145") {
      updatedNext.choseong = "\u3146";
    } else {
      updatedNext.choseong = updatedCurrent.jongseong[1];
    }
    updatedCurrent.jongseong = updatedCurrent.jongseong.replace(updatedCurrent.jongseong[1], "");
  }
  return {
    current: updatedCurrent,
    next: updatedNext
  };
}

// src/standardizePronunciation/rules/transform16th.ts
function transform16th({
  currentSyllable,
  phrase,
  index,
  nextSyllable
}) {
  let current = __spreadValues({}, currentSyllable);
  let next = __spreadValues({}, nextSyllable);
  const 제16항주요조건 = current.jongseong && next.choseong === 음가가_없는_자음;
  if (!제16항주요조건) {
    return {
      current,
      next
    };
  }
  const combinedSyllables = phrase[index - 1] + phrase[index];
  ({
    current,
    next
  } = handleSpecialHangulCharacters({
    current,
    next,
    combinedSyllables
  }));
  ({
    current,
    next
  } = handleHangulCharacters({
    current,
    next,
    combinedSyllables
  }));
  return {
    current,
    next
  };
}
function handleSpecialHangulCharacters({
  current,
  next,
  combinedSyllables
}) {
  const updatedCurrent = __spreadValues({}, current);
  const updatedNext = __spreadValues({}, next);
  if (arrayIncludes(특별한_한글_자모, combinedSyllables)) {
    const 다음_음절의_초성 = 특별한_한글_자모의_발음[updatedCurrent.jongseong];
    updatedCurrent.jongseong = "";
    updatedNext.choseong = 다음_음절의_초성;
  }
  return {
    current: updatedCurrent,
    next: updatedNext
  };
}
function handleHangulCharacters({
  current,
  next,
  combinedSyllables
}) {
  const updatedCurrent = __spreadValues({}, current);
  const updatedNext = __spreadValues({}, next);
  if (arrayIncludes(한글_자모, combinedSyllables)) {
    updatedNext.choseong = updatedCurrent.jongseong;
    if (updatedCurrent.jongseong !== "\u3147") {
      updatedCurrent.jongseong = "";
    }
  }
  return {
    current: updatedCurrent,
    next: updatedNext
  };
}

// src/standardizePronunciation/rules/transform17th.ts
function transform17th(currentSyllable, nextSyllable) {
  let current = __spreadValues({}, currentSyllable);
  let next = __spreadValues({}, nextSyllable);
  const 제17항주요조건 = next.jungseong === "\u3163";
  if (!제17항주요조건) {
    return {
      current,
      next
    };
  }
  ({
    current,
    next
  } = handleChoseongIsㅇ(current, next));
  ({
    current,
    next
  } = handleChoseongIsㅎAndㄷ(current, next));
  return {
    current,
    next
  };
}
function handleChoseongIsㅇ(current, next) {
  const updatedCurrent = __spreadValues({}, current);
  const updatedNext = __spreadValues({}, next);
  if (updatedNext.choseong === "\u3147" && hasProperty(음의_동화_받침, updatedCurrent.jongseong)) {
    updatedNext.choseong = 음의_동화_받침[updatedCurrent.jongseong];
    updatedCurrent.jongseong = updatedCurrent.jongseong === "\u3139\u314C" ? "\u3139" : "";
  }
  return {
    current: updatedCurrent,
    next: updatedNext
  };
}
function handleChoseongIsㅎAndㄷ(current, next) {
  const updatedCurrent = __spreadValues({}, current);
  const updatedNext = __spreadValues({}, next);
  if (updatedNext.choseong === "\u314E" && updatedCurrent.jongseong === "\u3137") {
    updatedNext.choseong = "\u314A";
    updatedCurrent.jongseong = "";
  }
  return {
    current: updatedCurrent,
    next: updatedNext
  };
}

// src/standardizePronunciation/rules/transform18th.ts
function transform18th(currentSyllable, nextSyllable) {
  const current = __spreadValues({}, currentSyllable);
  const 제18항주요조건 = current.jongseong && arrayIncludes(["\u3134", "\u3141"], nextSyllable.choseong);
  if (!제18항주요조건) {
    return {
      current
    };
  }
  if (arrayIncludes(비음화_받침_ㅇ_변환, current.jongseong)) {
    current.jongseong = "\u3147";
  }
  if (arrayIncludes(비음화_받침_ㄴ_변환, current.jongseong)) {
    current.jongseong = "\u3134";
  }
  if (arrayIncludes(비음화_받침_ㅁ_변환, current.jongseong)) {
    current.jongseong = "\u3141";
  }
  return {
    current
  };
}

// src/standardizePronunciation/rules/transform19th.ts
function transform19th(currentSyllable, nextSyllable) {
  const next = __spreadValues({}, nextSyllable);
  const 제19항조건 = arrayIncludes(자음동화_받침_ㄴ_변환, currentSyllable.jongseong) && next.choseong === "\u3139";
  if (제19항조건) {
    next.choseong = "\u3134";
  }
  return {
    next
  };
}

// src/standardizePronunciation/rules/transform20th.ts
function transform20th(currentSyllable, nextSyllable) {
  let current = __spreadValues({}, currentSyllable);
  let next = __spreadValues({}, nextSyllable);
  ({
    current
  } = applyMainCondition(current, next));
  ({
    next
  } = applySupplementaryCondition(current, next));
  return {
    current,
    next
  };
}
function applyMainCondition(current, next) {
  const updatedCurrent = __spreadValues({}, current);
  if (updatedCurrent.jongseong === "\u3134" && next.choseong === "\u3139") {
    updatedCurrent.jongseong = "\u3139";
  }
  return {
    current: updatedCurrent
  };
}
function applySupplementaryCondition(current, next) {
  const updatedNext = __spreadValues({}, next);
  if (updatedNext.choseong === "\u3134" && (current.jongseong === "\u3139" || arrayIncludes(["\u3139\u314E", "\u3139\u314C"], current.jongseong))) {
    updatedNext.choseong = "\u3139";
  }
  return {
    next: updatedNext
  };
}

// src/standardizePronunciation/rules/transform9And10And11th.ts
function transform9And10And11th(currentSyllable, nextSyllable) {
  const current = __spreadValues({}, currentSyllable);
  const is어말 = current.jongseong && !nextSyllable;
  const is음가있는자음앞 = current.jongseong && (nextSyllable == null ? void 0 : nextSyllable.choseong) !== 음가가_없는_자음;
  const 제9_10_11항주요조건 = (is어말 || is음가있는자음앞) && hasProperty(받침_대표음_발음, current.jongseong);
  if (제9_10_11항주요조건) {
    current.jongseong = 받침_대표음_발음[current.jongseong];
  }
  return {
    current
  };
}

// src/standardizePronunciation/rules/transformHardConversion.ts
function transformHardConversion(currentSyllable, nextSyllable) {
  const next = __spreadValues({}, nextSyllable);
  if (hasProperty(된소리, next.choseong)) {
    const 제23항조건 = arrayIncludes(된소리_받침, currentSyllable.jongseong);
    const 제24_25항조건 = arrayIncludes(어간_받침, currentSyllable.jongseong) && next.choseong !== "\u3142";
    if (제23항조건 || 제24_25항조건) {
      next.choseong = 된소리[next.choseong];
    }
  }
  return {
    next
  };
}

// src/standardizePronunciation/rules/transformNLAssimilation.ts
function transformNLAssimilation(currentSyllable, nextSyllable) {
  let current = __spreadValues({}, currentSyllable);
  let next = __spreadValues({}, nextSyllable);
  const ㄴㄹ이덧나는조건 = current.jongseong && next.choseong === "\u3147" && arrayIncludes(ㄴㄹ이_덧나는_후속음절_모음, next.jungseong);
  if (!ㄴㄹ이덧나는조건) {
    return {
      current,
      next
    };
  }
  ({
    current,
    next
  } = applyㄴㄹ덧남(current, next));
  return {
    current,
    next
  };
}
function applyㄴㄹ덧남(current, next) {
  const updatedCurrent = __spreadValues({}, current);
  const updatedNext = __spreadValues({}, next);
  if (arrayIncludes(ㄴㄹ이_덧나는_모음, updatedCurrent.jungseong)) {
    if (arrayIncludes(ㄴㄹ이_덧나서_받침_ㄴ_변환, updatedCurrent.jongseong)) {
      updatedCurrent.jongseong = updatedCurrent.jongseong === "\u3131" ? "\u3147" : updatedCurrent.jongseong;
      updatedNext.choseong = "\u3134";
    }
    if (arrayIncludes(ㄴㄹ이_덧나서_받침_ㄹ_변환, updatedCurrent.jongseong)) {
      updatedNext.choseong = "\u3139";
    }
  } else {
    updatedNext.choseong = updatedCurrent.jongseong;
  }
  return {
    current: updatedCurrent,
    next: updatedNext
  };
}

// src/standardizePronunciation/standardizePronunciation.ts
function standardizePronunciation(hangul, options = {
  hardConversion: true
}) {
  if (!hangul) {
    return "";
  }
  const processSyllables = (syllables, phrase, options2) => syllables.map((currentSyllable, index, array) => {
    const nextSyllable = index < array.length - 1 ? array[index + 1] : null;
    const {
      current,
      next
    } = applyRules({
      currentSyllable,
      phrase,
      index,
      nextSyllable,
      options: options2
    });
    if (next) {
      array[index + 1] = next;
    }
    return current;
  });
  const transformHangulPhrase = (phrase, options2) => {
    const {
      notHangulPhrase,
      disassembleHangul
    } = 음절분해(phrase);
    const processedSyllables = processSyllables(disassembleHangul, phrase, options2);
    return assembleChangedHangul(processedSyllables, notHangulPhrase);
  };
  return hangul.split(" ").map(phrase => transformHangulPhrase(phrase, options)).join(" ");
}
function 음절분해(hangulPhrase) {
  const notHangulPhrase = [];
  const disassembleHangul = Array.from(hangulPhrase).filter((syllable, index) => {
    if (!isHangulCharacter(syllable) || isHangulAlphabet(syllable)) {
      notHangulPhrase.push({
        index,
        syllable
      });
      return false;
    }
    return true;
  }).map(disassembleCompleteCharacter).filter(isNotUndefined);
  return {
    notHangulPhrase,
    disassembleHangul
  };
}
function applyRules(params) {
  const {
    currentSyllable,
    nextSyllable,
    index,
    phrase,
    options
  } = params;
  let current = __spreadValues({}, currentSyllable);
  let next = nextSyllable ? __spreadValues({}, nextSyllable) : nextSyllable;
  if (next && options.hardConversion) {
    ({
      next
    } = transformHardConversion(current, next));
  }
  if (next) {
    ({
      current,
      next
    } = transform16th({
      currentSyllable: current,
      nextSyllable: next,
      index,
      phrase
    }));
    ({
      current,
      next
    } = transform17th(current, next));
    ({
      next
    } = transform19th(current, next));
    ({
      current,
      next
    } = transformNLAssimilation(current, next));
    ({
      current
    } = transform18th(current, next));
    ({
      current,
      next
    } = transform20th(current, next));
  }
  ({
    current,
    next
  } = transform12th(current, next));
  if (next) {
    ({
      current,
      next
    } = transform13And14th(current, next));
  }
  ({
    current
  } = transform9And10And11th(current, next));
  return {
    current,
    next
  };
}
function assembleChangedHangul(disassembleHangul, notHangulPhrase) {
  const changedSyllables = disassembleHangul.filter(isNotUndefined).map(syllable => combineCharacter(syllable.choseong, syllable.jungseong, syllable.jongseong));
  for (const {
    index,
    syllable
  } of notHangulPhrase) {
    changedSyllables.splice(index, 0, syllable);
  }
  return joinString(...changedSyllables);
}

// src/romanize/constants.ts
var 중성_알파벳_발음 = {
  // ------- 단모음
  ㅏ: "a",
  ㅓ: "eo",
  ㅗ: "o",
  ㅜ: "u",
  ㅡ: "eu",
  ㅣ: "i",
  ㅐ: "ae",
  ㅔ: "e",
  ㅚ: "oe",
  ㅟ: "wi",
  // -------
  // ------- 이중모음
  ㅑ: "ya",
  ㅕ: "yeo",
  ㅛ: "yo",
  ㅠ: "yu",
  ㅒ: "yae",
  ㅖ: "ye",
  ㅘ: "wa",
  ㅙ: "wae",
  ㅝ: "wo",
  ㅞ: "we",
  ㅢ: "ui"
};
var 초성_알파벳_발음 = {
  // ------- 파열음
  ㄱ: "g",
  ㄲ: "kk",
  ㅋ: "k",
  ㄷ: "d",
  ㄸ: "tt",
  ㅌ: "t",
  ㅂ: "b",
  ㅃ: "pp",
  ㅍ: "p",
  // -------
  // ------- 파찰음
  ㅈ: "j",
  ㅉ: "jj",
  ㅊ: "ch",
  // -------
  // ------- 마찰음
  ㅅ: "s",
  ㅆ: "ss",
  ㅎ: "h",
  // -------
  // ------- 비음
  ㄴ: "n",
  ㅁ: "m",
  ㅇ: "",
  // -------
  // ------- 유음
  ㄹ: "r"
};
var 종성_알파벳_발음 = {
  ㄱ: "k",
  ㄴ: "n",
  ㄷ: "t",
  ㄹ: "l",
  ㅁ: "m",
  ㅂ: "p",
  ㅇ: "ng",
  "": ""
};

// src/romanize/romanize.ts
function romanize(hangul) {
  const changedHangul = standardizePronunciation(hangul, {
    hardConversion: false
  });
  return changedHangul.split("").map((_, i, arrayHangul) => romanizeSyllableHangul(arrayHangul, i)).join("");
}
var romanizeSyllableHangul = (arrayHangul, index) => {
  const syllable = arrayHangul[index];
  if (isHangulCharacter(syllable)) {
    const disassemble2 = disassembleCompleteCharacter(syllable);
    let choseong = 초성_알파벳_발음[disassemble2.choseong];
    const jungseong = 중성_알파벳_발음[assemble([disassemble2.jungseong])];
    const jongseong = 종성_알파벳_발음[disassemble2.jongseong];
    if (disassemble2.choseong === "\u3139" && index > 0 && isHangulCharacter(arrayHangul[index - 1])) {
      const prevDisassemble = disassembleCompleteCharacter(arrayHangul[index - 1]);
      if ((prevDisassemble == null ? void 0 : prevDisassemble.jongseong) === "\u3139") {
        choseong = "l";
      }
    }
    return choseong + jungseong + jongseong;
  }
  if (syllable in 중성_알파벳_발음) {
    return 중성_알파벳_발음[syllable];
  }
  if (canBeChoseong(syllable)) {
    return 초성_알파벳_발음[syllable];
  }
  return syllable;
};

// src/susa/constants.ts
var SUSA_MAP = {
  1: "\uD558\uB098",
  2: "\uB458",
  3: "\uC14B",
  4: "\uB137",
  5: "\uB2E4\uC12F",
  6: "\uC5EC\uC12F",
  7: "\uC77C\uACF1",
  8: "\uC5EC\uB35F",
  9: "\uC544\uD649",
  10: "\uC5F4",
  20: "\uC2A4\uBB3C",
  30: "\uC11C\uB978",
  40: "\uB9C8\uD754",
  50: "\uC270",
  60: "\uC608\uC21C",
  70: "\uC77C\uD754",
  80: "\uC5EC\uB4E0",
  90: "\uC544\uD754",
  100: "\uBC31"
};
var SUSA_CLASSIFIER_MAP = {
  1: "\uD55C",
  2: "\uB450",
  3: "\uC138",
  4: "\uB124",
  20: "\uC2A4\uBB34"
};

// src/susa/susa.ts
function susa(num, classifier) {
  validateNumber2(num);
  return classifier ? getClassifierWord(num) : getNumberWord2(num);
}
function getClassifierWord(num) {
  if (num === 20) {
    return SUSA_CLASSIFIER_MAP[num];
  }
  const tens = Math.floor(num / 10) * 10;
  const ones = num % 10;
  const tensWord = hasProperty(SUSA_MAP, tens) ? SUSA_MAP[tens] : "";
  if (ones === 0) {
    return tensWord;
  }
  if (hasProperty(SUSA_CLASSIFIER_MAP, ones)) {
    const onesWord2 = SUSA_CLASSIFIER_MAP[ones];
    return `${tensWord}${onesWord2}`;
  }
  const onesWord = SUSA_MAP[ones];
  return `${tensWord}${onesWord}`;
}
function validateNumber2(num) {
  if (Number.isNaN(num) || num <= 0 || num > 100 || !Number.isInteger(num) || !Number.isFinite(num)) {
    throw new Error("\uC9C0\uC6D0\uD558\uC9C0 \uC54A\uB294 \uC22B\uC790\uC785\uB2C8\uB2E4.");
  }
}
function getNumberWord2(num) {
  if (num === 100) {
    return SUSA_MAP[100];
  }
  const tens = Math.floor(num / 10) * 10;
  const ones = num % 10;
  const tensWord = hasProperty(SUSA_MAP, tens) ? SUSA_MAP[tens] : "";
  const onesWord = hasProperty(SUSA_MAP, ones) ? SUSA_MAP[ones] : "";
  return `${tensWord}${onesWord}`;
}
export { amountToHangul, assemble, canBeChoseong, canBeJongseong, canBeJungseong, combineCharacter, combineVowels, convertQwertyToAlphabet, convertQwertyToHangul, days, disassemble, disassembleCompleteCharacter, disassembleToGroups, getChoseong, hasBatchim, josa, numberToHangul, numberToHangulMixed, removeLastCharacter, romanize, standardizePronunciation, susa };