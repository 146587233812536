import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  viewChild,
} from '@angular/core';
import { OptionDirective } from 'libs/front-share/src/directives/option.directive';
import { Icon } from '../../../icon/icon.component';
import { SelectOptionAdapter } from '../select-option.adapter';

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss'],
  standalone: true,
  imports: [CommonModule, Icon, OptionDirective],
})
export class SelectOption
  extends SelectOptionAdapter<any>
  implements AfterViewInit
{
  directiveRef = viewChild(OptionDirective);
  changeRef = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    if (this.renderItem()) {
      const viewContainerRef = this.directiveRef()?.viewContainerRef;
      viewContainerRef?.clear();

      const componentRef = viewContainerRef?.createComponent<
        SelectOptionAdapter<any>
      >(this.renderItem());

      componentRef?.setInput('option', this.option());
      componentRef?.setInput('focus', this.focus());

      this.changeRef.detectChanges();

      this.focus$.subscribe({
        next: (focus) => {
          componentRef?.setInput('focus', focus);
        },
      });

      componentRef?.instance.mouseEnter.subscribe(() => {
        this.mouseEnter.emit();
      });

      componentRef?.instance.mouseLeave.subscribe(() => {
        this.mouseLeave.emit();
      });
    }
  }
}
