/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { memoControllerCreateV1 } from '../fn/memo/memo-controller-create-v-1';
import { MemoControllerCreateV1$Params } from '../fn/memo/memo-controller-create-v-1';
import { memoControllerDeleteV1 } from '../fn/memo/memo-controller-delete-v-1';
import { MemoControllerDeleteV1$Params } from '../fn/memo/memo-controller-delete-v-1';
import { memoControllerFindByIdV1 } from '../fn/memo/memo-controller-find-by-id-v-1';
import { MemoControllerFindByIdV1$Params } from '../fn/memo/memo-controller-find-by-id-v-1';
import { memoControllerSearchV1 } from '../fn/memo/memo-controller-search-v-1';
import { MemoControllerSearchV1$Params } from '../fn/memo/memo-controller-search-v-1';
import { memoControllerUpdateV1 } from '../fn/memo/memo-controller-update-v-1';
import { MemoControllerUpdateV1$Params } from '../fn/memo/memo-controller-update-v-1';
import { MemoDto } from '../models/memo-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class MemoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `memoControllerSearchV1()` */
  static readonly MemoControllerSearchV1Path = '/api/v1/memo/search';

  /**
   * 메모 검색.
   *
   * 메모를 검색 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memoControllerSearchV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  memoControllerSearchV1$Response(params: MemoControllerSearchV1$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return memoControllerSearchV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 메모 검색.
   *
   * 메모를 검색 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memoControllerSearchV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memoControllerSearchV1(params: MemoControllerSearchV1$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.memoControllerSearchV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `memoControllerFindByIdV1()` */
  static readonly MemoControllerFindByIdV1Path = '/api/v1/memo/{id}';

  /**
   * 메모 검색.
   *
   * 메모를 검색 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memoControllerFindByIdV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  memoControllerFindByIdV1$Response(params: MemoControllerFindByIdV1$Params, context?: HttpContext): Observable<StrictHttpResponse<MemoDto>> {
    return memoControllerFindByIdV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 메모 검색.
   *
   * 메모를 검색 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memoControllerFindByIdV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memoControllerFindByIdV1(params: MemoControllerFindByIdV1$Params, context?: HttpContext): Observable<MemoDto> {
    return this.memoControllerFindByIdV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<MemoDto>): MemoDto => r.body)
    );
  }

  /** Path part for operation `memoControllerDeleteV1()` */
  static readonly MemoControllerDeleteV1Path = '/api/v1/memo/{id}';

  /**
   * 메모 삭제.
   *
   * 메모를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memoControllerDeleteV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  memoControllerDeleteV1$Response(params: MemoControllerDeleteV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return memoControllerDeleteV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 메모 삭제.
   *
   * 메모를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memoControllerDeleteV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memoControllerDeleteV1(params: MemoControllerDeleteV1$Params, context?: HttpContext): Observable<boolean> {
    return this.memoControllerDeleteV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `memoControllerUpdateV1()` */
  static readonly MemoControllerUpdateV1Path = '/api/v1/memo/{id}';

  /**
   * 메모 수정.
   *
   * 메모를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memoControllerUpdateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memoControllerUpdateV1$Response(params: MemoControllerUpdateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return memoControllerUpdateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 메모 수정.
   *
   * 메모를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memoControllerUpdateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memoControllerUpdateV1(params: MemoControllerUpdateV1$Params, context?: HttpContext): Observable<void> {
    return this.memoControllerUpdateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `memoControllerCreateV1()` */
  static readonly MemoControllerCreateV1Path = '/api/v1/memo';

  /**
   * 메모 등록.
   *
   * 메모를 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memoControllerCreateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memoControllerCreateV1$Response(params: MemoControllerCreateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return memoControllerCreateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 메모 등록.
   *
   * 메모를 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memoControllerCreateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memoControllerCreateV1(params: MemoControllerCreateV1$Params, context?: HttpContext): Observable<boolean> {
    return this.memoControllerCreateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
