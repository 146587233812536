import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  computed,
  forwardRef,
  inject,
  signal,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ClickOutside } from 'ngxtension/click-outside';
import { BaseInput } from '../base-input';
import { Input } from '../input.component';

@Component({
  selector: 'app-input-email',
  templateUrl: './input-email.component.html',
  imports: [CommonModule, Input, ClickOutside],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEmail),
      multi: true,
    },
  ],
})
export class InputEmail extends BaseInput<string> {
  domains = [
    'naver.com',
    'kakao.com',
    'gmail.com',
    'hanmail.net',
    'daum.net',
    'nate.com',
  ];

  changeRef = inject(ChangeDetectorRef);
  open = signal(false);
  currentIndex = signal(-1);
  currentValue = computed(() => this.value()?.split('@')[0]);
  has = computed(() => this.value()?.includes('@'));

  constructor() {
    super();
  }

  /**
   * @description 키보드 이벤트 처리
   * @param ev
   * @returns
   */
  handleKeydown(ev: KeyboardEvent) {
    const index = this.currentIndex();
    const length = this.domains.length - 1;

    if (ev.key === '@') {
      this.open.set(true);
    }

    switch (ev.key) {
      case 'ArrowDown':
        if (index === length) {
          this.currentIndex.set(0);
        } else {
          this.currentIndex.set(index + 1);
        }
        break;
      case 'ArrowUp':
        if (index === 0) {
          this.currentIndex.set(length);
        } else {
          this.currentIndex.set(index - 1);
        }
        break;
      case 'Enter':
        if (index !== -1) {
          const option = this.domains[index];
          this.value.set(this.currentValue() + '@' + option);
          this.open.set(false);
          this.changeRef.detectChanges();
        }
        break;
      case 'Escape':
        this.open.set(false);
        break;
    }
  }

  handleFoucs() {
    if (this.value()?.includes('@')) {
      this.open.set(true);
    }
  }

  handleSelect(ev: string) {
    this.value.set(this.currentValue() + '@' + ev);
    this.open.set(false);
  }
}
