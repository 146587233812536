import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  booleanAttribute,
  computed,
  inject,
  input,
  model,
  viewChild,
  viewChildren,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { OptionDirective } from '../../directives/option.directive';
import { BaseConfig } from '../common/config/config.adapter';
import { Color } from '../common/types';
import { SegmentOptionComponent } from './option/segment-option.component';

export type SegmentOption = {
  label: string;
  value: string;
  icon?: string;
  count?: number;
  renderItem?: any;
};

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
  standalone: true,
  imports: [CommonModule, OptionDirective, SegmentOptionComponent],
})
export class Segment
  extends BaseConfig
  implements OnInit, AfterViewChecked, AfterViewInit
{
  @HostBinding('class') get hostClass() {
    if (this.scrollEnabled()) {
      return 'w-full';
    }

    return '';
  }

  readonly elementRef = inject(ElementRef);

  container = viewChild<ElementRef<HTMLElement>>('container');
  indicator = viewChild<ElementRef<HTMLElement>>('indicator');
  items = viewChildren<SegmentOptionComponent>('item');

  color = input<Color>('primary');
  $options = input<string[] | SegmentOption[]>([], { alias: 'options' });

  options = computed<SegmentOption[]>(() => {
    if (this.$options().length === 0) return [];

    return this.$options().map((option) => {
      if (typeof option === 'string') {
        return {
          label: option,
          value: option,
        };
      }

      return option;
    });
  });

  value = model<string>(this.options()[0]?.value);
  value$ = toObservable(this.value);

  scrollEnabled = input<boolean, string>(false, {
    transform: booleanAttribute,
  });

  ngOnInit(): void {
    if (!this.value()) {
      this.value.set(this.options()[0].value);
    }
  }

  ngAfterViewInit(): void {
    this.value$.subscribe({
      next: (value) => {
        if (this.container() && value && this.scrollEnabled()) {
          // setTimeout 안걸면 scrollBy가 안먹힘
          setTimeout(() => {
            const left = this.getTranslateX(
              this.options().findIndex((item) => item.value === value),
            );
            this.container()!.nativeElement.scrollBy({
              left,
            });
          }, 100);
        }
      },
    });
  }

  ngAfterViewChecked(): void {
    this.value$.subscribe((value) => {
      if (this.items() && this.indicator() && value) {
        this.transform();
      }
    });
  }

  handleClick(option: SegmentOption) {
    this.value.set(option.value);
  }

  transform() {
    const index = this.options().findIndex(
      (item) => item.value === this.value(),
    );
    if (index === -1) return;
    this.indicator()!.nativeElement.style.width = `${
      this.items()[index].elementRef.nativeElement!.offsetWidth
    }px`;
    const translateX = this.getTranslateX(index);
    this.indicator()!.nativeElement.style.transform = `translateX(${translateX}px)`;
  }

  getTranslateX(index: number) {
    return this.items()
      .slice(0, index)
      .reduce((acc, cur) => acc + cur.elementRef.nativeElement.offsetWidth, 0);
  }
}
