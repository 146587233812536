<section (clickOutside)="isOpen.set(false)" class="relative">
  <app-input
    readonly
    [color]="currentColor()"
    [size]="currentSize()"
    [rounded]="currentRounded()"
    [required]="required()"
    [disabled]="disabled()"
    [autofocus]="autofocus()"
    [autocomplete]="autocomplete()"
    [spellcheck]="spellcheck()"
    [label]="label()"
    [(value)]="inputValue"
    (inputFocus)="isOpen.set(true)"
  >
    <app-icon
      (click)="handleOpenCalendar()"
      [attr.data-theme]="theme()"
      class="cursor-pointer theme-text"
      icons
      name="material-symbols:calendar-month"
    />
  </app-input>
  @if (isOpen()) {
    <div
      class="shadow-md md:min-w-max fixed md:inset-auto md:w-auto md:h-auto inset-0 items-center flex p-4 md:p-0 w-screen md:bg-transparent bg-black/70 h-screen z-[999] md:absolute md:top-[calc(100%+4px)]"
    >
      <app-input-calendar
        mode="range"
        [attr.data-theme]="theme()"
        [startDate]="start()"
        [endDate]="end()"
        (select)="handleSelect($event)"
      />
    </div>
  }
</section>
