import { Component, HostListener, model } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Checkbox } from '../../../../checkbox/checkbox.component';
import { CellAdapter } from '../cell.adapter';

@Component({
  host: {
    ngSkipHydration: 'true',
  },
  selector: 'app-checkbox-cell',
  standalone: true,
  imports: [Checkbox],
  template: `
    <div class="flex justify-center">
      <app-checkbox [value]="value()" (valueChange)="valueChange($event)" />
    </div>
  `,
})
export class CheckboxCell extends CellAdapter {
  @HostListener('click', ['$event']) handleClick(ev: MouseEvent) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  value = model<boolean>(false);
  selecteds = model<any[]>([]);
  selecteds$ = toObservable(this.selecteds);

  constructor() {
    super();

    // 선택된 row의 uniqueId를 가져와서 체크박스 선택 여부를 결정
    this.selecteds$.subscribe((selecteds) => {
      this.value.set(selecteds.includes(this.row()));
    });
  }

  /**
   * @description 체크박스 선택/해제
   * @param ev
   */
  valueChange(ev: boolean) {
    const array = this.selecteds() || [];

    if (ev) {
      this.selecteds.set([...(array || []), this.row()]);
    } else {
      this.selecteds.set(array.filter((id) => id !== this.row()['uniqueId']));
    }
  }
}
