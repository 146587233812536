<section class="flex flex-col gap-2">
  <div class="flex flex-col w-full gap-2">
    @if (label(); as label) {
      <app-label class="text-black" [required]="required()">{{
        label
      }}</app-label>
    }

    <div class="flex items-center w-full gap-2">
      <div class="relative w-full" (click)="handleAddress($event)">
        <app-input
          #inputRef
          readonly
          [(value)]="address"
          [color]="color()"
          [disabled]="disabled()"
          placeholder="주소를 검색해주세요."
          [required]="required()"
        />
      </div>
      <app-button
        (click)="handleAddress($event)"
        color="primary"
        class="shrink-0"
      >
        검색
      </app-button>
    </div>
  </div>

  <app-input
    type="text"
    placeholder="상세주소를 입력해주세요."
    [color]="color()"
    [disabled]="disabled()"
    [(value)]="addressDetail"
    [maxlength]="200"
  />
</section>
