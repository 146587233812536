<article
  [attr.data-theme]="theme()"
  class="flex flex-col w-full border theme-border rounded-md"
>
  <div
    class="flex items-center justify-between w-full px-5 py-3.5 cursor-pointer"
    (click)="toggle()"
  >
    <div class="flex items-center w-full gap-2">
      <div>
        <div class="flex items-center gap-3 min-w-max">
          <p
            [attr.data-theme]="theme()"
            class="text-xl font-semibold theme-text"
          >
            {{ label() }}
          </p>
          @if (open()) {
            <app-icon
              [attr.data-theme]="theme()"
              name="ic:round-keyboard-arrow-up"
              class="theme-text"
            />
          } @else {
            <app-icon
              [attr.data-theme]="theme()"
              name="ic:round-keyboard-arrow-down"
              class="theme-text"
            />
          }
        </div>
      </div>
      <div class="w-full cursor-default" (click)="slotClick($event)">
        <ng-content select="[headerContent]" />
      </div>
    </div>
    <div
      class="flex items-center gap-2 cursor-default min-w-max"
      (click)="slotClick($event)"
    >
      <ng-content select="[buttons]" />
    </div>
  </div>
  @if (open()) {
    <div class="flex flex-col gap-6 px-5 pb-5">
      <app-divider />
      <ng-content />
    </div>
  }
</article>
