/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { userActionLogControllerSearchOffset } from '../fn/user-action-log/user-action-log-controller-search-offset';
import { UserActionLogControllerSearchOffset$Params } from '../fn/user-action-log/user-action-log-controller-search-offset';

@Injectable({ providedIn: 'root' })
export class UserActionLogService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userActionLogControllerSearchOffset()` */
  static readonly UserActionLogControllerSearchOffsetPath = '/api/user-action-log/search/offset';

  /**
   * 사용자 로그 오프셋 기반 조회.
   *
   * 사용자 로그을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userActionLogControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  userActionLogControllerSearchOffset$Response(params: UserActionLogControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return userActionLogControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 로그 오프셋 기반 조회.
   *
   * 사용자 로그을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userActionLogControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userActionLogControllerSearchOffset(params: UserActionLogControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.userActionLogControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

}
