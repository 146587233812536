import { CommonModule } from '@angular/common';
import { Component, output } from '@angular/core';
import { BaseConfig } from '../common/config/config.adapter';
import { Icon } from '../components';

@Component({
  selector: 'app-modal-header',
  template: `
    <section
      [ngClass]="theme() === 'dark' ? 'bg-dark' : 'bg-white'"
      [attr.data-theme]="theme()"
      class="z-10 flex justify-between w-full p-4 border-b theme-border"
    >
      <div
        class="flex gap-3 items-center theme-text"
        [attr.data-theme]="theme()"
      >
        <app-icon
          (click)="close.emit()"
          [ngClass]="theme() === 'dark' ? 'text-white' : 'text-gray-700'"
          name="material-symbols:arrow-back-ios-new"
          class="cursor-pointer"
          size="sm"
        />
        <ng-content />
      </div>
      <ng-content select="[icons]" />
    </section>
  `,
  imports: [Icon, CommonModule],
})
export class ModalHeader extends BaseConfig {
  close = output<void>();
}
