@if (row(); as row) {
  <div
    [cdkContextMenuTriggerFor]="context"
    [class.cursor-pointer]="options()?.row?.clickHandler"
    [attr.data-theme]="theme()"
    class="card-wrapper theme-border"
  >
    <div class="w-[20rem] h-[10rem] overflow-hidden">
      <app-image [src]="src()" />
    </div>
    <div class="flex flex-col gap-4 p-4">
      <p
        [attr.data-theme]="theme()"
        class="text-xl md:text-2xl font-semibold theme-text overflow-hidden line-clamp-2"
      >
        {{ title() }}
      </p>
      <p
        [attr.data-theme]="theme()"
        class="theme-sub-text overflow-hidden line-clamp-2"
      >
        {{ content() }}
      </p>
    </div>
  </div>
}

<ng-template #context>
  @if (rowOptions(); as options) {
    @if (options.length > 0) {
      <app-menu cdkMenu>
        @for (option of options; track option) {
          <app-menu-option
            [icon]="option.icon"
            (click)="handleOption(option)"
            cdkMenuItem
            >{{ option.label }}</app-menu-option
          >
        }
      </app-menu>
    }
  }
</ng-template>
