import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputDatePicker } from '../../../input/date/picker/input-date-picker.component';
import { InputFormFieldAdapter } from '../../adapters/input-form-field.adapter';
import { DateFormField } from '../../auto-form.type';

@Component({
  selector: 'app-date-form-field',
  standalone: true,
  imports: [InputDatePicker],
  templateUrl: './date-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateFormFieldComponent,
      multi: true,
    },
  ],
})
export class DateFormFieldComponent extends InputFormFieldAdapter<
  DateFormField<any>
> {
  override ngOnInit(): void {
    super.ngOnInit();
  }
}
