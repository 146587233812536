<div class="relative item">
  <p
    (click)="handleClick()"
    class="relative z-10 text theme-text"
    [attr.data-theme]="theme()"
    [attr.data-text]="textColor()"
  >
    {{ day().format('DD') }}
  </p>
  @if (has()) {
  <div class="absolute bg-primary/20 w-full inset-0"></div>
  } @else if (selected()) {
  <div class="absolute bg-primary w-full inset-0 text-white"></div>
  }
</div>
