import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import dayjs from 'dayjs';
import { Badge } from 'libs/front-share/src/components/badge/badge.component';
import { CalendarEvent } from 'libs/front-share/src/components/calendar/calendar.types';
import { Divider } from 'libs/front-share/src/components/divider/divider.component';
import { Icon } from 'libs/front-share/src/components/icon/icon.component';
import { Menu } from 'libs/front-share/src/components/menu/menu.component';
import { MenuOption } from 'libs/front-share/src/components/menu/option/menu-option.component';

const EventType = {
  personal: '개인',
  center: '센터',
  therapy: '치료',
  counseling: '상담',
  lesson: '보강',
} as const;

@Component({
  selector: 'app-schedule-info-item',
  templateUrl: './schedule-info-item.component.html',
  imports: [
    Badge,
    Icon,
    Divider,
    CommonModule,
    Menu,
    MenuOption,
    CdkMenuModule,
  ],
})
export class ScheduleInfoItemComponent {
  dayjs = dayjs;
  event = input.required<CalendarEvent>();
  eventType = EventType;

  date = computed(() => {
    const event = this.event();
    const startDate = dayjs(event.startDate);
    const endDate = dayjs(event.endDate);

    if (event.allDay) {
      return '종일';
    }

    if (startDate.isSame(endDate, 'day')) {
      if (event.allDay) {
        return `${startDate.format('MM월 DD일')}`;
      }

      const startTime = startDate.format('HH:mm');
      const endTime = endDate.format('HH:mm');

      if (startTime === endTime) {
        return `${startDate.format('MM월 DD일')} ${startTime}`;
      }

      return `${startDate.format('MM월 DD일')} ${startTime} ~ ${endTime}`;
    }

    return '';
  });
}
