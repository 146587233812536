import { Component, input, output } from '@angular/core';
import { Icon } from '../../icon/icon.component';

@Component({
  selector: 'app-action-sheet-header',
  template: `
    <div class="flex justify-between w-full p-4">
      <div class="flex gap-2 items-center">
        @if (icon(); as icon) {
          <app-icon [name]="icon" class="text-primary" />
        }
        <p class="text-gray-800">
          <ng-content />
        </p>
      </div>
      <p (click)="close.emit()" class="text-gray-400 text-sm">취소</p>
    </div>
  `,
  imports: [Icon],
})
export class ActionSheetHeader {
  icon = input<string>();
  close = output<void>();
}
