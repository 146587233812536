/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OffsetPaginationDto } from '../../models/offset-pagination-dto';

export interface VoucherControllerSearchOffset$Params {

/**
 * 페이지 크기
 */
  pageSize: number;

/**
 * 정렬 기준
 */
  orderBy?: string;

/**
 * 정렬 방향
 */
  align?: 'asc' | 'desc';

/**
 * 검색어
 */
  query?: string;

/**
 * 페이지 번호
 */
  pageNo: number;
}

export function voucherControllerSearchOffset(http: HttpClient, rootUrl: string, params: VoucherControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
  const rb = new RequestBuilder(rootUrl, voucherControllerSearchOffset.PATH, 'get');
  if (params) {
    rb.query('pageSize', params.pageSize, {});
    rb.query('orderBy', params.orderBy, {});
    rb.query('align', params.align, {});
    rb.query('query', params.query, {});
    rb.query('pageNo', params.pageNo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OffsetPaginationDto>;
    })
  );
}

voucherControllerSearchOffset.PATH = '/api/voucher/search/offset';
