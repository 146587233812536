/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { keywordControllerCreateDepth1V1 } from '../fn/keyword/keyword-controller-create-depth-1-v-1';
import { KeywordControllerCreateDepth1V1$Params } from '../fn/keyword/keyword-controller-create-depth-1-v-1';
import { keywordControllerCreateDepth2V1 } from '../fn/keyword/keyword-controller-create-depth-2-v-1';
import { KeywordControllerCreateDepth2V1$Params } from '../fn/keyword/keyword-controller-create-depth-2-v-1';
import { keywordControllerDeleteV1 } from '../fn/keyword/keyword-controller-delete-v-1';
import { KeywordControllerDeleteV1$Params } from '../fn/keyword/keyword-controller-delete-v-1';
import { keywordControllerFindOneV1 } from '../fn/keyword/keyword-controller-find-one-v-1';
import { KeywordControllerFindOneV1$Params } from '../fn/keyword/keyword-controller-find-one-v-1';
import { keywordControllerSearchMyDataV1 } from '../fn/keyword/keyword-controller-search-my-data-v-1';
import { KeywordControllerSearchMyDataV1$Params } from '../fn/keyword/keyword-controller-search-my-data-v-1';
import { KeywordDto } from '../models/keyword-dto';

@Injectable({ providedIn: 'root' })
export class KeywordService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `keywordControllerSearchMyDataV1()` */
  static readonly KeywordControllerSearchMyDataV1Path = '/api/v1/keyword/search';

  /**
   * 내 키워드 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordControllerSearchMyDataV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordControllerSearchMyDataV1$Response(params: KeywordControllerSearchMyDataV1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KeywordDto>>> {
    return keywordControllerSearchMyDataV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 키워드 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordControllerSearchMyDataV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordControllerSearchMyDataV1(params: KeywordControllerSearchMyDataV1$Params, context?: HttpContext): Observable<Array<KeywordDto>> {
    return this.keywordControllerSearchMyDataV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KeywordDto>>): Array<KeywordDto> => r.body)
    );
  }

  /** Path part for operation `keywordControllerFindOneV1()` */
  static readonly KeywordControllerFindOneV1Path = '/api/v1/keyword/{id}';

  /**
   * 키워드 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordControllerFindOneV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordControllerFindOneV1$Response(params: KeywordControllerFindOneV1$Params, context?: HttpContext): Observable<StrictHttpResponse<KeywordDto>> {
    return keywordControllerFindOneV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 키워드 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordControllerFindOneV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordControllerFindOneV1(params: KeywordControllerFindOneV1$Params, context?: HttpContext): Observable<KeywordDto> {
    return this.keywordControllerFindOneV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<KeywordDto>): KeywordDto => r.body)
    );
  }

  /** Path part for operation `keywordControllerDeleteV1()` */
  static readonly KeywordControllerDeleteV1Path = '/api/v1/keyword/{id}';

  /**
   * 키워드 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordControllerDeleteV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordControllerDeleteV1$Response(params: KeywordControllerDeleteV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return keywordControllerDeleteV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 키워드 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordControllerDeleteV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordControllerDeleteV1(params: KeywordControllerDeleteV1$Params, context?: HttpContext): Observable<boolean> {
    return this.keywordControllerDeleteV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `keywordControllerCreateDepth2V1()` */
  static readonly KeywordControllerCreateDepth2V1Path = '/api/v1/keyword/{id}';

  /**
   * 2뎁스 키워드 생성.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordControllerCreateDepth2V1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  keywordControllerCreateDepth2V1$Response(params: KeywordControllerCreateDepth2V1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return keywordControllerCreateDepth2V1(this.http, this.rootUrl, params, context);
  }

  /**
   * 2뎁스 키워드 생성.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordControllerCreateDepth2V1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  keywordControllerCreateDepth2V1(params: KeywordControllerCreateDepth2V1$Params, context?: HttpContext): Observable<boolean> {
    return this.keywordControllerCreateDepth2V1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `keywordControllerCreateDepth1V1()` */
  static readonly KeywordControllerCreateDepth1V1Path = '/api/v1/keyword';

  /**
   * 1뎁스 키워드 생성.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordControllerCreateDepth1V1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  keywordControllerCreateDepth1V1$Response(params: KeywordControllerCreateDepth1V1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return keywordControllerCreateDepth1V1(this.http, this.rootUrl, params, context);
  }

  /**
   * 1뎁스 키워드 생성.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordControllerCreateDepth1V1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  keywordControllerCreateDepth1V1(params: KeywordControllerCreateDepth1V1$Params, context?: HttpContext): Observable<boolean> {
    return this.keywordControllerCreateDepth1V1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
