import { CommonModule } from '@angular/common';
import { Component, computed, input, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Icon } from '../../icon/icon.component';
import { BaseInput } from '../base-input';
import { Input } from '../input.component';

@Component({
  selector: 'app-input-password',
  standalone: true,
  imports: [CommonModule, Input, Icon],
  template: `
    <app-input
      [color]="currentColor()"
      [size]="currentSize()"
      [rounded]="currentRounded()"
      [label]="label()"
      [required]="required()"
      [placeholder]="placeholder()"
      [spellcheck]="spellcheck()"
      [autocomplete]="autocomplete()"
      [autofocus]="autofocus()"
      [readonly]="readonly()"
      [type]="currentType()"
      [(value)]="value"
    >
      @if (!hideIcon()) {
        <app-icon
          size="sm"
          [attr.data-theme]="theme()"
          (click)="showPassword.set(!showPassword())"
          class="cursor-pointer theme-text"
          icons
          [name]="icon()"
        />
      }
    </app-input>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputPassword,
      multi: true,
    },
  ],
})
export class InputPassword
  extends BaseInput<string>
  implements ControlValueAccessor
{
  showPassword = signal(false);
  hideIcon = input<boolean>(false);
  icon = computed(() => (this.showPassword() ? 'mdi:eye-off' : 'mdi:eye'));
  currentType = computed(() => (this.showPassword() ? 'text' : 'password'));
}
