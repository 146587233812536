/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { kakaoAuthControllerConnect } from '../fn/kakao/kakao-auth-controller-connect';
import { KakaoAuthControllerConnect$Params } from '../fn/kakao/kakao-auth-controller-connect';
import { kakaoAuthControllerLogin } from '../fn/kakao/kakao-auth-controller-login';
import { KakaoAuthControllerLogin$Params } from '../fn/kakao/kakao-auth-controller-login';
import { OAuthResponseDto } from '../models/o-auth-response-dto';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class KakaoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `kakaoAuthControllerLogin()` */
  static readonly KakaoAuthControllerLoginPath = '/api/oauth/kakao/signin';

  /**
   * 카카오 로그인.
   *
   * 카카오 로그인을 수행합니다
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kakaoAuthControllerLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  kakaoAuthControllerLogin$Response(params: KakaoAuthControllerLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<OAuthResponseDto>> {
    return kakaoAuthControllerLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 카카오 로그인.
   *
   * 카카오 로그인을 수행합니다
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kakaoAuthControllerLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  kakaoAuthControllerLogin(params: KakaoAuthControllerLogin$Params, context?: HttpContext): Observable<OAuthResponseDto> {
    return this.kakaoAuthControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<OAuthResponseDto>): OAuthResponseDto => r.body)
    );
  }

  /** Path part for operation `kakaoAuthControllerConnect()` */
  static readonly KakaoAuthControllerConnectPath = '/api/oauth/kakao/connect';

  /**
   * 카카오 계정 연결.
   *
   * 기존 계정에 소셜 계정을 연결합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kakaoAuthControllerConnect()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kakaoAuthControllerConnect$Response(params: KakaoAuthControllerConnect$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return kakaoAuthControllerConnect(this.http, this.rootUrl, params, context);
  }

  /**
   * 카카오 계정 연결.
   *
   * 기존 계정에 소셜 계정을 연결합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kakaoAuthControllerConnect$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kakaoAuthControllerConnect(params: KakaoAuthControllerConnect$Params, context?: HttpContext): Observable<UserDto> {
    return this.kakaoAuthControllerConnect$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

}
