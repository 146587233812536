import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputNumber } from '../../../input/number/input-number.component';
import { InputFormFieldAdapter } from '../../adapters/input-form-field.adapter';
import { NumberFormField } from '../../auto-form.type';

@Component({
  selector: 'app-number-form-field',
  standalone: true,
  imports: [InputNumber],
  templateUrl: './number-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumberFormFieldComponent,
      multi: true,
    },
  ],
})
export class NumberFormFieldComponent extends InputFormFieldAdapter<
  NumberFormField<any>
> {}
