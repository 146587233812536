export const GuardianRole = {
  MAIN: '주 보호자', // 주 보호자
  SUB: '부 보호자', // 부 보호자
} as const;

export const FriendShip = {
  HIGH: '상',
  MIDDLE: '중',
  LOW: '하',
} as const;

export const Relationship = {
  FATHER: '부',
  MOTHER: '모',
  RELATIVE: '친인척',
  OTHER: '기타',
} as const;
