/* eslint-disable @nx/enforce-module-boundaries */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  computed,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { OptionDirective } from 'libs/front-share/src/directives/option.directive';
import { Icon } from '../../icon/icon.component';
import { ActionSheetOption } from '../action-sheet.type';

@Component({
  selector: 'app-action-sheet-option',
  templateUrl: './action-sheet-option.component.html',
  imports: [OptionDirective, Icon],
})
export class ActionSheetOptionComponent implements AfterViewInit {
  label = computed(() => this.option().label);
  value = computed(() => this.option().value);
  active = computed(() => this.currentValue() === this.value());

  option = input.required<ActionSheetOption>();
  renderItem = input<any>();
  currentValue = input<any>();

  directiveRef = viewChild(OptionDirective);
  changeRef = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    if (this.renderItem()) {
      const viewContainerRef = this.directiveRef()?.viewContainerRef;
      viewContainerRef?.clear();
      const componentRef = viewContainerRef?.createComponent(this.renderItem());
      componentRef?.setInput('option', this.option());
      componentRef?.setInput('label', this.label());
      componentRef?.setInput('value', this.value());
      componentRef?.setInput('active', this.active());

      this.changeRef.detectChanges();
    }
  }
}
