<section [attr.data-theme]="theme()" class="grid-section">
  <app-input
    size="sm"
    [debounce]="options()?.searchOption?.debounce || 300"
    [(value)]="query"
    class="md:min-w-[30rem]"
    icon="mdi:search"
    placeholder="검색어를 입력해주세요"
  />
  <div class="flex items-center gap-2">
    <ng-content name="[buttons]" />
    <button
      (click)="exportExcel()"
      [ngClass]="
        theme() === 'dark' ? 'hover:bg-neutral-700' : 'hover:bg-neutral-300'
      "
      class="p-1.5 transition-colors rounded"
    >
      <img
        class="w-6 h-6"
        src="https://api.iconify.design/vscode-icons:file-type-excel2.svg"
      />
    </button>
  </div>
</section>
@if (currentOrderBy()) {
  <section
    [attr.data-theme]="theme()"
    class="border-t grid-section theme-border"
  >
    <div class="flex items-center gap-2">
      <app-badge>
        <div class="flex items-center gap-1">
          <app-icon
            size="sm"
            [name]="
              align() === 'asc'
                ? 'ic:outline-arrow-upward'
                : 'ic:outline-arrow-downward'
            "
          />
          {{ currentOrderBy() }}
        </div>
      </app-badge>
      @if (selecteds() && selecteds()!.length > 0) {
        <app-badge>선택된 갯수 : {{ selecteds()!.length }}</app-badge>
      }
    </div>
    <app-icon-button
      [cdkMenuTriggerFor]="menu"
      [attr.data-theme]="theme()"
      class="theme-text"
      icon="mdi:dots-vertical"
      size="sm"
    />
  </section>
}

<ng-template #menu>
  <app-menu cdkMenu>
    @for (option of aligns; track $index) {
      <div class="relative group">
        <app-menu-option icon="ic:baseline-chevron-left">
          <div class="flex items-center justify-end w-full gap-2">
            <p>{{ option === 'desc' ? '내림차순' : '오름차순' }}</p>
            <app-icon
              [name]="
                option === 'desc'
                  ? 'ic:outline-arrow-downward'
                  : 'ic:outline-arrow-upward'
              "
              size="sm"
            />
          </div>
        </app-menu-option>
        <app-menu
          class="absolute right-[calc(100%)] top-0 hidden group-hover:block"
        >
          @for (column of columns(); track column.field) {
            @if (column.field !== 'rowNumber' && column.field !== 'checkbox') {
              <app-menu-option
                (click)="handleOrderBy(option, column.alias || column.field)"
                cdkMenuItem
                [active]="
                  column.name === currentOrderBy() && align() === option
                "
                >{{ column.name }}</app-menu-option
              >
            }
          }
        </app-menu>
      </div>
    }
  </app-menu>
</ng-template>
