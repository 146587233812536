import { Component, computed, HostBinding, input } from '@angular/core';
import { Color, Size } from '../common/types';

@Component({
  selector: 'app-icon',
  standalone: true,
  template: ``,
  styleUrl: './icon.component.scss',
  host: {
    '[attr.data-size]': 'size()',
    '[style]': 'style()',
    '[ngClass]': 'class()',
  },
})
export class Icon {
  @HostBinding('class') get hostClass() {
    return this.$class();
  }

  name = input.required<string>();

  _name = computed(() =>
    encodeURI(`https://api.iconify.design/${this.name()}.svg`),
  );

  size = input<Size>('md');
  color = input<Color>('primary');
  $class = input<string>('', { alias: 'class' });

  style = computed(() => ({
    maskSize: 'cover',
    maskImage: `url(${this._name()})`,
    webkitMaskSize: 'cover',
    webkitMaskImage: `url(${this._name()})`,
    display: 'inline-block',
    background: 'currentColor',
  }));

  class = computed(
    () =>
      `${this.size() ? `${this.size()}` : ''}  ${
        this.color() ? this.color() : ''
      }`,
  );
}
