/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { guardianControllerChangeMainGuardian } from '../fn/guardian/guardian-controller-change-main-guardian';
import { GuardianControllerChangeMainGuardian$Params } from '../fn/guardian/guardian-controller-change-main-guardian';
import { guardianControllerChangeMainGuardianByParent } from '../fn/guardian/guardian-controller-change-main-guardian-by-parent';
import { GuardianControllerChangeMainGuardianByParent$Params } from '../fn/guardian/guardian-controller-change-main-guardian-by-parent';
import { guardianControllerConnectChildParent } from '../fn/guardian/guardian-controller-connect-child-parent';
import { GuardianControllerConnectChildParent$Params } from '../fn/guardian/guardian-controller-connect-child-parent';
import { guardianControllerCreate } from '../fn/guardian/guardian-controller-create';
import { GuardianControllerCreate$Params } from '../fn/guardian/guardian-controller-create';
import { guardianControllerDelete } from '../fn/guardian/guardian-controller-delete';
import { GuardianControllerDelete$Params } from '../fn/guardian/guardian-controller-delete';
import { guardianControllerFindAll } from '../fn/guardian/guardian-controller-find-all';
import { GuardianControllerFindAll$Params } from '../fn/guardian/guardian-controller-find-all';
import { guardianControllerFindAllByChildId } from '../fn/guardian/guardian-controller-find-all-by-child-id';
import { GuardianControllerFindAllByChildId$Params } from '../fn/guardian/guardian-controller-find-all-by-child-id';
import { guardianControllerFindById } from '../fn/guardian/guardian-controller-find-by-id';
import { GuardianControllerFindById$Params } from '../fn/guardian/guardian-controller-find-by-id';
import { guardianControllerManualCreate } from '../fn/guardian/guardian-controller-manual-create';
import { GuardianControllerManualCreate$Params } from '../fn/guardian/guardian-controller-manual-create';
import { guardianControllerSearchByParent } from '../fn/guardian/guardian-controller-search-by-parent';
import { GuardianControllerSearchByParent$Params } from '../fn/guardian/guardian-controller-search-by-parent';
import { guardianControllerSearchCursor } from '../fn/guardian/guardian-controller-search-cursor';
import { GuardianControllerSearchCursor$Params } from '../fn/guardian/guardian-controller-search-cursor';
import { guardianControllerSearchOffset } from '../fn/guardian/guardian-controller-search-offset';
import { GuardianControllerSearchOffset$Params } from '../fn/guardian/guardian-controller-search-offset';
import { guardianControllerUpdate } from '../fn/guardian/guardian-controller-update';
import { GuardianControllerUpdate$Params } from '../fn/guardian/guardian-controller-update';
import { GuardianDto } from '../models/guardian-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { SearchGuardianMemoResponseDto } from '../models/search-guardian-memo-response-dto';

@Injectable({ providedIn: 'root' })
export class GuardianService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `guardianControllerFindAll()` */
  static readonly GuardianControllerFindAllPath = '/api/guardian';

  /**
   * 보호자 전체 조회.
   *
   * 보호자 전체를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerFindAll$Response(params?: GuardianControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GuardianDto>>> {
    return guardianControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 전체 조회.
   *
   * 보호자 전체를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerFindAll(params?: GuardianControllerFindAll$Params, context?: HttpContext): Observable<Array<GuardianDto>> {
    return this.guardianControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GuardianDto>>): Array<GuardianDto> => r.body)
    );
  }

  /** Path part for operation `guardianControllerCreate()` */
  static readonly GuardianControllerCreatePath = '/api/guardian';

  /**
   * 보호자 생성.
   *
   * 아동과 보호자를 연결합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guardianControllerCreate$Response(params: GuardianControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return guardianControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 생성.
   *
   * 아동과 보호자를 연결합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guardianControllerCreate(params: GuardianControllerCreate$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.guardianControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `guardianControllerFindAllByChildId()` */
  static readonly GuardianControllerFindAllByChildIdPath = '/api/guardian/child';

  /**
   * 아동의 보호자 전체 조회.
   *
   * 아동의 보호자를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerFindAllByChildId()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerFindAllByChildId$Response(params: GuardianControllerFindAllByChildId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GuardianDto>>> {
    return guardianControllerFindAllByChildId(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동의 보호자 전체 조회.
   *
   * 아동의 보호자를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerFindAllByChildId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerFindAllByChildId(params: GuardianControllerFindAllByChildId$Params, context?: HttpContext): Observable<Array<GuardianDto>> {
    return this.guardianControllerFindAllByChildId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GuardianDto>>): Array<GuardianDto> => r.body)
    );
  }

  /** Path part for operation `guardianControllerSearchByParent()` */
  static readonly GuardianControllerSearchByParentPath = '/api/guardian/search-by-parent';

  /**
   * 보호자가 내 아동의 다른 보호자 검색 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerSearchByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerSearchByParent$Response(params: GuardianControllerSearchByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchGuardianMemoResponseDto>> {
    return guardianControllerSearchByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 다른 보호자 검색 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerSearchByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerSearchByParent(params: GuardianControllerSearchByParent$Params, context?: HttpContext): Observable<SearchGuardianMemoResponseDto> {
    return this.guardianControllerSearchByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchGuardianMemoResponseDto>): SearchGuardianMemoResponseDto => r.body)
    );
  }

  /** Path part for operation `guardianControllerSearchOffset()` */
  static readonly GuardianControllerSearchOffsetPath = '/api/guardian/search/offset';

  /**
   * 보호자 오프셋 기반 조회.
   *
   * 보호자을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerSearchOffset$Response(params: GuardianControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return guardianControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 오프셋 기반 조회.
   *
   * 보호자을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerSearchOffset(params: GuardianControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.guardianControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `guardianControllerSearchCursor()` */
  static readonly GuardianControllerSearchCursorPath = '/api/guardian/search/cursor';

  /**
   * 보호자 커서 기반 조회.
   *
   * 보호자을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerSearchCursor$Response(params: GuardianControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return guardianControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 커서 기반 조회.
   *
   * 보호자을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerSearchCursor(params: GuardianControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.guardianControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `guardianControllerFindById()` */
  static readonly GuardianControllerFindByIdPath = '/api/guardian/{id}';

  /**
   * 보호자 ID 기반 조회.
   *
   * 보호자을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerFindById$Response(params: GuardianControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return guardianControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 ID 기반 조회.
   *
   * 보호자을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerFindById(params: GuardianControllerFindById$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.guardianControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `guardianControllerDelete()` */
  static readonly GuardianControllerDeletePath = '/api/guardian/{id}';

  /**
   * 보호자 삭제.
   *
   * 보호자을(를) 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerDelete$Response(params: GuardianControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return guardianControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 삭제.
   *
   * 보호자을(를) 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerDelete(params: GuardianControllerDelete$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.guardianControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `guardianControllerUpdate()` */
  static readonly GuardianControllerUpdatePath = '/api/guardian/{id}';

  /**
   * 보호자 수정.
   *
   * 보호자을(를) 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guardianControllerUpdate$Response(params: GuardianControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return guardianControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 수정.
   *
   * 보호자을(를) 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guardianControllerUpdate(params: GuardianControllerUpdate$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.guardianControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `guardianControllerManualCreate()` */
  static readonly GuardianControllerManualCreatePath = '/api/guardian/manual';

  /**
   * 보호자 생성.
   *
   * 아동과 보호자를 연결합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerManualCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guardianControllerManualCreate$Response(params: GuardianControllerManualCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return guardianControllerManualCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 생성.
   *
   * 아동과 보호자를 연결합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerManualCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guardianControllerManualCreate(params: GuardianControllerManualCreate$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.guardianControllerManualCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `guardianControllerConnectChildParent()` */
  static readonly GuardianControllerConnectChildParentPath = '/api/guardian/connect-child-parent';

  /**
   * 아동 보호자 연결.
   *
   * 아동과 보호자를 연결합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerConnectChildParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guardianControllerConnectChildParent$Response(params: GuardianControllerConnectChildParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return guardianControllerConnectChildParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 보호자 연결.
   *
   * 아동과 보호자를 연결합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerConnectChildParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guardianControllerConnectChildParent(params: GuardianControllerConnectChildParent$Params, context?: HttpContext): Observable<boolean> {
    return this.guardianControllerConnectChildParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `guardianControllerChangeMainGuardianByParent()` */
  static readonly GuardianControllerChangeMainGuardianByParentPath = '/api/guardian/change/main-guardian-by-parent';

  /**
   * 보호자가 내 아동의 주보호자를 변경.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerChangeMainGuardianByParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guardianControllerChangeMainGuardianByParent$Response(params: GuardianControllerChangeMainGuardianByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return guardianControllerChangeMainGuardianByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 주보호자를 변경.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerChangeMainGuardianByParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guardianControllerChangeMainGuardianByParent(params: GuardianControllerChangeMainGuardianByParent$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.guardianControllerChangeMainGuardianByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `guardianControllerChangeMainGuardian()` */
  static readonly GuardianControllerChangeMainGuardianPath = '/api/guardian/change';

  /**
   * 주 보호자 변경.
   *
   * 주 보호자를 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guardianControllerChangeMainGuardian()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerChangeMainGuardian$Response(params: GuardianControllerChangeMainGuardian$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return guardianControllerChangeMainGuardian(this.http, this.rootUrl, params, context);
  }

  /**
   * 주 보호자 변경.
   *
   * 주 보호자를 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guardianControllerChangeMainGuardian$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  guardianControllerChangeMainGuardian(params: GuardianControllerChangeMainGuardian$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.guardianControllerChangeMainGuardian$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

}
