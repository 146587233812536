<div class="item">
  <p
    class="text theme-text rounded-full"
    [attr.data-theme]="theme()"
    [attr.data-text]="textColor()"
    [attr.data-background]="backgroundColor()"
  >
    {{ day().format('DD') }}
  </p>
</div>
