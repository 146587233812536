import { CommonModule } from "@angular/common";
import {
  Component,
  inject,
  input,
  OnInit,
  output,
  signal,
} from "@angular/core";
import dayjs from "dayjs";
import { ClickOutside } from "ngxtension/click-outside";
import { InputCalendarSelect } from "./select/calendar-select.component";
import { RangeDay } from "./range-day/range-day.component";
import { Day } from "./day/day.component";
import { InputCalendarMode, InputCalendarStore } from "./calendar.store";
import { BaseConfig } from "../../../common/config/config.adapter";
import { IconButton } from "../../../button/icon-button.component";

export type CalendarOutput = {
  start: Date | null;
  end: Date | null;
  value: Date | null;
};

@Component({
  host: {
    ngSkipHydration: "true",
  },
  selector: "app-input-calendar",
  standalone: true,
  imports: [
    CommonModule,
    Day,
    RangeDay,
    InputCalendarSelect,
    ClickOutside,
    IconButton,
  ],
  templateUrl: "./calendar.component.html",
  styleUrl: "./calendar.component.scss",
  providers: [InputCalendarStore],
})
export class InputCalendar extends BaseConfig implements OnInit {
  readonly store = inject(InputCalendarStore);

  mode = input<InputCalendarMode>("pick"); // pick과 range 두 가지 모드
  date = input<Date | null>(null);
  startDate = input<Date | null>(null);
  endDate = input<Date | null>(null);
  isOpen = signal<boolean>(false); // 년도와 달을 선택하는 섹션 띄우는 flag
  select = output<CalendarOutput>();

  value = this.store.value;
  days = this.store.days;
  start = this.store.start;
  end = this.store.end;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.handleDefaultData();

    this.store.setValue(this.value()!);
    this.store.setMode(this.mode());
  }

  /**
   * @description input으로 받은 date를 세팅
   */
  handleDefaultData() {
    if (this.mode() === "pick") {
      this.store.setValue(this.date()!);
    } else {
      this.store.setStart(this.startDate()!);
      this.store.setEnd(this.endDate()!);
    }
  }

  /**
   * @description 년도와 달을 이동하는 통합 함수
   * @param type
   * @param unit
   * @param number
   */
  handleNavigate(
    type: "prev" | "next",
    unit: "year" | "month",
    number: number,
  ) {
    if (type === "prev") {
      const value = dayjs(this.value()).subtract(number, unit).toDate();
      this.store.setValue(value);
    } else {
      const value = dayjs(this.value()).add(number, unit).toDate();
      this.store.setValue(value);
    }
  }

  /**
   * @description 년도와 달을 선택했을 경우 이벤트 핸들링
   * @param ev
   */
  handleSelectMonthAndYear(ev: Date) {
    this.store.setValue(ev);
    this.isOpen.set(false);
  }

  /**
   * @description 날짜를 선택했을 경우 이벤트 핸들링
   * @param ev
   */
  handleSelectDay() {
    this.select.emit({
      start: this.start() || null,
      end: this.end() || null,
      value: this.value() || null,
    });
  }
}
