/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OffsetPaginationDto } from '../../models/offset-pagination-dto';

export interface CenterChildControllerSearch$Params {

/**
 * 페이지 크기
 */
  pageSize: number;

/**
 * 정렬 기준
 */
  orderBy?: string;

/**
 * 정렬 방향
 */
  align?: 'asc' | 'desc';

/**
 * 검색어
 */
  query?: string;

/**
 * 페이지 번호
 */
  pageNo: number;

/**
 * 센터 User ID
 */
  centerId?: string;

/**
 * 보호자 User ID
 */
  parentId?: string;

/**
 * 보호자 ID
 */
  guardianId?: string;

/**
 * 로그인 email
 */
  email?: string;

/**
 * 검색어 대상
 */
  queryTarget?: 'GUARDIAN' | 'CHILD' | 'ADDRESS';

/**
 * 아동 태그
 */
  tagIds?: Array<string>;

/**
 * 담당 선생님
 */
  teacherIds?: Array<string>;

/**
 * 바우처
 */
  vouchers?: Array<string>;

/**
 * 치료 상태
 */
  statusList?: Array<'BEFORE_COUNSELING' | 'PENDING' | 'PROGRESS' | 'COMPLETED'>;

/**
 * 치료 유형
 */
  types?: Array<string>;

/**
 * 아동 성별
 */
  gender?: 'MALE' | 'FEMALE';
}

export function centerChildControllerSearch(http: HttpClient, rootUrl: string, params: CenterChildControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
  const rb = new RequestBuilder(rootUrl, centerChildControllerSearch.PATH, 'get');
  if (params) {
    rb.query('pageSize', params.pageSize, {});
    rb.query('orderBy', params.orderBy, {});
    rb.query('align', params.align, {});
    rb.query('query', params.query, {});
    rb.query('pageNo', params.pageNo, {});
    rb.query('centerId', params.centerId, {});
    rb.query('parentId', params.parentId, {});
    rb.query('guardianId', params.guardianId, {});
    rb.query('email', params.email, {});
    rb.query('queryTarget', params.queryTarget, {});
    rb.query('tagIds', params.tagIds, {});
    rb.query('teacherIds', params.teacherIds, {});
    rb.query('vouchers', params.vouchers, {});
    rb.query('statusList', params.statusList, {});
    rb.query('types', params.types, {});
    rb.query('gender', params.gender, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OffsetPaginationDto>;
    })
  );
}

centerChildControllerSearch.PATH = '/api/child/center-child/search';
