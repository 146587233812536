<section #weekRef class="relative flex w-full">
  @for (day of days(); track $index) {
    <div
      class="day-wrapper"
      [attr.data-theme]="theme()"
      [class.weekend]="$index === 6 || $index === 0"
      dndDropzone
      dndDragoverClass="drag-over"
      (dndDrop)="drop($event, day)"
    >
      <app-day [day]="day" [height]="height() || 200" class="md:block hidden" />
      <app-day [day]="day" [height]="80" class="md:hidden" />
    </div>
  }
  @for (event of renderEvents(); track event.id) {
    <app-block-calendar-event
      #eventRef
      class="calendar-event-container md:block hidden"
      (menu)="handleEventMenu($event, event)"
      [days]="days()"
      [event]="event"
      [index]="$index"
      [weekIndex]="index()"
      [width]="width() / 7"
    />
  }
</section>
