/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { enrollmentControllerCreate } from '../fn/enrollment/enrollment-controller-create';
import { EnrollmentControllerCreate$Params } from '../fn/enrollment/enrollment-controller-create';
import { enrollmentControllerDelete } from '../fn/enrollment/enrollment-controller-delete';
import { EnrollmentControllerDelete$Params } from '../fn/enrollment/enrollment-controller-delete';
import { enrollmentControllerFindAll } from '../fn/enrollment/enrollment-controller-find-all';
import { EnrollmentControllerFindAll$Params } from '../fn/enrollment/enrollment-controller-find-all';
import { enrollmentControllerFindById } from '../fn/enrollment/enrollment-controller-find-by-id';
import { EnrollmentControllerFindById$Params } from '../fn/enrollment/enrollment-controller-find-by-id';
import { enrollmentControllerSearchCursor } from '../fn/enrollment/enrollment-controller-search-cursor';
import { EnrollmentControllerSearchCursor$Params } from '../fn/enrollment/enrollment-controller-search-cursor';
import { enrollmentControllerSearchOffset } from '../fn/enrollment/enrollment-controller-search-offset';
import { EnrollmentControllerSearchOffset$Params } from '../fn/enrollment/enrollment-controller-search-offset';
import { enrollmentControllerUpdate } from '../fn/enrollment/enrollment-controller-update';
import { EnrollmentControllerUpdate$Params } from '../fn/enrollment/enrollment-controller-update';
import { EnrollmentDto } from '../models/enrollment-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class EnrollmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `enrollmentControllerFindAll()` */
  static readonly EnrollmentControllerFindAllPath = '/api/enrollment';

  /**
   * 학적 전체 조회.
   *
   * 학적 전체를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentControllerFindAll$Response(params?: EnrollmentControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EnrollmentDto>>> {
    return enrollmentControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 학적 전체 조회.
   *
   * 학적 전체를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentControllerFindAll(params?: EnrollmentControllerFindAll$Params, context?: HttpContext): Observable<Array<EnrollmentDto>> {
    return this.enrollmentControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EnrollmentDto>>): Array<EnrollmentDto> => r.body)
    );
  }

  /** Path part for operation `enrollmentControllerCreate()` */
  static readonly EnrollmentControllerCreatePath = '/api/enrollment';

  /**
   * 학적 생성.
   *
   * 학적을(를) 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentControllerCreate$Response(params: EnrollmentControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<EnrollmentDto>> {
    return enrollmentControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 학적 생성.
   *
   * 학적을(를) 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentControllerCreate(params: EnrollmentControllerCreate$Params, context?: HttpContext): Observable<EnrollmentDto> {
    return this.enrollmentControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnrollmentDto>): EnrollmentDto => r.body)
    );
  }

  /** Path part for operation `enrollmentControllerSearchOffset()` */
  static readonly EnrollmentControllerSearchOffsetPath = '/api/enrollment/search/offset';

  /**
   * 학적 오프셋 기반 조회.
   *
   * 학적을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentControllerSearchOffset$Response(params: EnrollmentControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return enrollmentControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 학적 오프셋 기반 조회.
   *
   * 학적을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentControllerSearchOffset(params: EnrollmentControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.enrollmentControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `enrollmentControllerSearchCursor()` */
  static readonly EnrollmentControllerSearchCursorPath = '/api/enrollment/search/cursor';

  /**
   * 학적 커서 기반 조회.
   *
   * 학적을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentControllerSearchCursor$Response(params: EnrollmentControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return enrollmentControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 학적 커서 기반 조회.
   *
   * 학적을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentControllerSearchCursor(params: EnrollmentControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.enrollmentControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `enrollmentControllerFindById()` */
  static readonly EnrollmentControllerFindByIdPath = '/api/enrollment/{id}';

  /**
   * 학적 ID 기반 조회.
   *
   * 학적을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentControllerFindById$Response(params: EnrollmentControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<EnrollmentDto>> {
    return enrollmentControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 학적 ID 기반 조회.
   *
   * 학적을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentControllerFindById(params: EnrollmentControllerFindById$Params, context?: HttpContext): Observable<EnrollmentDto> {
    return this.enrollmentControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnrollmentDto>): EnrollmentDto => r.body)
    );
  }

  /** Path part for operation `enrollmentControllerDelete()` */
  static readonly EnrollmentControllerDeletePath = '/api/enrollment/{id}';

  /**
   * 학적 삭제.
   *
   * 학적을(를) 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentControllerDelete$Response(params: EnrollmentControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<EnrollmentDto>> {
    return enrollmentControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 학적 삭제.
   *
   * 학적을(를) 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enrollmentControllerDelete(params: EnrollmentControllerDelete$Params, context?: HttpContext): Observable<EnrollmentDto> {
    return this.enrollmentControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnrollmentDto>): EnrollmentDto => r.body)
    );
  }

  /** Path part for operation `enrollmentControllerUpdate()` */
  static readonly EnrollmentControllerUpdatePath = '/api/enrollment/{id}';

  /**
   * 학적 수정.
   *
   * 학적을(를) 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enrollmentControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentControllerUpdate$Response(params: EnrollmentControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EnrollmentDto>> {
    return enrollmentControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 학적 수정.
   *
   * 학적을(를) 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enrollmentControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enrollmentControllerUpdate(params: EnrollmentControllerUpdate$Params, context?: HttpContext): Observable<EnrollmentDto> {
    return this.enrollmentControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnrollmentDto>): EnrollmentDto => r.body)
    );
  }

}
