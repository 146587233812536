<section class="flex flex-col gap-4 md:gap-6">
  <section
    [attr.data-theme]="theme()"
    class="flex flex-col overflow-hidden border theme-border"
  >
    <div
      class="grid w-full grid-cols-7"
      [ngClass]="theme() === 'dark' ? 'bg-dark-section' : 'bg-gray-100'"
    >
      @for (day of ['일', '월', '화', '수', '목', '금', '토']; track day) {
        <p [attr.data-theme]="theme()" class="theme-text item">{{ day }}</p>
      }
    </div>

    <div class="flex flex-col w-full">
      @for (days of renderDays(); track $index) {
        <app-week [days]="days" [index]="$index" />
      }
    </div>
  </section>
  @if (options()?.mobile?.showEventList) {
    <app-event-list class="md:hidden" />
  }
</section>
