/* eslint-disable @nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { Component, computed, input, model, output } from '@angular/core';
import dayjs from 'dayjs';
import { CalendarDayClickOutput } from 'libs/front-share/src/components/calendar/calendar.types';
import { Icon } from 'libs/front-share/src/components/icon/icon.component';
import { InputSearchComponent } from 'libs/front-share/src/components/input-search/input-search.component';
import { ModalHeader } from 'libs/front-share/src/components/modal/modal-header.component';
import { SLIDE_X } from '../../../../../animations/slide.animation';
import { ScheduleInfoItemComponent } from './item/schedule-info-item.component';

@Component({
  selector: 'app-schedule-info',
  templateUrl: './schedule-info.component.html',
  imports: [
    Icon,
    CommonModule,
    InputSearchComponent,
    ScheduleInfoItemComponent,
    ModalHeader,
  ],
  animations: [SLIDE_X],
})
export class ScheduleInfoComponent {
  item = input.required<CalendarDayClickOutput>();
  open = model<boolean>(false);
  dayjs = dayjs;
  query = model<string>('');

  close = output<void>();

  events = computed(() => {
    const query = this.query()?.toLowerCase();
    const array = query
      ? this.item()?.events.filter((event) => {
          return event.title.toLowerCase().includes(query);
        })
      : this.item()?.events;

    return array.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
  });
}
