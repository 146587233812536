<div class="flex flex-col gap-2">
  @if (label(); as label) {
    <app-label [required]="required()">{{ label }}</app-label>
  }
  @if (hint(); as hint) {
    <app-hint>{{ hint }}</app-hint>
  }
  <div
    class="flex w-full gap-6"
    [class.flex-row]="direction() === 'row'"
    [class.flex-col]="direction() === 'column'"
  >
    @if (isLoading()) {
      <app-spinner class="z-10" />
    } @else {
      @if (items().length !== maxlength()) {
        <div class="flex flex-col w-full gap-2 min-w-max">
          <div
            class="p-4 min-w-[20rem] gap-4 border-2 border-gray-200 border-dashed rounded-lg flex flex-col items-center justify-center hover:border-primary hover:bg-primary-50 transition-all cursor-pointer"
            [class.bg-primary-50]="isDragOvered()"
            [class.border-primary]="isDragOvered()"
            [class.bg-white]="!isDragOvered()"
            [ngClass]="{ 'min-h-[18.75rem]': direction() === 'row' }"
            (click)="openFileSelect()"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onFileDrop($event)"
          >
            <app-icon
              name="ic:baseline-upload-file"
              class="w-8 h-8 text-primary"
            />
            <div class="flex flex-col gap-1 text-center">
              <p class="text-sm text-gray-800">
                {{ type() === 'image' ? '사진' : '파일' }}을 드래그하여
                올려놓으세요
              </p>
              <p class="text-sm text-gray-500">
                또는 클릭해서 선택이 가능합니다
              </p>
            </div>
          </div>
          @if (maxlength() > 1) {
            <div class="flex w-full text-sm text-gray-500">
              <p>{{ items().length }} / {{ maxlength() }}</p>
            </div>
          }
        </div>
      }
      @if (items(); as items) {
        <div
          cdkDropList
          [cdkDropListData]="items"
          [cdkDropListDisabled]="items.length < 2"
          (cdkDropListDropped)="onDrop($event)"
          class="flex flex-col gap-2 max-h-[20rem] min-w-[15rem]"
        >
          @for (item of items; track $index) {
            <div
              cdkDrag
              class="flex w-full justify-between p-2.5 gap-2.5 bg-white border border-gray-200 rounded-md"
            >
              <div class="flex gap-2.5 items-center">
                @if (type() === 'image') {
                  @if (item.status) {
                    <img
                      [src]="item.url"
                      class="rounded-md cursor-pointer w-9 h-9"
                      (click)="openImage(item.url)"
                      (error)="item.status = 'fail'"
                    />
                  } @else {
                    <div
                      class="flex items-center justify-center p-2 border border-gray-200 rounded-md w-9 h-9"
                    >
                      <app-icon
                        name="mdi:file-image"
                        class="block w-6 h-6 bg-gray-700"
                      />
                    </div>
                  }
                } @else {
                  <app-icon
                    name="ic:outline-insert-drive-file"
                    class="block w-6 h-6 bg-gray-700"
                  />
                }
                <div class="flex flex-col gap-1">
                  <p
                    [ngClass]="{ 'max-w-[7rem]': direction() === 'row' }"
                    class="w-full truncate max-w-[10rem] lg:max-w-[25rem] text-sm text-gray-700"
                  >
                    {{ item.filename }}
                  </p>
                </div>
              </div>
              <div class="flex items-center gap-4">
                @if (item.status === 'fail') {
                  <app-icon
                    class="w-5 h-5 text-red-500"
                    name="ic:round-warning-amber"
                  />
                }
                @if (item.status === 'success') {
                  <app-icon
                    class="w-5 h-5 text-green-500"
                    name="ic:baseline-check"
                  />
                }
                @if (!item.status) {
                  <app-icon
                    class="w-5 h-5 text-primary animate-spin"
                    name="mdi:loading"
                  />
                }
                @if (item.status) {
                  <app-icon
                    name="ic:outline-close"
                    class="w-5 h-5 text-gray-700 cursor-pointer"
                    (click)="deleteFile(item.url)"
                  />
                }
              </div>
            </div>
          }
        </div>
      }
    }
  </div>
</div>
<input
  class="hidden"
  id="app-file-uploader"
  type="file"
  multiple
  [accept]="accept()"
/>
