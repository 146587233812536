/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { developmentStageControllerCreateManualV1 } from '../fn/development-stage/development-stage-controller-create-manual-v-1';
import { DevelopmentStageControllerCreateManualV1$Params } from '../fn/development-stage/development-stage-controller-create-manual-v-1';
import { developmentStageControllerDeleteManualV1 } from '../fn/development-stage/development-stage-controller-delete-manual-v-1';
import { DevelopmentStageControllerDeleteManualV1$Params } from '../fn/development-stage/development-stage-controller-delete-manual-v-1';
import { developmentStageControllerPutV1 } from '../fn/development-stage/development-stage-controller-put-v-1';
import { DevelopmentStageControllerPutV1$Params } from '../fn/development-stage/development-stage-controller-put-v-1';
import { developmentStageControllerSearchByParentV1 } from '../fn/development-stage/development-stage-controller-search-by-parent-v-1';
import { DevelopmentStageControllerSearchByParentV1$Params } from '../fn/development-stage/development-stage-controller-search-by-parent-v-1';
import { developmentStageControllerUpdateManualV1 } from '../fn/development-stage/development-stage-controller-update-manual-v-1';
import { DevelopmentStageControllerUpdateManualV1$Params } from '../fn/development-stage/development-stage-controller-update-manual-v-1';
import { SearchDevelopmentStageResponseDto } from '../models/search-development-stage-response-dto';

@Injectable({ providedIn: 'root' })
export class DevelopmentStageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `developmentStageControllerSearchByParentV1()` */
  static readonly DevelopmentStageControllerSearchByParentV1Path = '/api/v1/development-stage/search-by-parent';

  /**
   * 보호자가 내 아동의 발달단계를 검색합니다.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `developmentStageControllerSearchByParentV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  developmentStageControllerSearchByParentV1$Response(params?: DevelopmentStageControllerSearchByParentV1$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchDevelopmentStageResponseDto>> {
    return developmentStageControllerSearchByParentV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 발달단계를 검색합니다.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `developmentStageControllerSearchByParentV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  developmentStageControllerSearchByParentV1(params?: DevelopmentStageControllerSearchByParentV1$Params, context?: HttpContext): Observable<SearchDevelopmentStageResponseDto> {
    return this.developmentStageControllerSearchByParentV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchDevelopmentStageResponseDto>): SearchDevelopmentStageResponseDto => r.body)
    );
  }

  /** Path part for operation `developmentStageControllerCreateManualV1()` */
  static readonly DevelopmentStageControllerCreateManualV1Path = '/api/v1/development-stage/manual';

  /**
   * 보호자가 내 아동의 개발단계를 수기로 등록합니다.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `developmentStageControllerCreateManualV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  developmentStageControllerCreateManualV1$Response(params: DevelopmentStageControllerCreateManualV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return developmentStageControllerCreateManualV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 개발단계를 수기로 등록합니다.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `developmentStageControllerCreateManualV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  developmentStageControllerCreateManualV1(params: DevelopmentStageControllerCreateManualV1$Params, context?: HttpContext): Observable<boolean> {
    return this.developmentStageControllerCreateManualV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `developmentStageControllerDeleteManualV1()` */
  static readonly DevelopmentStageControllerDeleteManualV1Path = '/api/v1/development-stage/manual/{id}';

  /**
   * 보호자가 내 아동의 수기 개발단계를 삭제합니다.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `developmentStageControllerDeleteManualV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  developmentStageControllerDeleteManualV1$Response(params: DevelopmentStageControllerDeleteManualV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return developmentStageControllerDeleteManualV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 수기 개발단계를 삭제합니다.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `developmentStageControllerDeleteManualV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  developmentStageControllerDeleteManualV1(params: DevelopmentStageControllerDeleteManualV1$Params, context?: HttpContext): Observable<boolean> {
    return this.developmentStageControllerDeleteManualV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `developmentStageControllerUpdateManualV1()` */
  static readonly DevelopmentStageControllerUpdateManualV1Path = '/api/v1/development-stage/manual/{id}';

  /**
   * 보호자가 내 아동의 개발단계를 수기로 수정합니다.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `developmentStageControllerUpdateManualV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  developmentStageControllerUpdateManualV1$Response(params: DevelopmentStageControllerUpdateManualV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return developmentStageControllerUpdateManualV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 개발단계를 수기로 수정합니다.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `developmentStageControllerUpdateManualV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  developmentStageControllerUpdateManualV1(params: DevelopmentStageControllerUpdateManualV1$Params, context?: HttpContext): Observable<boolean> {
    return this.developmentStageControllerUpdateManualV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `developmentStageControllerPutV1()` */
  static readonly DevelopmentStageControllerPutV1Path = '/api/v1/development-stage';

  /**
   * 보호자가 내 아동의 개발단계 토글.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `developmentStageControllerPutV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  developmentStageControllerPutV1$Response(params: DevelopmentStageControllerPutV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return developmentStageControllerPutV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 개발단계 토글.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `developmentStageControllerPutV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  developmentStageControllerPutV1(params: DevelopmentStageControllerPutV1$Params, context?: HttpContext): Observable<boolean> {
    return this.developmentStageControllerPutV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
