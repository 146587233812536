<div>
  <p
    [attr.data-type]="type()"
    [attr.data-theme]="theme()"
    [class.today]="isToday()"
    class="theme-text day"
  >
    {{ day().format("DD") }} ({{ day().toDate() | date: "EE" }})
  </p>
</div>
