/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { inquiryControllerAnswerV1 } from '../fn/inquiry/inquiry-controller-answer-v-1';
import { InquiryControllerAnswerV1$Params } from '../fn/inquiry/inquiry-controller-answer-v-1';
import { inquiryControllerCreateV1 } from '../fn/inquiry/inquiry-controller-create-v-1';
import { InquiryControllerCreateV1$Params } from '../fn/inquiry/inquiry-controller-create-v-1';
import { inquiryControllerDeleteV1 } from '../fn/inquiry/inquiry-controller-delete-v-1';
import { InquiryControllerDeleteV1$Params } from '../fn/inquiry/inquiry-controller-delete-v-1';
import { inquiryControllerFindAllV1 } from '../fn/inquiry/inquiry-controller-find-all-v-1';
import { InquiryControllerFindAllV1$Params } from '../fn/inquiry/inquiry-controller-find-all-v-1';
import { inquiryControllerFindByIdV1 } from '../fn/inquiry/inquiry-controller-find-by-id-v-1';
import { InquiryControllerFindByIdV1$Params } from '../fn/inquiry/inquiry-controller-find-by-id-v-1';
import { inquiryControllerSearchV1 } from '../fn/inquiry/inquiry-controller-search-v-1';
import { InquiryControllerSearchV1$Params } from '../fn/inquiry/inquiry-controller-search-v-1';
import { inquiryControllerUpdateV1 } from '../fn/inquiry/inquiry-controller-update-v-1';
import { InquiryControllerUpdateV1$Params } from '../fn/inquiry/inquiry-controller-update-v-1';
import { InquiryDto } from '../models/inquiry-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class InquiryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `inquiryControllerSearchV1()` */
  static readonly InquiryControllerSearchV1Path = '/api/v1/inquiry/search';

  /**
   * 1:1 문의 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerSearchV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerSearchV1$Response(params: InquiryControllerSearchV1$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return inquiryControllerSearchV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerSearchV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerSearchV1(params: InquiryControllerSearchV1$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.inquiryControllerSearchV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `inquiryControllerFindAllV1()` */
  static readonly InquiryControllerFindAllV1Path = '/api/v1/inquiry';

  /**
   * 전체1:1 문의 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerFindAllV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerFindAllV1$Response(params?: InquiryControllerFindAllV1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InquiryDto>>> {
    return inquiryControllerFindAllV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 전체1:1 문의 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerFindAllV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerFindAllV1(params?: InquiryControllerFindAllV1$Params, context?: HttpContext): Observable<Array<InquiryDto>> {
    return this.inquiryControllerFindAllV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InquiryDto>>): Array<InquiryDto> => r.body)
    );
  }

  /** Path part for operation `inquiryControllerCreateV1()` */
  static readonly InquiryControllerCreateV1Path = '/api/v1/inquiry';

  /**
   * 1:1 문의 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerCreateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerCreateV1$Response(params: InquiryControllerCreateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<InquiryDto>> {
    return inquiryControllerCreateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerCreateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerCreateV1(params: InquiryControllerCreateV1$Params, context?: HttpContext): Observable<InquiryDto> {
    return this.inquiryControllerCreateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<InquiryDto>): InquiryDto => r.body)
    );
  }

  /** Path part for operation `inquiryControllerFindByIdV1()` */
  static readonly InquiryControllerFindByIdV1Path = '/api/v1/inquiry/{id}';

  /**
   * 1:1 문의 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerFindByIdV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerFindByIdV1$Response(params: InquiryControllerFindByIdV1$Params, context?: HttpContext): Observable<StrictHttpResponse<InquiryDto>> {
    return inquiryControllerFindByIdV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerFindByIdV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerFindByIdV1(params: InquiryControllerFindByIdV1$Params, context?: HttpContext): Observable<InquiryDto> {
    return this.inquiryControllerFindByIdV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<InquiryDto>): InquiryDto => r.body)
    );
  }

  /** Path part for operation `inquiryControllerDeleteV1()` */
  static readonly InquiryControllerDeleteV1Path = '/api/v1/inquiry/{id}';

  /**
   * 1:1 문의 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerDeleteV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerDeleteV1$Response(params: InquiryControllerDeleteV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return inquiryControllerDeleteV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerDeleteV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerDeleteV1(params: InquiryControllerDeleteV1$Params, context?: HttpContext): Observable<boolean> {
    return this.inquiryControllerDeleteV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `inquiryControllerUpdateV1()` */
  static readonly InquiryControllerUpdateV1Path = '/api/v1/inquiry/{id}';

  /**
   * 1:1 문의 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerUpdateV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerUpdateV1$Response(params: InquiryControllerUpdateV1$Params, context?: HttpContext): Observable<StrictHttpResponse<InquiryDto>> {
    return inquiryControllerUpdateV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerUpdateV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerUpdateV1(params: InquiryControllerUpdateV1$Params, context?: HttpContext): Observable<InquiryDto> {
    return this.inquiryControllerUpdateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<InquiryDto>): InquiryDto => r.body)
    );
  }

  /** Path part for operation `inquiryControllerAnswerV1()` */
  static readonly InquiryControllerAnswerV1Path = '/api/v1/inquiry/{id}/answer';

  /**
   * 1:1 문의 답변 등록 및 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerAnswerV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerAnswerV1$Response(params: InquiryControllerAnswerV1$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return inquiryControllerAnswerV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 답변 등록 및 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerAnswerV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerAnswerV1(params: InquiryControllerAnswerV1$Params, context?: HttpContext): Observable<boolean> {
    return this.inquiryControllerAnswerV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
