import { Component } from '@angular/core';
import { Icon } from '../icon/icon.component';
import { BaseConfig } from '../common/config/config.adapter';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-hint',
  template: `
    <div class="flex items-start gap-2">
      <div class="min-w-4">
        <app-icon
          name="solar:info-circle-bold-duotone"
          size="sm"
          class=""
          [ngClass]="theme() === 'dark' ? 'text-gray-300' : 'text-gray-500'"
        />
      </div>
      <p
        [ngClass]="theme() === 'dark' ? 'text-gray-300' : 'text-gray-500'"
        class="text-sm  whitespace-pre-line"
      >
        <ng-content />
      </p>
    </div>
  `,
  imports: [Icon, CommonModule],
})
export class Hint extends BaseConfig {}
