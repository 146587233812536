/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { appleAuthControllerAppleLogin } from '../fn/apple/apple-auth-controller-apple-login';
import { AppleAuthControllerAppleLogin$Params } from '../fn/apple/apple-auth-controller-apple-login';
import { appleAuthControllerConnect } from '../fn/apple/apple-auth-controller-connect';
import { AppleAuthControllerConnect$Params } from '../fn/apple/apple-auth-controller-connect';
import { OAuthResponseDto } from '../models/o-auth-response-dto';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class AppleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `appleAuthControllerAppleLogin()` */
  static readonly AppleAuthControllerAppleLoginPath = '/api/oauth/apple/signin';

  /**
   * 애플 로그인.
   *
   * 애플 로그인을 수행합니다. 로그인에 성공하면, 엑세스, 리프레쉬 토큰은 응답 헤더에 포함됩니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appleAuthControllerAppleLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appleAuthControllerAppleLogin$Response(params: AppleAuthControllerAppleLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<OAuthResponseDto>> {
    return appleAuthControllerAppleLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 애플 로그인.
   *
   * 애플 로그인을 수행합니다. 로그인에 성공하면, 엑세스, 리프레쉬 토큰은 응답 헤더에 포함됩니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appleAuthControllerAppleLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appleAuthControllerAppleLogin(params: AppleAuthControllerAppleLogin$Params, context?: HttpContext): Observable<OAuthResponseDto> {
    return this.appleAuthControllerAppleLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<OAuthResponseDto>): OAuthResponseDto => r.body)
    );
  }

  /** Path part for operation `appleAuthControllerConnect()` */
  static readonly AppleAuthControllerConnectPath = '/api/oauth/apple/connect';

  /**
   * 애플 계정 연결.
   *
   * 기존에 가입된 계정이 있는 경우 소셜 계정을 연결합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appleAuthControllerConnect()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appleAuthControllerConnect$Response(params: AppleAuthControllerConnect$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return appleAuthControllerConnect(this.http, this.rootUrl, params, context);
  }

  /**
   * 애플 계정 연결.
   *
   * 기존에 가입된 계정이 있는 경우 소셜 계정을 연결합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appleAuthControllerConnect$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appleAuthControllerConnect(params: AppleAuthControllerConnect$Params, context?: HttpContext): Observable<UserDto> {
    return this.appleAuthControllerConnect$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

}
