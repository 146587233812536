/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GoogleAuthRequestDto } from '../../models/google-auth-request-dto';
import { OAuthResponseDto } from '../../models/o-auth-response-dto';

export interface GoogleAuthControllerFirebaseLogin$Params {
      body: GoogleAuthRequestDto
}

export function googleAuthControllerFirebaseLogin(http: HttpClient, rootUrl: string, params: GoogleAuthControllerFirebaseLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<OAuthResponseDto>> {
  const rb = new RequestBuilder(rootUrl, googleAuthControllerFirebaseLogin.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OAuthResponseDto>;
    })
  );
}

googleAuthControllerFirebaseLogin.PATH = '/api/oauth/google/firebase/sign-in';
