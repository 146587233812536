/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { parentProfileControllerFindById } from '../fn/parent-profile/parent-profile-controller-find-by-id';
import { ParentProfileControllerFindById$Params } from '../fn/parent-profile/parent-profile-controller-find-by-id';
import { parentProfileControllerGetMyData } from '../fn/parent-profile/parent-profile-controller-get-my-data';
import { ParentProfileControllerGetMyData$Params } from '../fn/parent-profile/parent-profile-controller-get-my-data';
import { parentProfileControllerUpdateMyDataWithUser } from '../fn/parent-profile/parent-profile-controller-update-my-data-with-user';
import { ParentProfileControllerUpdateMyDataWithUser$Params } from '../fn/parent-profile/parent-profile-controller-update-my-data-with-user';
import { parentProfileControllerUpsertMyData } from '../fn/parent-profile/parent-profile-controller-upsert-my-data';
import { ParentProfileControllerUpsertMyData$Params } from '../fn/parent-profile/parent-profile-controller-upsert-my-data';
import { ParentProfileDto } from '../models/parent-profile-dto';

@Injectable({ providedIn: 'root' })
export class ParentProfileService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `parentProfileControllerGetMyData()` */
  static readonly ParentProfileControllerGetMyDataPath = '/api/parent-profile';

  /**
   * 내 부모 프로필 조회.
   *
   * 내 부모 프로필을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentProfileControllerGetMyData()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentProfileControllerGetMyData$Response(params?: ParentProfileControllerGetMyData$Params, context?: HttpContext): Observable<StrictHttpResponse<ParentProfileDto>> {
    return parentProfileControllerGetMyData(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 부모 프로필 조회.
   *
   * 내 부모 프로필을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentProfileControllerGetMyData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentProfileControllerGetMyData(params?: ParentProfileControllerGetMyData$Params, context?: HttpContext): Observable<ParentProfileDto> {
    return this.parentProfileControllerGetMyData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ParentProfileDto>): ParentProfileDto => r.body)
    );
  }

  /** Path part for operation `parentProfileControllerUpsertMyData()` */
  static readonly ParentProfileControllerUpsertMyDataPath = '/api/parent-profile';

  /**
   * 내 부모 프로필 등록 | 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentProfileControllerUpsertMyData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentProfileControllerUpsertMyData$Response(params: ParentProfileControllerUpsertMyData$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return parentProfileControllerUpsertMyData(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 부모 프로필 등록 | 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentProfileControllerUpsertMyData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentProfileControllerUpsertMyData(params: ParentProfileControllerUpsertMyData$Params, context?: HttpContext): Observable<boolean> {
    return this.parentProfileControllerUpsertMyData$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `parentProfileControllerFindById()` */
  static readonly ParentProfileControllerFindByIdPath = '/api/parent-profile/{id}';

  /**
   * 부모 프로필 조회.
   *
   * 부모 프로필을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentProfileControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentProfileControllerFindById$Response(params: ParentProfileControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ParentProfileDto>> {
    return parentProfileControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 부모 프로필 조회.
   *
   * 부모 프로필을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentProfileControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentProfileControllerFindById(params: ParentProfileControllerFindById$Params, context?: HttpContext): Observable<ParentProfileDto> {
    return this.parentProfileControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ParentProfileDto>): ParentProfileDto => r.body)
    );
  }

  /** Path part for operation `parentProfileControllerUpdateMyDataWithUser()` */
  static readonly ParentProfileControllerUpdateMyDataWithUserPath = '/api/parent-profile/with-user';

  /**
   * 내 부모 프로필 및 사용자 정보 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentProfileControllerUpdateMyDataWithUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentProfileControllerUpdateMyDataWithUser$Response(params: ParentProfileControllerUpdateMyDataWithUser$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return parentProfileControllerUpdateMyDataWithUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 부모 프로필 및 사용자 정보 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentProfileControllerUpdateMyDataWithUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentProfileControllerUpdateMyDataWithUser(params: ParentProfileControllerUpdateMyDataWithUser$Params, context?: HttpContext): Observable<boolean> {
    return this.parentProfileControllerUpdateMyDataWithUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
