import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  input,
  output,
  PLATFORM_ID,
  signal,
} from '@angular/core';
import { FormControl } from '@angular/forms';

const Errors = [
  { key: 'required', message: '이 항목은 필수입니다' },
  { key: 'email', message: '이메일 형식이 아닙니다' },
  {
    key: 'password',
    message: '문자/숫자/특수문자 조합 8자 이상 20자 이하로 입력해 주세요.',
  },
  { key: 'array', message: '이 항목은 필수입니다' },
  { key: 'object-required', message: '이 항목은 필수입니다.' },
];

@Directive({
  selector: '[formError]',
})
export class ErrorDirective implements AfterViewInit {
  formControl = input.required<FormControl>();
  touched = signal(false);

  hasError = output<boolean>();

  platformId = inject(PLATFORM_ID);

  constructor(private readonly el: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.formControl()) {
        this.formControl().valueChanges.subscribe({
          next: (ev) => {
            if (ev) {
              this.touched.set(true);
            }

            if (this.touched()) {
              const error = Errors.find((error) => {
                return this.formControl().getError(error.key);
              });

              if (error) {
                this.el.nativeElement.className =
                  'px-2 py-1 mt-2 text-xs text-red-400 rounded-md bg-red-50 w-max';
                this.el.nativeElement.innerHTML = error.message;
              } else {
                this.el.nativeElement.innerHTML = '';
                this.el.nativeElement.className = '';
              }

              this.hasError.emit(!!error);
            }
          },
        });
      }
    }
  }
}
