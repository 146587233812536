import { CommonModule } from "@angular/common";
import { Component, computed, inject, input, output } from "@angular/core";
import dayjs, { Dayjs } from "dayjs";
import { InputCalendarStore } from "./calendar.store";
import { BaseConfig } from "../../../common/config/config.adapter";

@Component({
  selector: "app-input-base-day",
  imports: [CommonModule],
  standalone: true,
  template: ``,
})
export class InputBaseDay extends BaseConfig {
  readonly store = inject(InputCalendarStore);

  select = output<void>();

  value = this.store.value;
  day = input.required<Dayjs>();

  isToday = computed<boolean>(
    () => dayjs().format("YYYY-MM-DD") === this.day().format("YYYY-MM-DD"),
  );
  selected = computed<boolean>(
    () =>
      !this.isToday() &&
      dayjs(this.value()).format("YYYY-MM-DD") ===
        this.day().format("YYYY-MM-DD"),
  );
}
