import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { OptionDirective } from 'libs/front-share/src/directives/option.directive';
import { SegmentOption } from '../segment.component';
import { BaseConfig } from '../../common/config/config.adapter';
import { Color, Icon } from '../../components';

@Component({
  selector: '[segmentOption]',
  templateUrl: './segment-option.component.html',
  styleUrls: ['../segment.component.scss'],
  imports: [CommonModule, OptionDirective, Icon],
})
export class SegmentOptionComponent
  extends BaseConfig
  implements AfterViewInit
{
  @HostBinding('class') hostClass = 'w-full';

  readonly changeRef = inject(ChangeDetectorRef);
  readonly elementRef = inject(ElementRef);

  option = input.required<SegmentOption>();
  active = input<boolean>(false);
  color = input<Color>('primary');
  directiveRef = viewChild(OptionDirective);

  ngAfterViewInit(): void {
    if (this.option()?.renderItem) {
      const viewContainerRef = this.directiveRef()?.viewContainerRef;
      viewContainerRef?.clear();
      const componentRef = viewContainerRef?.createComponent(
        this.option()?.renderItem,
      );
      componentRef?.setInput('option', this.option());

      this.changeRef.detectChanges();
    }
  }
}
