<div [attr.data-theme]="theme()" class="theme-text">
  <div class="absolute">
    @for (event of renderEvents(); track event) {
      <app-all-day-event
        class="calendar-event"
        (menu)="handleEventMenu($event, event)"
        [event]="event"
        [index]="$index"
      />
    }
  </div>
  <div
    [attr.data-theme]="theme()"
    class="flex w-full min-w-max"
    [style.height]="height() + 'px'"
  >
    <p
      [style.height]="height() + 'px'"
      [attr.data-theme]="theme()"
      [ngClass]="theme() === 'dark' ? 'bg-dark' : 'bg-white'"
      class="min-w-[8rem] theme-text border-b-4 theme-border border-r flex items-center justify-center sticky left-0 z-20"
    >
      종일
    </p>
    @for (day of days(); track day.format("DD")) {
      <div
        [style.height]="height() + 'px'"
        [attr.data-theme]="theme()"
        [attr.data-day]="day"
        class="day theme-border flex-1 border-b-4 min-w-[8rem]"
      ></div>
    }
  </div>
</div>
