/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { appVersionControllerSearchV1 } from '../fn/app-version/app-version-controller-search-v-1';
import { AppVersionControllerSearchV1$Params } from '../fn/app-version/app-version-controller-search-v-1';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class AppVersionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `appVersionControllerSearchV1()` */
  static readonly AppVersionControllerSearchV1Path = '/api/v1/app-version/search';

  /**
   * 앱 버전 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appVersionControllerSearchV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  appVersionControllerSearchV1$Response(params: AppVersionControllerSearchV1$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return appVersionControllerSearchV1(this.http, this.rootUrl, params, context);
  }

  /**
   * 앱 버전 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appVersionControllerSearchV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appVersionControllerSearchV1(params: AppVersionControllerSearchV1$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.appVersionControllerSearchV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

}
