import { Component, effect, model, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputAddress } from '../../../../input/address/input-address.component';
import { CustomFormField } from '../../../auto-form.type';
import { FormFieldAdapter } from '../../form-field.adapter';

@Component({
  selector: 'app-form-address',
  templateUrl: './address.component.html',
  imports: [InputAddress],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FormAddress,
      multi: true,
    },
  ],
})
export class FormAddress
  extends FormFieldAdapter<CustomFormField<any>>
  implements OnInit
{
  override ngOnInit(): void {
    super.ngOnInit();

    this.defaultValues$.subscribe({
      next: (values) => {
        if (values) {
          this.value.set({
            address: values.address.address,
            addressDetail: values.address.addressDetail,
          });
        } else {
          this.value.set({
            address: '',
            addressDetail: '',
          });
        }
      },
    });
  }
}
