@if (formField(); as field) {
  <app-input-number
    [label]="field.label"
    [(value)]="value"
    [disabled]="field.disabled!"
    [required]="field.required!"
    [readonly]="field.readonly!"
    [placeholder]="_placeholder()"
  />
}
