import { Route } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import LayoutComponent from './pages/layout/layout.component';

export const appRoutes: Route[] = [
  {
    path: 'login',
    loadComponent: () => import('./pages/auth/login/login.page'),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'user',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/user/user.page'),
          },
          {
            path: ':id',
            loadComponent: () => import('./pages/user/detail/user-detail.page'),
          },
        ],
      },
      {
        path: 'center',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/center/center.page'),
          },
          {
            path: 'form',
            loadComponent: () => import('./pages/center/form/center-form.page'),
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./pages/center/detail/center-detail.page'),
          },
        ],
      },
      {
        path: 'voucher',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/task/voucher/voucher.page'),
          },
          {
            path: 'form',
            loadComponent: () =>
              import('./pages/task/voucher/voucher-form/voucher-form.page'),
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./pages/task/voucher/voucher-detail/voucher-detail.page'),
          },
        ],
      },
      {
        path: 'ticket-type',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/task/ticket-type/ticket-type.page'),
          },
        ],
      },
      {
        path: 'child',
        children: [
          { path: '', loadComponent: () => import('./pages/child/child.page') },
          {
            path: ':id',
            loadComponent: () =>
              import('./pages/child/child-detail/child-detail.page'),
          },
        ],
      },
      {
        path: 'notice',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/customer/notice/notice.page'),
          },
          {
            path: 'create',
            loadComponent: () =>
              import(
                './pages/customer/notice/create-notice/create-notice.page'
              ),
          },
          {
            path: 'update',
            children: [
              {
                path: ':id',
                loadComponent: () =>
                  import(
                    './pages/customer/notice/create-notice/create-notice.page'
                  ),
              },
            ],
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './pages/customer/notice/detail-notice/detail-notice.page'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'inquiry',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/customer/inquiry/inquiry.page'),
          },
          {
            path: 'update',
            children: [
              {
                path: ':id',
                loadComponent: () =>
                  import(
                    './pages/customer/inquiry/create-inquiry/create-inquiry.page'
                  ),
              },
            ],
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './pages/customer/inquiry/detail-inquiry/detail-inquiry.page'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'faq',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/customer/faq/faq.page'),
          },
          {
            path: 'create',
            loadComponent: () =>
              import('./pages/customer/faq/create-faq/create-faq.page'),
          },
          {
            path: 'update',
            children: [
              {
                path: ':id',
                loadComponent: () =>
                  import('./pages/customer/faq/create-faq/create-faq.page'),
              },
            ],
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./pages/customer/faq/detail-faq/detail-faq.page'),
          },
        ],
      },
      {
        path: 'term',
        loadComponent: () => import('./pages/info/term/term.page'),
      },
      {
        path: 'company-info',
        loadComponent: () =>
          import('./pages/info/company-info/company-info.page'),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
