/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FileDto } from '../models/file-dto';
import { storageControllerDeleteFile } from '../fn/storage/storage-controller-delete-file';
import { StorageControllerDeleteFile$Params } from '../fn/storage/storage-controller-delete-file';
import { storageControllerDeleteMany } from '../fn/storage/storage-controller-delete-many';
import { StorageControllerDeleteMany$Params } from '../fn/storage/storage-controller-delete-many';
import { storageControllerUploadFiles } from '../fn/storage/storage-controller-upload-files';
import { StorageControllerUploadFiles$Params } from '../fn/storage/storage-controller-upload-files';

@Injectable({ providedIn: 'root' })
export class StorageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `storageControllerUploadFiles()` */
  static readonly StorageControllerUploadFilesPath = '/api/storage/{container}';

  /**
   * 파일 업로드.
   *
   * 파일을 업로드합니다
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageControllerUploadFiles()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  storageControllerUploadFiles$Response(params: StorageControllerUploadFiles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FileDto>>> {
    return storageControllerUploadFiles(this.http, this.rootUrl, params, context);
  }

  /**
   * 파일 업로드.
   *
   * 파일을 업로드합니다
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageControllerUploadFiles$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  storageControllerUploadFiles(params: StorageControllerUploadFiles$Params, context?: HttpContext): Observable<Array<FileDto>> {
    return this.storageControllerUploadFiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FileDto>>): Array<FileDto> => r.body)
    );
  }

  /** Path part for operation `storageControllerDeleteFile()` */
  static readonly StorageControllerDeleteFilePath = '/api/storage/{url}';

  /**
   * 파일 삭제.
   *
   * 파일을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageControllerDeleteFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageControllerDeleteFile$Response(params: StorageControllerDeleteFile$Params, context?: HttpContext): Observable<StrictHttpResponse<FileDto>> {
    return storageControllerDeleteFile(this.http, this.rootUrl, params, context);
  }

  /**
   * 파일 삭제.
   *
   * 파일을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageControllerDeleteFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageControllerDeleteFile(params: StorageControllerDeleteFile$Params, context?: HttpContext): Observable<FileDto> {
    return this.storageControllerDeleteFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileDto>): FileDto => r.body)
    );
  }

  /** Path part for operation `storageControllerDeleteMany()` */
  static readonly StorageControllerDeleteManyPath = '/api/storage';

  /**
   * 여러 파일 삭제.
   *
   * 여러 파일을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageControllerDeleteMany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageControllerDeleteMany$Response(params: StorageControllerDeleteMany$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'count'?: number;
}>> {
    return storageControllerDeleteMany(this.http, this.rootUrl, params, context);
  }

  /**
   * 여러 파일 삭제.
   *
   * 여러 파일을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageControllerDeleteMany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageControllerDeleteMany(params: StorageControllerDeleteMany$Params, context?: HttpContext): Observable<{
'count'?: number;
}> {
    return this.storageControllerDeleteMany$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>): {
'count'?: number;
} => r.body)
    );
  }

}
