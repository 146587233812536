@for (column of columns(); track $index) {
  <td
    (contextmenu)="open.set(true)"
    (clickOutside)="open.set(false)"
    [cdkContextMenuTriggerFor]="context"
    gridCell
    [(selecteds)]="selecteds"
    [attr.data-theme]="theme()"
    [attr.data-fixed]="column?.type === 'checkbox'"
    [attr.data-selected]="selected() || active() || open()"
    [class.cursor-pointer]="column.cellClickHandler"
    [class.group-hover:bg-dark]="theme() === 'dark'"
    [class.group-hover:bg-primary-50]="theme() === 'light'"
    [column]="column"
    [row]="row()"
  ></td>
}

<ng-template #context>
  @if (rowOptions(); as options) {
    @if (options.length > 0) {
      <app-menu cdkMenu>
        @for (option of options; track option) {
          <app-menu-option
            [icon]="option.icon"
            (click)="handleOption(option)"
            cdkMenuItem
            >{{ option.label }}</app-menu-option
          >
        }
      </app-menu>
    }
  }
</ng-template>
