/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SearchEnrollmentScheduleResponseDto } from '../../models/search-enrollment-schedule-response-dto';

export interface EnrollmentScheduleControllerSearchByParentV1$Params {

/**
 * 페이지 크기
 */
  pageSize: number;

/**
 * 정렬 기준
 */
  orderBy?: string;

/**
 * 정렬 방향
 */
  align?: 'asc' | 'desc';

/**
 * 검색어
 */
  query?: string;

/**
 * 페이지 번호
 */
  pageNo: number;

/**
 * 발달 서비스 ID
 */
  developmentServiceId?: string;

/**
 * 타입
 */
  type?: string;
}

export function enrollmentScheduleControllerSearchByParentV1(http: HttpClient, rootUrl: string, params: EnrollmentScheduleControllerSearchByParentV1$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchEnrollmentScheduleResponseDto>> {
  const rb = new RequestBuilder(rootUrl, enrollmentScheduleControllerSearchByParentV1.PATH, 'get');
  if (params) {
    rb.query('pageSize', params.pageSize, {});
    rb.query('orderBy', params.orderBy, {});
    rb.query('align', params.align, {});
    rb.query('query', params.query, {});
    rb.query('pageNo', params.pageNo, {});
    rb.query('developmentServiceId', params.developmentServiceId, {});
    rb.query('type', params.type, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SearchEnrollmentScheduleResponseDto>;
    })
  );
}

enrollmentScheduleControllerSearchByParentV1.PATH = '/api/v1/enrollment-schedule/search-by-parent';
