import { Component } from '@angular/core';
import { Icon } from '../components';

@Component({
  selector: 'app-spinner',
  imports: [Icon],
  template: `
    <section class="flex items-center justify-center w-full h-full">
      <app-icon
        size="xl"
        class="animate-spin text-primary"
        name="mdi:loading"
      />
    </section>
  `,
})
export class Spinner {}
