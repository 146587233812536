import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  computed,
  HostBinding,
  input,
  signal,
} from '@angular/core';
import { BaseConfig } from '../common/config/config.adapter';
import { Color, Rounded, Size, Variant } from '../common/types';
import { Icon } from '../icon/icon.component';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [Icon, CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class Button extends BaseConfig {
  @HostBinding('class') get hostClass() {
    if (this.expand()) {
      return 'w-full';
    }

    return null;
  }

  $size = input<Size>(undefined, { alias: 'size' });
  $rounded = input<Rounded>(undefined, { alias: 'rounded' });
  $color = input<Color>(undefined, { alias: 'color' });
  $variant = input<Variant>(undefined, { alias: 'variant' });
  $class = input<string>('', { alias: 'class' });

  _size = signal<Size | undefined>(undefined);
  _rounded = signal<Rounded | undefined>(undefined);
  _color = signal<Color | undefined>(undefined);
  _variant = signal<Variant | undefined>(undefined);
  _class = signal<string>('');

  type = input<'button' | 'submit' | 'reset'>('button');
  icon = input<string>();
  iconClass = input<string>();

  expand = input<boolean, string | boolean>(false, {
    transform: booleanAttribute,
  });
  loading = input<boolean>(false);
  disabled = input<boolean, string | boolean>(false, {
    transform: booleanAttribute,
  });

  // loading이 true일 때 icon override
  currentIcon = computed(() => {
    if (this.loading()) {
      return 'svg-spinners:ring-resize';
    }

    return this.icon();
  });

  config = computed(() => this.globalConfig()?.button);
  currentSize = computed(
    () => this.$size() || this._size() || this.config()?.size || 'md',
  );
  currentColor = computed(
    () => this.$color() || this._color() || this.config()?.color || 'primary',
  );
  currentRounded = computed(
    () => this.$rounded() || this._rounded() || this.config()?.rounded || 'md',
  );
  currentVariant = computed(
    () => this.$variant() || this._variant() || 'solid',
  );
  currentClass = computed(() => this.$class() || this._class());
}
