@if (formField(); as field) {
  <app-input-email
    [color]="hasError() ? 'red' : 'primary'"
    [label]="field.label"
    [(value)]="value"
    [disabled]="field.disabled!"
    [required]="field.required!"
    [readonly]="field.readonly!"
    [placeholder]="_placeholder()"
  />
  <p
    formError
    [formControl]="formControl!"
    (hasError)="hasError.set($event)"
  ></p>
}
