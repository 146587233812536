import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

type ToastPositions =
  | 'toast-top-left'
  | 'toast-top-right'
  | 'toast-bottom-left'
  | 'toast-bottom-right'
  | 'toast-bottom-center';

type ToastColors = 'success' | 'info' | 'warning' | 'error';

export type ToastProps = {
  title?: string;
  message: string;
  type: ToastColors;
  positionClass?: ToastPositions;
};

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private readonly toastr: ToastrService) {}

  show(props: ToastProps): void {
    const text = props?.message ?? ''; // Safe access with default
    const title = props?.title ?? ''; // Safe access with default
    const type = props?.type ?? 'info'; // Default to 'info' if type is missing
    const positionClass = props?.positionClass ?? 'toast-top-right';

    if (this.toastr[type]) {
      this.toastr[type](text, title, { positionClass });
    }
  }

  success(message: string): void;
  success(props: Omit<ToastProps, 'type'>): void;

  success(props: string | Omit<ToastProps, 'type'>): void {
    if (typeof props === 'string') {
      this.toastr.success(props ?? '', undefined, {
        positionClass: 'toast-bottom-center',
      });
      return;
    }

    const text = props?.message ?? '';
    const title = props?.title ?? '';
    const positionClass = props?.positionClass ?? 'toast-top-right';

    this.toastr.success(text, title, { positionClass });
  }

  error(message: string): void;
  error(props: Omit<ToastProps, 'type'>): void;

  error(props: string | Omit<ToastProps, 'type'>): void {
    if (typeof props === 'string') {
      this.toastr.error(props ?? '', undefined, {
        positionClass: 'toast-bottom-center',
      });
      return;
    }

    const text = props?.message ?? '';
    const title = props?.title ?? '';
    const positionClass = props?.positionClass ?? 'toast-top-right';

    this.toastr.error(text, title, { positionClass });
  }

  info(message: string): void;
  info(props: Omit<ToastProps, 'type'>): void;

  info(props: string | Omit<ToastProps, 'type'>): void {
    if (typeof props === 'string') {
      this.toastr.info(props ?? '', undefined, {
        positionClass: 'toast-bottom-center',
      });
      return;
    }

    const text = props?.message ?? '';
    const title = props?.title ?? '';
    const positionClass = props?.positionClass ?? 'toast-top-right';

    this.toastr.info(text, title, { positionClass });
  }

  warning(message: string): void;
  warning(props: Omit<ToastProps, 'type'>): void;

  warning(props: string | Omit<ToastProps, 'type'>): void {
    if (typeof props === 'string') {
      this.toastr.warning(props ?? '', undefined, {
        positionClass: 'toast-bottom-center',
      });
      return;
    }

    const text = props?.message ?? '';
    const title = props?.title ?? '';
    const positionClass = props?.positionClass ?? 'toast-top-right';

    this.toastr.warning(text, title, { positionClass });
  }
}
