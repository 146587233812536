import { CdkMenuModule, CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Component, effect, input, model, viewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import dayjs from 'dayjs';
import { Icon } from '../../../icon/icon.component';
import { BaseInput } from '../../base-input';
import { Input } from '../../input.component';
import { CalendarOutput, InputCalendar } from '../calendar/calendar.component';

@Component({
  selector: 'app-input-date-picker',
  templateUrl: './input-date-picker.component.html',
  standalone: true,
  imports: [CommonModule, Input, Icon, InputCalendar, CdkMenuModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputDatePicker,
      multi: true,
    },
  ],
})
export class InputDatePicker extends BaseInput<Date> {
  menuTrigger = viewChild(CdkMenuTrigger);

  inputValue = model<string | null>(null);
  inputValue$ = toObservable(this.inputValue);
  date = model<Date | null>(new Date());
  date$ = toObservable(this.date);
  icon = input('');

  constructor() {
    super();

    // value에 값이 있을 경우 date와 inputValue를 설정
    effect(() => {
      const value = this.value();
      this.inputValue.set(value ? dayjs(value).format('YYYY-MM-DD') : null);
      this.date.set(value ? dayjs(value).toDate() : new Date());
    });
  }

  handleInput(value: string): void {
    if (this.disabled()) return;

    const date = dayjs(value, 'YYYY-MM-DD').toDate();
    if (date) {
      this.date.set(date);
      this.value.set(dayjs(date).toDate());
    }
  }

  handleSelect(ev: CalendarOutput) {
    if (this.disabled()) return;

    if (ev.value) {
      const date = ev.value;
      this.date.set(date);
      this.inputValue.set(dayjs(date).format('YYYY-MM-DD'));
      this.value.set(date);
      this.menuTrigger()?.close();
    }
  }

  handleOpenCalendar() {
    if (this.readonly() || this.disabled()) return;

    if (this.menuTrigger()) {
      this.menuTrigger()?.open();
    }
  }

  handleKeyDown(ev: KeyboardEvent) {
    if (this.disabled()) {
      ev.stopPropagation();
      ev.preventDefault();
      return;
    }

    if (ev.key === 'Enter') {
      this.menuTrigger()?.close();

      ev.stopPropagation();
      ev.preventDefault();
    }
  }

  handleClick(ev: any) {
    ev.stopPropagation();
  }
}
