import { CommonModule } from '@angular/common';
import { Component, output } from '@angular/core';
import { Icon } from '../components';
import { BaseConfig } from '../common/config/config.adapter';

@Component({
  selector: 'app-name-tag',
  template: `
    <div
      [ngClass]="theme() === 'dark' ? 'bg-gray-500' : 'bg-gray-100'"
      class="flex items-center gap-1 px-2 py-1 rounded-lg w-max"
    >
      <p
        class="text-xs"
        [ngClass]="theme() === 'dark' ? 'text-white' : 'text-gray-600'"
      >
        <ng-content />
      </p>
      <app-icon
        [ngClass]="theme() === 'dark' ? 'text-white' : 'text-gray-400'"
        (click)="close.emit()"
        name="solar:close-circle-bold-duotone"
        class="text-gray-400 cursor-pointer"
        size="sm"
      />
    </div>
  `,
  standalone: true,
  imports: [Icon, CommonModule],
})
export class NameTag extends BaseConfig {
  close = output();
}
