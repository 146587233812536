<section class="flex flex-col rounded-md select-none">
  <app-calendar-header [(mode)]="mode">
    <ng-content select="[buttons]" />
  </app-calendar-header>

  @if (view() === 'block') {
    <app-block-calendar />
  } @else {
    <app-timeline-calendar />
  }
</section>
