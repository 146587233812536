import { Component, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInput } from '../base-input';
import { Input } from '../input.component';

@Component({
  selector: 'app-input-number',
  standalone: true,
  imports: [Input],
  template: `
    <app-input
      [color]="currentColor()"
      [size]="currentSize()"
      [rounded]="currentRounded()"
      [label]="label()"
      [required]="required()"
      [disabled]="disabled()"
      [placeholder]="placeholder()"
      [spellcheck]="spellcheck()"
      [autocomplete]="autocomplete()"
      [autofocus]="autofocus()"
      [readonly]="readonly()"
      [suffix]="suffix()"
      [type]="type()"
      [(value)]="value"
      inputmode="numeric"
      [maxlength]="maxlength()"
    />
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputNumber,
      multi: true,
    },
  ],
})
export class InputNumber
  extends BaseInput<number>
  implements ControlValueAccessor
{
  suffix = input<string>('');
}
