@if (renderItem()) {
  <ng-template option />
} @else {
  @if (option(); as option) {
    <div class="p-4 flex w-full justify-between items-center">
      <div class="text-gray-800">
        {{ option.label }}
      </div>
      @if (active()) {
        <app-icon class="text-gray-800" name="mdi:check" size="sm" />
      }
    </div>
  }
}
