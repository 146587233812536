import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ThemeStore } from '@malirang/front-share';

@Component({
  selector: 'app-container',
  standalone: true,
  template: `
    <section class="base-container" [attr.data-theme]="theme()">
      <ng-content />
    </section>
  `,
  styleUrls: ['./container.component.scss'],
  imports: [CommonModule],
})
export class Container {
  readonly themeStore = inject(ThemeStore);
  theme = this.themeStore.theme;
}
