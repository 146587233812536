import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  computed,
  input,
  model,
  OnInit,
  signal,
} from '@angular/core';
import dayjs from 'dayjs';
import { ClickOutside } from 'ngxtension/click-outside';
import { BaseConfig } from '../../../common/config/config.adapter';
import { Color, Rounded, Size } from '../../../common/types';
import { Icon } from '../../../icon/icon.component';
import { Input } from '../../input.component';
import { CalendarOutput, InputCalendar } from '../calendar/calendar.component';

@Component({
  selector: 'app-input-date-range',
  templateUrl: './input-date-range.component.html',
  standalone: true,
  imports: [CommonModule, InputCalendar, Input, Icon, ClickOutside],
})
export class InputDateRange extends BaseConfig implements OnInit {
  label = input<string>('');
  required = input<boolean, string>(false, { transform: booleanAttribute });
  placeholder = input<string>('');
  spellcheck = input<boolean>(true);
  autocomplete = input<string>('off');
  autofocus = input<boolean>(false);
  readonly = input<boolean>(false);
  disabled = input<boolean>(false);

  isOpen = signal(false);
  inputValue = signal('');

  start = model<Date | null>(null);
  end = model<Date | null>(null);

  type = input<string>('text');
  color = input<Color>();
  size = input<Size>();
  rounded = input<Rounded>('md');

  config = computed(() => this.globalConfig()?.input);
  currentSize = computed(() => this.size() || this.config()?.size || 'md');
  currentColor = computed(
    () => this.color() || this.config()?.color || 'primary',
  );
  currentRounded = computed(
    () => this.rounded() || this.config()?.rounded || 'md',
  );

  ngOnInit(): void {
    if (this.start() && this.end()) {
      const formatStart = dayjs(this.start()).format('YYYY-MM-DD');
      const formatEnd = dayjs(this.end()).format('YYYY-MM-DD');
      this.inputValue.set(
        `${formatStart !== 'Invalid Date' ? formatStart : ''} ~ ${
          formatEnd !== 'Invalid Date' ? formatEnd : ''
        }`,
      );
    }
  }

  handleSelect(ev: CalendarOutput) {
    if (ev.start || ev.end) {
      this.start.set(ev.start);
      this.end.set(ev.end);

      const formatStart = dayjs(ev.start).format('YYYY-MM-DD');
      const formatEnd = dayjs(ev.end).format('YYYY-MM-DD');
      this.inputValue.set(
        `${formatStart !== 'Invalid Date' ? formatStart : ''} ~ ${
          formatEnd !== 'Invalid Date' ? formatEnd : ''
        }`,
      );

      if (formatStart !== 'Invalid Date' && formatEnd !== 'Invalid Date') {
        this.isOpen.set(false);
      }
    }
  }

  handleOpenCalendar() {
    if (this.readonly()) return;

    this.isOpen.set(true);
  }
}
