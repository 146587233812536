import { Component, computed, inject, input, signal } from '@angular/core';
import { josa } from 'es-hangul';
import { BaseInput } from '../../input/base-input';
import { AutoFormStore } from '../auto-form.store';
import { TextFormField } from '../auto-form.type';

@Component({
  selector: 'app-input-form-field-adapter',
  template: '',
})
export class InputFormFieldAdapter<T> extends BaseInput<any> {
  readonly store = inject(AutoFormStore);

  formField = input.required<T>();
  hasError = signal(false);

  _placeholder = computed<string>(() => {
    const field = this.formField() as TextFormField<any>;
    const value = field.placeholder || field.label;
    return `${josa(value!, '을/를')} 입력해주세요.`;
  });

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
