import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  Component,
  computed,
  ElementRef,
  inject,
  input,
  InputSignal,
  InputSignalWithTransform,
  model,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ClickOutside } from 'ngxtension/click-outside';
import { injectResize } from 'ngxtension/resize';
import { get } from '../../helpers/object-get';
import { ActionSheetService } from '../action-sheet/action-sheet.service';
import { BaseInput } from '../input/base-input';
import { Input } from '../input/input.component';
import { Label } from '../label/label.component';
import { SelectOption } from './option/default/select-option.component';
import { SelectOutput } from './option/select-option.adapter';
import { SelectStore } from './select.store';

export interface SelectInput {
  _options: InputSignal<any[]>;

  bindValue?: InputSignal<string | undefined>;
  bindLabel?: InputSignal<string | undefined>;

  /**
   * @description 검색 기능 사용 여부
   */
  search?: InputSignalWithTransform<boolean, string>;

  /**
   * @description custom select option 렌더링 컴포넌트
   */
  renderItem: InputSignal<any | undefined>;

  /**
   * @description 앞 아이콘
   */
  prefixIcon?: InputSignal<string | undefined>;

  /**
   * @description 뒤 아이콘
   */
  suffixIcon?: InputSignal<string | undefined>;
}

@Component({
  host: {
    ngSkipHydration: 'true',
  },
  selector: 'app-select',
  styleUrls: ['../input/input.component.scss', './select.component.scss'],
  standalone: true,
  imports: [CommonModule, Input, Label, SelectOption, ClickOutside],
  templateUrl: './select.component.html',
  providers: [
    SelectStore,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: Select,
      multi: true,
    },
  ],
})
export class Select
  extends BaseInput<any>
  implements OnInit, AfterViewInit, SelectInput
{
  readonly store = inject(SelectStore);
  readonly actionSheetService = inject(ActionSheetService);

  menuWrapper = viewChild<ElementRef<HTMLElement>>('menuWrapper');
  menuRender = signal<boolean>(false);

  renderItem = input<any>();
  prefixIcon = input<string>();
  suffixIcon = input<string>();
  modalTitle = input<string>();
  actionSheetMode = input<boolean>(false);

  _options = input.required<any[]>({ alias: 'options' });
  $options = signal<any[] | null>(null);
  options = computed(() => this.$options() || this._options());

  search = input<boolean, string>(false, { transform: booleanAttribute });
  bindValue = input<string>();
  bindLabel = input<string>();

  open = signal<boolean>(false);
  open$ = toObservable(this.open);

  // input에 보여질 값
  inputValue = model<string>('');

  // 키보드 이벤트 시 사용
  currentIndex = signal<number>(-1);

  isMobile = signal(true);
  resize$ = injectResize();

  override ngOnInit(): void {
    /**
     * @description 초기 값 셋팅 랜더링 시간 고려 setTimeout 사용
     */
    setTimeout(() => {
      this.store.init({
        bindValue: this.bindValue() || '',
        bindLabel: this.bindLabel() || '',
        search: this.search(),
        currentValue: null,
      });

      this.value$.subscribe((value) => {
        const option1 = this.options()[0];

        if (typeof option1 === 'string') {
          this.inputValue.set(this.store.getLabel(value));
        } else {
          const bindValue = this.bindValue();
          if (bindValue) {
            const found = this.options()?.find(
              (option) => get(option, bindValue) === value,
            );
            this.inputValue.set(this.store.getLabel(found));
          }
        }

        this.store.setCurrentValue(value);
      });
    });
  }

  ngAfterViewInit(): void {
    this.handlePosition();

    this.resize$.subscribe({
      next: () => {
        if (window.innerWidth < 768) {
          this.isMobile.set(true);
        } else {
          this.isMobile.set(false);
        }
      },
    });
  }

  /**
   * @description menuWrapper의 위치를 계산하여 화면 하단을 넘어가면 menu-wrapper-bottom 클래스 추가
   */
  handlePosition() {
    this.open$.subscribe({
      next: (open) => {
        if (open) {
          setTimeout(() => {
            const rect =
              this.menuWrapper()?.nativeElement.getBoundingClientRect();
            if (
              rect!.top < 0 || // 요소가 화면 상단 위로 넘어가는지
              rect!.left < 0 || // 요소가 화면 왼쪽으로 넘어가는지
              rect!.bottom > window.innerHeight || // 요소가 화면 하단 아래로 넘어가는지
              rect!.right > window.innerWidth // 요소가 화면 오른쪽으로 넘어가는지
            ) {
              this.menuWrapper()!.nativeElement.classList.add(
                'menu-wrapper-bottom',
              );
            }

            this.menuRender.set(true);
          }, 10);
        } else {
          this.menuRender.set(false);
        }
      },
    });
  }

  handleSelect(ev: SelectOutput) {
    this.open.set(false);
    this.value.set(ev.value);
  }

  /**
   * @description 키보드 이벤트 처리
   * @param ev
   * @returns
   */
  handleKeyup(ev: KeyboardEvent) {
    if (this.isMobile()) return;
    if (this.options().length === 0) return;

    const index = this.currentIndex();
    const length = this.options().length - 1;
    this.open.set(true);

    switch (ev.key) {
      case 'ArrowDown':
        if (index === length) {
          this.currentIndex.set(0);
        } else {
          this.currentIndex.set(index + 1);
        }
        break;
      case 'ArrowUp':
        if (index === 0) {
          this.currentIndex.set(length);
        } else {
          this.currentIndex.set(index - 1);
        }
        break;
      case 'Enter':
        if (index !== -1) {
          const option = this.options()[index];
          this.value.set(this.store.getValue(option));
          this.inputValue.set(this.store.getLabel(option));
          this.open.set(false);
        }
        break;
      case 'Escape':
        this.open.set(false);
        break;
    }
  }

  /**
   * @description input blur 이벤트 발생 시 menu 닫기
   */
  handleBlur() {
    // input blur 이벤트가 menu의 click 이벤트보다 먼저 발생해서 setTimeout으로 처리
    setTimeout(() => {
      this.open.set(false);
      this.currentIndex.set(-1);
    }, 100);
  }

  /**
   * @description 검색 기능 사용 시 input에 입력한 값으로 options 필터링
   * @param value
   * @returns
   */
  handleSearch(value: any) {
    if (this.search()) {
      if (!value) {
        this.$options.set(this._options());
        return;
      }

      const array = this.options().filter((option, index) => {
        if (
          (typeof option === 'string' || typeof option === 'number') &&
          option.toString().includes(value)
        ) {
          return true;
        } else {
          const label = get(option, this.bindLabel() || '');
          if (label?.includes(value)) {
            return true;
          }
        }

        return false;
      });

      this.$options.set(array);
    }
  }

  /**
   * @description focus 이벤트 발생 시 menu 열기(데스크탑에서 사용)
   */
  handleOpen() {
    if (!this.isMobile()) {
      this.open.set(true);
    }
  }

  /**
   * @description action sheet 열기(모바일에서만 사용)
   */
  handleActionSheet(ev: Event) {
    ev?.stopPropagation();
    ev?.preventDefault();

    // actionSheetMode가 true일 경우 actionSheet로 열기
    if (this.actionSheetMode()) {
      this.actionSheet();
    } else {
      if (!this.isMobile()) return;
      this.actionSheet();
    }
  }

  /**
   * @name actionSheet
   * @description action sheet 열기
   * @returns {void}
   */
  actionSheet(): void {
    const options = this.options().map((option) => {
      const label = this.store.getLabel(option) || option?.label;
      const value = this.store.getValue(option) || option?.value;
      return {
        label,
        value,
        handler: () => {
          this.value.set(value);
          this.inputValue.set(label);
        },
      };
    });

    const modal = this.actionSheetService.create({
      options,
      title: this.modalTitle() ? this.modalTitle() : `${this.label()} 선택`,
      renderItem: this.renderItem(),
      currentValue: this.value(),
    });

    modal.closed.subscribe({
      next: (value) => {
        this.value.set(value);
      },
    });
  }
}
