<section
  class="flex flex-col theme-wrapper theme-border rounded-md p-4 select-none"
  [attr.data-theme]="theme()"
  [class.bg-white]="theme() === 'light'"
  [class.bg-dark-surface]="theme() === 'dark'"
>
  <ng-container *ngTemplateOutlet="header" />
  <ng-container *ngTemplateOutlet="daySection" />
</section>

<ng-template #header>
  <section class="relative flex w-full items-center justify-center mb-3 gap-2">
    <app-icon-button
      (click)="handleNavigate('prev', 'month', 1)"
      icon="ic:baseline-chevron-left"
    />
    @if (value(); as value) {
      <app-icon-button (click)="isOpen.set(true)"
        ><p class="font-semibold text-base md:text-lg">
          {{ value | date: 'y년 MM월' }}
        </p></app-icon-button
      >
    }
    <app-icon-button
      (click)="handleNavigate('next', 'month', 1)"
      icon="ic:baseline-chevron-right"
    />
    @if (isOpen()) {
      <app-input-calendar-select
        (clickOutside)="isOpen.set(false)"
        (select)="handleSelectMonthAndYear($event)"
        class="absolute z-20 top-[calc(100%+4px)]"
      />
    }
  </section>
</ng-template>

<ng-template #daySection>
  <section class="flex flex-col">
    <div class="grid grid-cols-7 w-full">
      <p class="item text-red-600">일</p>
      <p [attr.data-theme]="theme()" class="theme-text item">월</p>
      <p [attr.data-theme]="theme()" class="theme-text item">화</p>
      <p [attr.data-theme]="theme()" class="theme-text item">수</p>
      <p [attr.data-theme]="theme()" class="theme-text item">목</p>
      <p [attr.data-theme]="theme()" class="theme-text item">금</p>
      <p class="item text-blue-600">토</p>
    </div>
    <div class="grid grid-cols-7 w-full">
      @for (day of days(); track $index) {
        @if (mode() === 'range') {
          <app-range-day (select)="handleSelectDay()" [day]="day" />
        } @else {
          <app-day (select)="handleSelectDay()" [day]="day" />
        }
      }
    </div>
  </section>
</ng-template>
